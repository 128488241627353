import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, combineLatest, from, of, Subject, Observable } from 'rxjs';
import { switchMap, catchError, map, take } from 'rxjs/operators';
import * as moment from 'moment';
import { environment } from '@environments/environment';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { BlobService } from './blob.service';
import { removeURIQueryString, generateRandomString } from '../lib/fun';
import { isNullOrUndefined } from '@app/shared/helpers';
import { v4 as uuidv4 } from 'uuid';
import { ClinicsService } from './clinics.service';

@Injectable()
export class ImageService {
  private photosTabLoadingState = new Subject<any>();
  photosTabLoadingState$ = this.photosTabLoadingState.asObservable();
  private metadataUpdated = new Subject<any>();
  metadataUpdated$ = this.metadataUpdated.asObservable();

  constructor(private http: HttpClient, private blobService: BlobService, private clinicsService: ClinicsService) {}

  photosTabLoadingStateChanged(value) {
    this.photosTabLoadingState.next(value);
  }

  revertPatientPhoto(photoMetaData: PhotoMetaData): Observable<PhotoMetaData> {
    return this.blobService
      .downloadFileFromBlobStorage(photoMetaData.filePathThumb.replace('thumbs', 'originals'))
      .pipe(
        switchMap((downloadResponse) => from(downloadResponse.blobBody)),
        switchMap((blob: Blob) => {
          const arrayOfBlob = new Array<Blob>();
          arrayOfBlob.push(blob);
          const revertedFile = new File(arrayOfBlob, photoMetaData.fileId);

          // due to reverting to original we set the "revertToOriginal" flag to true
          return this.uploadPhoto(revertedFile, photoMetaData, { revertToOriginal: true, createOriginal: false });
        })
      );
  }

  deletePhoto(photoMetaData: PhotoMetaData): Observable<any> {
    return this.http.delete<PhotoMetaData>(`${environment.baseUrl}api/Photo/DeletePhoto?id=${photoMetaData.id}`).pipe(
      map((data) => {
        // this.patientService.thePatientHasBeenUpdatedById(photoMetaData.patientId);
        return data;
      })
    );
  }

  getClinicStockPhotos(): Observable<PhotoMetaData[]> {
    return this.http.get<PhotoMetaData[]>(`${environment.baseUrl}api/Photo/ClinicStockPhotos`).pipe(
      map((photos) => {
        const readOnlySAS = this.blobService.getReadOnlySAS();
        photos.forEach((photo) => {
          photo.filePath = (photo.filePath ? photo.filePath.trim() : '') + readOnlySAS;
          photo.filePathThumb = (photo.filePathThumb ? photo.filePathThumb.trim() : '') + readOnlySAS;
          photo.filePathOriginal = (photo.filePathOriginal ? photo.filePathOriginal.trim() : '') + readOnlySAS;
        });
        return photos;
      })
    );
  }

  downloadImageLine(imageName: string, dataUri: string) {
    var a = document.createElement('a');
    a.download = imageName;
    a.href = dataUri;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  downloadSingleImage(image: PhotoMetaData) {
    return this.http.get(`${environment.baseUrl}api/Photo/DownloadSingleImage?id=${image.id}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  uploadPhoto(
    file: File,
    photoMetaData: PhotoMetaData,
    options: { createOriginal?: boolean; revertToOriginal?: boolean } = {}
  ): Observable<PhotoMetaData> {
    const { createOriginal, revertToOriginal } = Object.assign(
      { createOriginal: true, revertToOriginal: false },
      options
    );

    const updateDatabaseRequest = () => {
      let series = JSON.parse(JSON.stringify(photoMetaData));
      this.sanitizePhotoMetaData(series);
      if (revertToOriginal || createOriginal) {
        series.isOriginal = true;
      } else {
        series.isOriginal = false;
      }
      return this.http.put<PhotoMetaData>(`${environment.baseUrl}api/Photo/UpdatePhotoMetaData`, series).pipe(
        map((data) => {
          //this.patientService.thePatientHasBeenUpdatedById(photoMetaData.patientId);
          return data;
        })
      );
    };

    // save-changes event can be passed a file or photoMetaData
    // Make sure file ^^^ is a file and not photoMetaData
    if (!file.size) {
      return updateDatabaseRequest();
    }

    // if we havn't got a fileName already create a random one
    photoMetaData.fileId = photoMetaData.fileId ? photoMetaData.fileId : `${uuidv4()}.${file.name.split('.').pop()}`;
    const newFormData = new FormData();
    newFormData.append(file.name, file);

    var clinicUrl = this.clinicsService.clinicUrl;
    const baseFilePath = photoMetaData.patientId
      ? `${clinicUrl}` + `patients/photos/${photoMetaData.patientContainerFolderName}`
      : `${clinicUrl}` + 'clinic/photos';

    // create a thumbnail of the image
    return this.http.post<any>(`${environment.baseUrl}api/Photo/GetThumbnail`, newFormData).pipe(
      switchMap((thumbnail) => {
        if (!thumbnail) {
          return throwError(null);
        }
        // take the file data returned and massage it into a proper File object
        const binaryString = window.atob(thumbnail.fileContents);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const arrayOfBlob = new Array<Blob>();
        arrayOfBlob.push(new Blob([bytes], { type: file.type }));
        const thumbFile = new File(arrayOfBlob, photoMetaData.fileId, { type: file.type });

        return this.blobService.uploadFileToBlobStorage(
          thumbFile,
          `${baseFilePath}/thumbs/${photoMetaData.fileId}`,
          {}
        );
      }),
      switchMap((blobURI: string) => {
        photoMetaData.filePathThumb = blobURI;
        return this.blobService.uploadFileToBlobStorage(file, `${baseFilePath}/emily/${photoMetaData.fileId}`, {});
      }),
      switchMap((blobURI: string) => {
        // Update the photo meta data with the blobs uri
        photoMetaData.filePath = blobURI;
        // Set the height of the image
        if (createOriginal) {
          return this.blobService.uploadFileToBlobStorage(
            file,
            `${baseFilePath}/originals/${photoMetaData.fileId}`,
            {}
          );
        } else {
          return of('');
        }
      }),
      switchMap((originalBlobURI: string) => {
        if (originalBlobURI) {
          photoMetaData.filePathOriginal = originalBlobURI;
        }
        return combineLatest([this.blobService.getReadOnlySASObservable(), updateDatabaseRequest()]).pipe(
          map(([readOnlySAS, photoMeta]) => {
            const forceImageRefreshParam = '&forceRefresh=' + generateRandomString();
            photoMeta.filePath =
              (photoMeta.filePath ? photoMeta.filePath.trim() : '') + readOnlySAS + forceImageRefreshParam;
            photoMeta.filePathThumb =
              (photoMeta.filePathThumb ? photoMeta.filePathThumb.trim() : '') + readOnlySAS + forceImageRefreshParam;
            photoMeta.filePathOriginal =
              (photoMeta.filePathOriginal ? photoMeta.filePathOriginal.trim() : '') +
              readOnlySAS +
              forceImageRefreshParam;
            if (!isNullOrUndefined(photoMeta.seriesPhotos)) {
              photoMeta.seriesPhotos.forEach((sp: PhotoMetaData) => {
                sp.filePath = (sp.filePath ? sp.filePath.trim() : '') + readOnlySAS;
                sp.filePathThumb = (sp.filePathThumb ? sp.filePathThumb.trim() : '') + readOnlySAS;
              });
            }
            //this.patientService.thePatientHasBeenUpdatedById(photoMetaData.patientId);

            return photoMeta;
          })
        );
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  uploadPhotoMetaData(photoMetaData: PhotoMetaData) {
    let series = JSON.parse(JSON.stringify(photoMetaData));
    this.sanitizePhotoMetaData(series);
    // To be called when changes are made to the photo metadata only
    const formData = new FormData();
    formData.append('metadata', JSON.stringify(series));

    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.post<PhotoMetaData>(environment.baseUrl + 'api/Photo/MetaData', formData),
    ]).pipe(
      take(1),
      map(([readOnlySAS, photoMeta]) => {
        let photo = new PhotoMetaData(photoMeta, photoMetaData);
        photo.filePath = (photoMeta.filePath ? photoMeta.filePath.trim() : '') + readOnlySAS;
        photo.filePathThumb = (photoMeta.filePathThumb ? photoMeta.filePathThumb.trim() : '') + readOnlySAS;
        photo.filePathOriginal = (photoMeta.filePathOriginal ? photoMeta.filePathOriginal.trim() : '') + readOnlySAS;

        this.metadataUpdated.next(photo);

        return photo;
      })
    );
  }

  uploadUserAvatar(file: File, fileName = ''): Observable<string> {
    // if we havn't got a fileName already create a random one
    fileName = fileName ? fileName : `${uuidv4()}.${file.name.split('.').pop()}`;

    var clinicUrl = this.clinicsService.clinicUrl;
    const filePath = `${clinicUrl}staff/photos/avatar/`;

    return this.blobService.uploadFileToBlobStorage(file, filePath + `${fileName}`, {
      // This options object is purely here to allow you to have a progress function that may update the UI otherwise you can remove it
      progress: (progress) => {
        // TODO: Do something with percent if you want
        const percent = progress.loadedBytes / file.size;
      },
    });
  }
  uploadClinicLogo(file: File, fileName = ''): Observable<string> {
    // if we havn't got a fileName already create a random one
    fileName = fileName ? fileName : `${uuidv4()}.${file.name.split('.').pop()}`;

    var clinicUrl = this.clinicsService.clinicUrl;
    const filePath = `${clinicUrl}clinic/logo/`;

    return this.blobService.uploadFileToBlobStorage(file, filePath + `${fileName}`, {});
  }

  emailPhoto(email: string, photoId: number, filename: string, patientId: number) {
    // let file = new File([blob], filename);
    // const newFormData = new FormData();
    // newFormData.append(file.name, file);

    return this.http.post<any>(
      `${environment.baseUrl}api/Photo/EmailPhoto/${email}/${photoId}/${filename}/${patientId}`,
      {}
    );
  }

  sanitizePhotoMetaData(photoMetaData: PhotoMetaData) {
    if (photoMetaData.filePath) {
      photoMetaData.filePath = removeURIQueryString(photoMetaData.filePath);
    }
    if (photoMetaData.filePathThumb) {
      photoMetaData.filePathThumb = removeURIQueryString(photoMetaData.filePathThumb);
    }
    if (photoMetaData.filePathOriginal) {
      photoMetaData.filePathOriginal = removeURIQueryString(photoMetaData.filePathOriginal);
    }
    if (photoMetaData.seriesPhotos) {
      photoMetaData.seriesPhotos.forEach((sp: PhotoMetaData) => {
        if (sp.filePath) {
          sp.filePath = removeURIQueryString(sp.filePath);
        }
        if (sp.filePathThumb) {
          sp.filePathThumb = removeURIQueryString(sp.filePathThumb);
        }
      });
    }
    if (photoMetaData.uploadDate && photoMetaData.uploadDate.toDate) {
      //@ts-ignore
      photoMetaData.uploadDate = photoMetaData.uploadDate.toDate();
    }
  }

  getConsentedImages() {
    return combineLatest(
      this.blobService.getReadOnlySASObservable(),
      this.http.get<PhotoMetaData[]>(environment.baseUrl + 'api/Photo/Consented')
    ).pipe(
      map(([readOnlySAS, images]) => {
        images.forEach((image) => {
          image.filePath = (image.filePath ? image.filePath.trim() : '') + readOnlySAS;
          image.filePathThumb = (image.filePathThumb ? image.filePathThumb.trim() : '') + readOnlySAS;
          if (!isNullOrUndefined(image.seriesPhotos)) {
            image.seriesPhotos.forEach((sp: PhotoMetaData) => {
              sp.filePath = (sp.filePath ? sp.filePath.trim() : '') + readOnlySAS;
              sp.filePathThumb = (sp.filePathThumb ? sp.filePathThumb.trim() : '') + readOnlySAS;
            });
          }
        });
        return images;
      })
    );
  }

  globalImages: PhotoMetaData[] = null; //caching for the session is likely fine for now -  even faster by including the sas in this variable but then youl be subject to sas expiration too
  getGlobalSharedImages(): Observable<PhotoMetaData[]> {
    return this.globalImages && this.globalImages.length > 0
      ? of(this.globalImages)
      : combineLatest([
          this.blobService.getReadOnlySASObservable(),
          this.http.get<PhotoMetaData[]>(environment.baseUrl + 'api/Photo/GlobalSharedImages'),
        ]).pipe(
          map(([readOnlySAS, images]) => {
            images.forEach((image) => {
              image.filePath = (image.filePath ? image.filePath.trim() : '') + readOnlySAS;
              image.filePathThumb = (image.filePathThumb ? image.filePathThumb.trim() : '') + readOnlySAS;
              if (!isNullOrUndefined(image.seriesPhotos)) {
                image.seriesPhotos.forEach((sp: PhotoMetaData) => {
                  sp.filePath = (sp.filePath ? sp.filePath.trim() : '') + readOnlySAS;
                  sp.filePathThumb = (sp.filePathThumb ? sp.filePathThumb.trim() : '') + readOnlySAS;
                });
              }
            });
            this.globalImages = images;
            return images;
          })
        );
  }

  sortImages(galleryImagesMetaData: PhotoMetaData[]) {
    return galleryImagesMetaData.sort((a, b) => moment.utc(b.uploadDate).diff(moment.utc(a.uploadDate)));
  }

  setPhotoFavouriteStatus(photo: PhotoMetaData, isFavourite: boolean) {
    return this.http
      .put<PhotoMetaData>(environment.baseUrl + 'api/Photo/Favourite/' + photo.id + '/' + isFavourite, {})
      .map((data) => {
        this.metadataUpdated.next(photo);
        return data;
      });
  }
}
