import { SelectionModel } from '@angular/cdk/collections';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { DateSelectDialogComponent } from '@app/management/dialogs/date-select-dialog/date-select-dialog.component';
import { CreateNudgesComponent } from '@app/patients/patient-tabs/patient-nudges-tab/create-nudges/create-nudges.component';
import { PhotoLightboxComponent } from '@app/patients/patient-tabs/shared-photos/photo-lightbox/photo-lightbox.component';
import { ConvertUTCPipe } from '@app/shared/pipes/convert-utc.pipe';
import { PatientForm } from '@models/forms/patient-form';
import { ServiceForm } from '@models/forms/service-form';
import { NudgeReferenceType } from '@models/nudges/reference-type';
import { Patient } from '@models/patient';
import { TargetTypes } from '@models/patient-transaction/target-types';
import { PhotoDrawing } from '@models/photo/photo-drawing';
import { Appointment } from '@models/scheduler/appointment';
import { PaymentStatus } from '@models/scheduler/payment-status';
import { ChartAppointment } from '@models/service-chart/chart-appointment';
import { ChartEntry } from '@models/service-chart/chart-entry';
import { ServiceBillingCode } from '@models/service/service-billing-code';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { ServiceNote } from '@models/service/service-note';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionPanelService, MasterOverlayService } from '@services/actionpanel.service';
import { AppointmentSignalrService } from '@services/appointment-signalr.service';
import { AppointmentService } from '@services/appointments.service';
import { ClinicsService } from '@services/clinics.service';
import { InvoicesService } from '@services/invoices.service';
import { PatientFormService } from '@services/patient-form.service';
import { PhotoDrawingService } from '@services/photo-drawing.service';
import { ServicesService } from '@services/services.service';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { UsersService } from '@services/users.service';
import { VisitService } from '@services/visit.service';
import moment from 'moment';
import { EMPTY, Observable, Subject, forkJoin, throwError } from 'rxjs';
import { catchError, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { eTreatmentFormComponent } from '../../../etreatment-form/etreatment-form.component';
import { ServiceChartDrawToolComponent } from '../../modals/service-chart-draw-tool/service-chart-draw-tool.component';

@Component({
  selector: 'app-chart-appointment-entry',
  templateUrl: './chart-appointment-entry.component.html',
  styleUrls: ['./chart-appointment-entry.component.less'],
})
export class ChartAppointmentEntryComponent implements OnInit, OnDestroy {
  errorMessage = '';
  isLoading = false;
  showServiceDetail = false;
  submitButtons = { draft: false, save: false };
  currentFormsSelectionModel: SelectionModel<number> = new SelectionModel<number>();
  chartEntry: ChartAppointment;
  serviceDetailTemplate = ServiceDetailTemplate;
  PaymentStatus = PaymentStatus;
  appointmentsPolicy = Policies.appointments;
  patientAccountPolicy = Policies.patientAccount;
  patientAccountPolicySatisfied = false;
  parseInt = parseInt;
  unsub: Subject<void> = new Subject<void>();

  @ViewChild('existingNote') existingNote: ElementRef<HTMLTextAreaElement>;
  @ViewChild('newNote') newNote: ElementRef<HTMLTextAreaElement>;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() appointmentId: number;
  @Input() scrollIndex: number;
  @Input() patient: Patient;
  @Input() applyNote$: Subject<string>;
  @Input() applyPhoto$: Subject<PhotoDrawing>;
  @Input() focusedIndex: number;
  @Output() focusedIndexChange = new EventEmitter<number>();
  @Input() set expandAll(expand: boolean) {
    this.showServiceDetail = expand;
    this.toggleTextArea();
  }
  @Output() chartSelected = new EventEmitter<ChartAppointment>();
  @Output() chartEdited = new EventEmitter<boolean>();
  @Output() openMinistryBilling = new EventEmitter<void>();
  @Output() removeEntry = new EventEmitter<void>();

  constructor(
    private dialog: MatDialog,
    private servicesService: ServicesService,
    private router: Router,
    private route: ActivatedRoute,
    private clinicService: ClinicsService,
    private appointmentService: AppointmentService,
    private visitsService: VisitService,
    private photoDrawingService: PhotoDrawingService,
    private invoicesService: InvoicesService,
    private userService: UsersService,
    private treatmentPlanService: TreatmentPlanService,
    private authService: AuthService,
    private modalService: NgbModal,
    private patientFormService: PatientFormService,
    private masterOverlayService: MasterOverlayService,
    private actionPanelService: ActionPanelService,
    private appointmentSignalrService: AppointmentSignalrService,
    private _ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.patientAccountPolicySatisfied = this.authService.userSatisfiesPolicy(this.patientAccountPolicy);
    this.getChartEntry();

    this.appointmentSignalrService.apptUpdated$
      .pipe(
        takeUntil(this.unsub),
        filter((appointment) => this.appointmentId === appointment.appointmentId)
      )
      .subscribe((appointment: Appointment) => {
        this.getChartEntry();
      });

    this.patientFormService.patientFormSubmitted$
      .pipe(
        takeUntil(this.unsub),
        filter((patientForm) => this.appointmentId === patientForm.appointmentId)
      )
      .subscribe((submittedForm) => {
        const formIndex = this.chartEntry.appointmentForms.findIndex(
          (pf) => pf.clinicFormId == submittedForm.clinicFormId
        );
        if (formIndex !== -1) {
          this.chartEntry.appointmentForms[formIndex] = submittedForm;
        }
      });

    this.actionPanelService
      .actionPanelVisible()
      .pipe(takeUntil(this.unsub))
      .subscribe(() => {
        setTimeout(() => {
          this.triggerResize();
        });
      });

    this.applyNote$.pipe(takeUntil(this.unsub)).subscribe((note) => {
      if (this.scrollIndex != null && this.focusedIndex != null && this.scrollIndex === this.focusedIndex) {
        const htmlElement = this.newNote.nativeElement;
        htmlElement.focus();
        if (htmlElement.value) {
          htmlElement.value = htmlElement.value + '\n' + note;
        } else {
          htmlElement.value = note;
        }
        this.chartEdited.emit(true);
        this.submitButtons.draft = true;
      }
    });

    this.applyPhoto$.pipe(takeUntil(this.unsub)).subscribe((photoDrawing) => {
      if (this.scrollIndex != null && this.focusedIndex != null && this.scrollIndex === this.focusedIndex) {
        this.chartEntry.photoDrawings.push(photoDrawing);
        this.chartEdited.emit(true);
        this.submitButtons.draft = true;
      }
    });
  }

  private getChartEntry() {
    this.isLoading = true;
    this.appointmentService.getChartAppointment(this.appointmentId).subscribe(
      (chartEntry) => {
        if (chartEntry.billingCodes.length === 0) {
          chartEntry.billingCodes.push(this.initEmptyServiceBillingCode());
        }
        this.currentFormsSelectionModel = new SelectionModel<number>();
        if (chartEntry.appointmentForms.length > 0) {
          this.currentFormsSelectionModel = new SelectionModel<number>(
            true, // <- multi-select
            chartEntry.appointmentForms.map((pf: PatientForm) => pf.clinicFormId) || [], // <- Initial selections
            true // <- emit an event on selection change
          );
        }
        this.chartEntry = chartEntry;
        this.isLoading = false;
      },
      (error) => {
        this.errorMessage = error;
        this.isLoading = false;
      }
    );
  }

  private initEmptyServiceBillingCode(): ServiceBillingCode {
    return {
      billingCode: '',
      serviceUnits: 1,
      id: 0,
      amount: null,
      isPaid: false,
    };
  }

  toggleTextArea() {
    if (this.showServiceDetail) {
      this.existingNote?.nativeElement.classList.remove('collapsed-area');
    } else {
      this.existingNote?.nativeElement.classList.add('collapsed-area');
    }
  }

  onTextareaFocus() {
    if (!this.chartEntry.isLocked) {
      this.focusedIndex = this.scrollIndex;
      this.focusedIndexChange.emit(this.scrollIndex);
    }
    this.showServiceDetail = true;
  }

  serviceNotesLength(): number {
    return this.chartEntry?.serviceNotes.filter((sn) => sn.note).length;
  }

  onTextareaKeyDown() {
    this.chartEdited.emit(true);
    if (this.chartEntry && !this.chartEntry.isLocked) {
      this.submitButtons.draft = true;
      this.submitButtons.save = false;
    }
  }

  notesFocus() {
    this.newNote.nativeElement.focus();
    this.newNote.nativeElement.classList.remove('collapsed-area');
    this.focusedIndex = this.scrollIndex;
    this.focusedIndexChange.emit(this.scrollIndex);
  }

  openeTreatmentForm() {
    this.dialog.open(eTreatmentFormComponent, {
      panelClass: 'document-view-modal',
      disableClose: true,
      data: {
        appointmenteTreatmentForm: this.chartEntry.appointmenteTreatmentForms[0],
        editable: !this.chartEntry.isLocked,
      },
    });
  }

  goToTheServiceDetail() {
    this.servicesService.isPaid = this.chartEntry.paymentStatus == PaymentStatus.Paid;
    this.router.navigate(
      [
        '../detail',
        this.chartEntry.serviceId,
        this.chartEntry.isLocked,
        false,
        this.chartEntry.paymentStatus == PaymentStatus.Paid,
      ],
      {
        relativeTo: this.route,
      }
    );
  }

  async clickRescheduleService() {
    const dialogRef = this.dialog.open(DateSelectDialogComponent, {
      width: '300px',
      data: {
        title: 'Change Chart Date',
        inputLabel: 'New date/time',
        confirmButtonText: 'Change Date',
        minimumDuration: this.clinicService.clinic.minimumDuration,
        maximumDate: new Date(Date.now()),
        date: new Date(new ConvertUTCPipe().transform(this.chartEntry.date.toDateString(), true)),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result == null || result == 'cancel') return;
      const targetDate = moment(result);
      const fromVisit = await this.visitsService.getVisitByServiceId(this.chartEntry.serviceId).toPromise();
      const toVisit = await this.visitsService.getVisitForAppointmentMove(fromVisit.visitId, targetDate);
      this.appointmentService.moveAppointment(targetDate, fromVisit.visitId, toVisit.visitId, this.appointmentId);
    });
  }

  private updateServiceDrawings(): Observable<any> {
    let toAdd = [];
    let toUpdate = [];
    let toDelete = [];
    let toDeleteIndexes = [];

    if (!this.chartEntry.photoDrawings) return EMPTY;

    this.chartEntry.photoDrawings.forEach((drawing, index) => {
      if (!drawing.id) toAdd.push(drawing);
      else if (drawing['update']) {
        toUpdate.push(drawing);
      } else if (drawing['delete']) {
        let removeIndex = this.patient.photos.findIndex((photo) => photo.id == drawing.photoId);
        this.patient.photos.splice(removeIndex, 1);
        toDelete.push(drawing);
        toDeleteIndexes.push(index);
      }
    });

    toDeleteIndexes.forEach((index) => this.chartEntry.photoDrawings.splice(index, 1));

    let addRequests = toAdd.map((pd) =>
      this.photoDrawingService.addPhotoDrawing(pd).pipe(
        tap((added) => {
          pd.id = added.id;
          this.patient.photos.push(added.photo);
        })
      )
    );
    let updateRequests = toUpdate.map((pd) =>
      this.photoDrawingService.updatePhotoDrawing(pd).pipe(
        tap((updated) => {
          pd = updated;
          const updateLocation = this.patient.photos.findIndex((photo) => photo.id == pd.photo.id);
          this.patient.photos[updateLocation] = pd.photo;
        })
      )
    );
    let deleteRequest = this.photoDrawingService.deletePhotoDrawings(toDelete);

    let allRequests = [deleteRequest];
    allRequests.push(...addRequests);
    allRequests.push(...updateRequests);

    return forkJoin([...addRequests, ...updateRequests, deleteRequest]);
  }

  async cancelAppointment() {
    const appointment = await this.appointmentService.getAppointmentById(this.chartEntry.appointmentId).toPromise();
    const cancelled = await this.appointmentService.handleCancellation(appointment);
    if (cancelled) this.removeEntry.emit();
  }

  onUnlockClick() {
    this.notesFocus();
    this.submitButtons.draft = true;
    this.showServiceDetail = true;
    this.unLockChartEntry();
  }

  unLockChartEntry() {
    this.isLoading = true;
    let appointment = new Appointment();
    appointment.appointmentId = this.appointmentId;
    return this.servicesService.unLockService(this.chartEntry.serviceId, appointment).subscribe(
      () => {
        this.chartEntry.isLocked = false;
        this.submitButtons.draft = false;
        this.appointmentService.onAllApptsUpdated();
        if (this.chartEntry.plannedTreatmentId) this.treatmentPlanService.treatmentPlanScheduled$.next();
        this.isLoading = false;
      },
      (error) => {
        this.errorMessage = error;
        this.isLoading = false;
      }
    );
  }

  openPhotoDrawing(photoDrawing: PhotoDrawing) {
    if (this.chartEntry.isLocked) {
      this.dialog.open(PhotoLightboxComponent, {
        panelClass: 'lightbox-dialog',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        data: {
          photoURL: photoDrawing.photo.filePath,
        },
      });
    } else {
      const modalRef = this.modalService.open(ServiceChartDrawToolComponent, {
        centered: true,
        windowClass: 'draw-tool-modal',
      });
      modalRef.componentInstance.patient = this.patient;
      modalRef.componentInstance.photoDrawing = photoDrawing;
      modalRef.result.then((result) => {
        // this.scrollToEntry.emit(null);
        if (result && result !== 'delete') {
          let [data, updatedPhotoDrawing]: [Blob, PhotoDrawing] = result;
          photoDrawing = updatedPhotoDrawing;
          photoDrawing.photo.filePath = photoDrawing.photo.filePathThumb = URL.createObjectURL(data);
          photoDrawing['update'] = true;
          this.chartEdited.emit(true);
          this.submitButtons.draft = true;
        } else if (result === 'delete') {
          photoDrawing['delete'] = true;
          this.chartEdited.emit(true);
          this.submitButtons.draft = true;
        }
      });
    }
  }

  onChangeCurrentFormsSelectionModel(event: MatCheckboxChange, serviceForm: ServiceForm) {
    if (event.checked) {
      // Adding an Appointment Form
      let patientForm = PatientForm.fromForm(this.patient.patientId, serviceForm.clinicForm);
      patientForm.appointmentId = this.chartEntry.appointmentId;
      this.patientFormService.addPatientForm(patientForm).subscribe((newPatientForm) => {
        // Add it to the ServiceCharts
        this.chartEntry.appointmentForms.push(newPatientForm);
      });
    } else if (!this.isSigned(serviceForm)) {
      // Find Appointment Patient Form
      let appointmentPatientForm = this.chartEntry.appointmentForms.filter(
        (apf) => apf.clinicFormId == serviceForm.clinicFormId
      )[0];
      // Remove it
      this.patientFormService.deletePatientForm(appointmentPatientForm.id).subscribe(() => {
        // Remove the appointment form from ServiceCharts
        var indexToRemove = this.chartEntry.appointmentForms.indexOf(appointmentPatientForm, 0);
        this.chartEntry.appointmentForms.splice(indexToRemove, 1);
      });
    }
  }

  isSigned(serviceForm: ServiceForm) {
    // Find Appointment Patient Form
    let appointmentPatientForm = this.chartEntry.appointmentForms.filter(
      (apf) => apf.clinicFormId == serviceForm.clinicFormId
    )[0];
    if (appointmentPatientForm) {
      return appointmentPatientForm.isSigned;
    } else {
      return false;
    }
  }

  isChecked(serviceForm: ServiceForm) {
    // Check if ServiceForm is in the AppointmentForms for this ServiceChartEntry
    if (this.chartEntry.appointmentForms) {
      var result = this.chartEntry.appointmentForms.find((af) => af.clinicFormId == serviceForm.clinicFormId);
      if (result) return true;
      else return false;
    } else return false;
  }

  updateServicePrice() {
    this.isLoading = true;
    if (this.chartEntry.price) {
      const service: { serviceId: number; appointmentId: number; price: number | string } = {
        serviceId: this.chartEntry.serviceId,
        appointmentId: this.chartEntry.appointmentId,
        price: this.chartEntry.price,
      };
      this.servicesService.updateServicePrice(service).subscribe(
        () => {
          this.isLoading = false;
          this.invoicesService.getInvoiceById;
          this.invoicesService.invoiceUpdated.next(this.chartEntry.invoiceId);
        },
        () => {
          this.isLoading = false;
        }
      );
    }
  }

  async updateChart(isLock: boolean): Promise<any> {
    const element = this.newNote.nativeElement;
    let value = element.value;
    const signedInfo: string =
      '- Signed by ' +
      this.userService.loggedInUser.firstName +
      ' ' +
      this.userService.loggedInUser.lastName +
      ' (' +
      moment(new Date()).format('YYYY-MM-DD h:mm A') +
      ')';
    if (isLock) {
      value += '\n' + signedInfo + '\n';

      if (this.chartEntry.serviceNotes.length === 0) {
        this.chartEntry.serviceNotes.push({ date: new Date(), note: value, draft: '', serviceNoteId: 0 });
      } else {
        this.chartEntry.serviceNotes[0].note += '\n' + value;
        this.chartEntry.serviceNotes[0].draft = '';
      }
    } else {
      if (this.chartEntry.serviceNotes.length === 0) {
        this.chartEntry.serviceNotes.push({ date: new Date(), note: '', draft: value, serviceNoteId: 0 });
      } else {
        this.chartEntry.serviceNotes[0].draft = value;
      }
    }

    this.chartEdited.emit(false);
    this.isLoading = true;

    await this.updateChartEntry();
    let appointment = new Appointment();
    appointment.appointmentId = this.chartEntry.appointmentId;
    this.appointmentSignalrService.updateAppointment(appointment);

    if (isLock) {
      element.value = '';
      await this.lockChartEntry().toPromise();
    }
    this.isLoading = false;
  }

  lockChartEntry(): Observable<any> {
    let appointment = new Appointment();
    appointment.appointmentId = this.appointmentId;
    return this.servicesService.lockService(this.chartEntry.serviceId, appointment).pipe(
      map(() => {
        this.updateSignInformation();
        this.appointmentService.onAllApptsUpdated();
        if (this.chartEntry.plannedTreatmentId)
          this.treatmentPlanService.plannedTreatmentCompleted$.next(this.chartEntry.plannedTreatmentId);
        return true;
      }),
      catchError((err) => {
        this.errorMessage = err;
        return throwError(err);
      })
    );
  }

  updateSignInformation() {
    this.chartEntry.isLocked = true;
    this.chartEntry.signedTime = new Date();
    this.chartEntry.signedByUser =
      this.userService.loggedInUser.firstName + ' ' + this.userService.loggedInUser.lastName;
  }

  async signSaveExit() {
    await this.updateChart(true);
    this.submitButtons.save = false;
    this.submitButtons.draft = false;
    this.closePatientPanel();
  }

  selectedChartForInvoice() {
    if (this.chartEntry.plannedTreatmentId === 0) {
      this.router.navigate(['../../patientaccounttab/invoice', this.chartEntry.invoiceId], { relativeTo: this.route });
    } else {
      this.router.navigate(['../../patientaccounttab'], { relativeTo: this.route });
    }
  }

  updateChartEntry(): Promise<any> {
    return forkJoin([this.updateServiceNote(), this.updateServiceDrawings()])
      .pipe(
        catchError((err) => {
          this.errorMessage = err;
          return throwError(err);
        })
      )
      .toPromise();
  }

  updateServiceNote(): Observable<ServiceNote> {
    const serviceNoteId: number =
      this.chartEntry.serviceNotes.length > 0 ? this.chartEntry.serviceNotes[0].serviceNoteId : 0;

    const newServiceNote: ServiceNote = new ServiceNote({
      serviceNoteId: serviceNoteId,
      entryDate: new Date(),
      enteredBy: this.userService.loggedInUser.firstName + ' ' + this.userService.loggedInUser.lastName,
      entryText: this.chartEntry.serviceNotes[0].note,
      draftText: this.chartEntry.serviceNotes[0].draft,
    });

    return this.servicesService.addServiceNote(this.appointmentId, newServiceNote, TargetTypes.Appointment);
  }

  closePatientPanel() {
    this.masterOverlayService.masterOverlayState(false);
    const returnURL = this.router.url.slice(0, this.router.url.indexOf('('));
    this.router.navigate([returnURL, { outlets: { 'action-panel': null } }]);
  }

  openMinistryModel() {
    this.openMinistryBilling.emit();
  }

  openNudgeModal(chartEntry: ChartEntry) {
    const dialogRef = this.dialog.open(CreateNudgesComponent, {
      panelClass: 'custom-dialog-container',
      width: '550px',
      data: {
        patientId: this.patient,
        referenceId: chartEntry.serviceId,
        referenceType: NudgeReferenceType.Service,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  triggerResize() {
    this._ngZone.onStable.pipe(take(1)).subscribe(() => {
      if (this.autosize && this.autosize.resizeToFitContent) {
        this.autosize.resizeToFitContent(true);
      }
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
    this.chartEntry?.photoDrawings?.forEach((pd) => {
      if (pd.photo?.filePath) {
        URL.revokeObjectURL(pd.photo.filePath);
      }
    });
  }
}
