import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { isNullOrUndefined } from '@app/shared/helpers';

import { ServicesService } from '@services/services.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { ObservationTypesService } from '@services/observation-types.service';
import { InjectionFormService } from '@services/service-detail/injection-form.service';
import { CurrentDataService } from '@services/currentData.service';
import { ServiceEventService } from '@services/service-event.service';
import { ServiceListType } from '@models/service/service-list-type';
import { Observation, ObservationType, ObservationTypes } from '@models/observation/observation';
import { Service } from '@models/service/service';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { ObservationListItemsService } from '@services/observation-list-items.service';
import { PreviousTreatment } from '@models/treatment-planning/previous-treatment';

@Component({
  selector: 'app-history-item',
  templateUrl: './history-item.component.html',
  styleUrls: ['./history-item.component.less'],
})
export class HistoryItemComponent implements OnInit {
  @Input() appointments: PreviousTreatment[];
  @Input() serviceTemplateId: number;
  @Input() isLocked: boolean;

  serviceListType = ServiceListType;
  expanded: number;

  observationType: ObservationType;
  observationTypes = ObservationTypes;

  constructor(
    private serviceTemplatesService: ServiceTemplatesService,
    private observationTypesService: ObservationTypesService,
    private injectionFormService: InjectionFormService,
    private servicesService: ServicesService,
    private dialog: MatDialog,
    private currentDataService: CurrentDataService,
    private serviceEventService: ServiceEventService,
    private observationListItemsService: ObservationListItemsService
  ) {
    // All items collapsed by default
    this.expanded = -1;
  }

  ngOnInit() {
    this.findObservationType();
    this.injectionFormService.initObservationUnits(null);
  }

  findObservationType() {
    this.serviceTemplatesService.getServiceTemplateById(this.serviceTemplateId).subscribe((s) => {
      if (!isNullOrUndefined(s)) {
        let obrTypeName;
        switch (s.serviceDetailTemplateId) {
          case ServiceDetailTemplate.Injections:
            obrTypeName = ObservationTypes.Injectables;
            break;
          case ServiceDetailTemplate.Coolsculpting:
            obrTypeName = ObservationTypes.Coolsculpting;
            break;
          case ServiceDetailTemplate.CoolTone:
            obrTypeName = ObservationTypes.CoolTone;
            break;
          case ServiceDetailTemplate.Deoxycholate:
            obrTypeName = ObservationTypes.Injectables;
            break;
          default:
            break;
        }
        this.observationTypesService.getObservationTypeByName(obrTypeName).subscribe((obrType) => {
          this.observationType = obrType;
        });
      }
    });
  }

  isNullOrUndefined(obj: any) {
    return isNullOrUndefined(obj);
  }

  trackByFunction(index: number, item: any) {
    return index;
  }

  public applyPreviousTreatment(item) {
    if (this.isLocked) {
      return;
    }

    this.getService(item.serviceId);
  }

  private getService(serviceId: number) {
    this.servicesService.getServiceById(serviceId).subscribe((service: Service) => {
      service.serviceNotes = []; //do not pass service notes
      const parsedService = JSON.parse(JSON.stringify(service));
      if (parsedService.observations.length) {
        parsedService.observations = parsedService.observations.map((e) => {
          e.details = JSON.parse(e.details);

          return e;
        });

        const observationType: ObservationTypes =
          this.observationType.name === this.observationTypes.Injectables
            ? this.observationTypes.Injectables
            : this.observationTypes.Coolsculpting;
        // Apply updated costs
        const observations = JSON.parse(JSON.stringify(parsedService.observations));
        observations.forEach((obr: Observation) => {
          this.observationListItemsService.assignCostToObservation(obr);
        });
        parsedService.observations = observations;
        this.serviceEventService.emitApplyTreatment(parsedService, observationType);
        this.currentDataService.treatmentIsDirty = true;
      } else {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
          width: '300px',
          data: {
            title: 'Warning',
            content: 'Unable to add treatment without treatment details',
            confirmButtonText: 'OK',
            showCancel: false,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'confirm') {
          }
        });
      }
    });
  }
}
