import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { EformConfig } from '@app/eform.config';
import { FormioAppConfig, FormioModule } from '@formio/angular';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { FormIoApiService } from '@services/formio-api.service';
import { SharedModule } from '../../shared/shared.module';
import { FormEditComponent } from './form-edit/form-edit.component';
import { FormLibraryOverviewComponent } from './form-library-overview/form-library-overview.component';
import { ConfirmFormDeleteComponent } from './forms-list/confirm-form-delete/confirm-form-delete.component';
import { FormsListComponent } from './forms-list/forms-list.component';
import { FormsComponent } from './forms.component';
import { ShareFormComponent } from './share-form/share-form.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        MatTabsModule,
        GridModule,
        ExcelModule,
        FormioModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatExpansionModule,
        MatDialogModule,
    ],
    declarations: [
        FormsComponent,
        FormEditComponent,
        FormsListComponent,
        ShareFormComponent,
        FormLibraryOverviewComponent,
        ConfirmFormDeleteComponent,
    ],
    exports: [
        FormsComponent,
        FormEditComponent,
        FormsListComponent,
        ShareFormComponent,
        FormLibraryOverviewComponent,
        ConfirmFormDeleteComponent,
    ],
    providers: [{ provide: FormioAppConfig, useValue: EformConfig }, FormIoApiService]
})
export class FormsModule {}
