import { User } from './user';
import { Appointment } from './scheduler/appointment';
import { Patient } from './patient';
import { ChargeableItem } from './billing/chargeable-item';
import { IChargeableAsset } from '@interfaces/IChargeableAsset';
import { VisitChargeableAsset } from './billing/visit-chargeable-asset';
import { VisitConfirmedStatus } from './visit-confirm-status';

export class Visit implements IChargeableAsset {
  visitId: number;
  visitIdString: string;
  patientId: number;
  patient?: Patient;
  visitNotes?: string;
  patientNotes?: string;
  cancellationReason: string;
  cancellationMessage?: string;
  isCancellationAlert: boolean;
  cancellationDate?: Date;
  cancelled: boolean;
  reasonCancellationNotCharged: string;
  cancelledByUserId: string;
  appointments: Appointment[];
  totalVisitCost: number = 0;
  overrideTotalVisitCost: number;
  chargeAmount: number;
  overrideChargeAmount: number;
  checkedIn: boolean;
  confirmedStatus: VisitConfirmedStatus;
  confirmedTime: Date;
  manuallyConfirmedByUser: User;
  manuallyConfirmedByUserId: string;
  noShow: boolean;
  reviewRequested: boolean;
  date: Date;
  createdBy: string;
  emailReminderSent: Date;
  smsReminderSent: Date;

  constructor(init?: Partial<Visit>) {
    this.cancellationReason = '';
    (this.appointments = []), (this.date = new Date());
    this.createdBy = '';

    Object.assign(this, init);
  }

  public getBaseAndChildrenChargeableItems(): ChargeableItem[] {
    const chargeableItems: ChargeableItem[] = [];

    if (this.totalVisitCost || this.overrideTotalVisitCost) {
      chargeableItems.push(
        new ChargeableItem({ defaultPrice: this.totalVisitCost, overridePrice: this.overrideTotalVisitCost })
      );
    }

    if (this.appointments != null && this.appointments.length > 0) {
      for (const appointment of this.appointments) {
        const appt = new Appointment(appointment);
        chargeableItems.push(...appt.getChargeableItems());
      }
    }

    return chargeableItems;
  }

  public getChargeableItems(): ChargeableItem[] {
    let chargeableItems: ChargeableItem[] = [];
    if (this.chargeAmount || this.overrideChargeAmount) {
      chargeableItems.push(
        new ChargeableItem({ defaultPrice: this.chargeAmount, overridePrice: this.overrideChargeAmount })
      );
    } else {
      chargeableItems = this.getBaseAndChildrenChargeableItems();
    }

    return chargeableItems;
  }

  public getChargeAmount() {
    const asset = new VisitChargeableAsset(this);
    return asset.getChargeAmount();
  }
}
