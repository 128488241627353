import { Injectable } from '@angular/core';
import { Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import { Service } from '@models/service/service';
import { CompanyService } from './company.service';
import { UsersService } from './users.service';
import { InjectionFormService } from './service-detail/injection-form.service';
import { CoolsculptingFormService } from './service-detail/coolsculpting-form.service';
import {ClinicsService} from "@services/clinics.service";

export enum DataRefreshLimits {
  SilentRefresh = 7,
  VisibleRefresh = 14
}

@Injectable()
export class CurrentDataService {
  private currentDataUpdated = new Subject<any>();
  currentDataUpdated$ = this.currentDataUpdated.asObservable();
  private favouritesDataUpdated = new Subject<any>();
  favouritesDataUpdated$ = this.favouritesDataUpdated.asObservable();
  private copyDataUpdated = new Subject<any>();
  copyDataUpdated$ = this.copyDataUpdated.asObservable();
  private serviceDetailSectionHeightUpdate = new BehaviorSubject<number>(0);
  serviceDetailSectionHeightUpdate$ = this.serviceDetailSectionHeightUpdate.asObservable();
  private currentReplayDataUpdated = new ReplaySubject<any>(1);
  currentReplayDataUpdated$ = this.currentReplayDataUpdated.asObservable();
  public preventUserFromNavigationLastSetting: boolean = false;
  private preventUserFromNavigation = new Subject<boolean>();
  preventUserFromNavigation$ = this.preventUserFromNavigation.asObservable();

  treatmentToCopy: Service;
  treatmentIsDirty = false;

  currentDate: Date;
  dataStartDate: Date;
  dataCenterDate: Date;
  dataEndDate: Date;

  patientPanelActiveTab: string;

  dirty = true;

  constructor(private companyService: CompanyService,
    private userService: UsersService,
    private clinicService: ClinicsService,
    private injectionFormService: InjectionFormService,
    private coolsculptingFormService: CoolsculptingFormService) {

    this.currentDate = new Date();
    this.preventUserFromNavigationLastSetting = false;
    this.dataStartDate = new Date();
    this.dataStartDate.setDate(this.dataStartDate.getDate() - DataRefreshLimits.VisibleRefresh);
    this.dataEndDate = new Date();
    this.dataEndDate.setDate(this.dataEndDate.getDate() + DataRefreshLimits.VisibleRefresh);
    this.dirty = false;
  }

  currentDataHasBeenUpdated(value) {
    this.currentDataUpdated.next(value);
  }

  currentReplayDataHasBeenUpdated(value) {
    this.currentReplayDataUpdated.next(value);
  }

  setPreventUserFromNavigation(value) {
    this.preventUserFromNavigationLastSetting = value;
    this.preventUserFromNavigation.next(value);
  }

  resetReplayDataSubject(){
    this.currentReplayDataUpdated = new ReplaySubject<any>(1);
    this.currentReplayDataUpdated$ = this.currentReplayDataUpdated.asObservable();
  }

  favouritesDataHasBeenUpdated(value) {
    this.favouritesDataUpdated.next(value);
  }

  copyDataHasBeenUpdated(value) {
    this.copyDataUpdated.next(value);
  }

  serviceDetailSectionHeightUpdated(height: number){

    this.serviceDetailSectionHeightUpdate.next(height);
  }

  fetchCurrentData() {
      this.userService.loadUserClinicAndCompany().subscribe(details => {
        this.userService.setUserClinicCompanyDefaults(details);
        this.clinicService.setLoggedInUserClinic();
        this.currentDataHasBeenUpdated(null);
    });
  }
}
