import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/helpers';
import { environment } from '@environments/environment';
import { Form } from '@models/forms/form';
import { PatientForm } from '@models/forms/patient-form';
import { Patient } from '@models/patient';
import { Utils } from 'formiojs';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ClinicsService } from './clinics.service';
import { GeographyService } from './geography.service';
import { PatientService } from './patient.service';
import { UsersService } from './users.service';

interface Dict {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class PatientFormService {
  patientForms$ = new BehaviorSubject<PatientForm[]>([]);
  patientFormSelected$ = new BehaviorSubject<PatientForm>(null);
  formEntry$ = new BehaviorSubject<any>('');
  formDirty$ = new BehaviorSubject<boolean>(false);
  private patientFormSubmitted = new Subject<PatientForm>();
  patientFormSubmitted$ = this.patientFormSubmitted.asObservable();
  appointmentFormSubmitted$ = this.patientFormSubmitted.asObservable();

  private httpBackend: HttpClient;

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private patientService: PatientService,
    private geoService: GeographyService,
    private clinicService: ClinicsService,
    private usersService: UsersService
  ) {
    this.httpBackend = new HttpClient(this.handler);
  }

  getPatientForms(patientId: number) {
    return this.http
      .get<PatientForm[]>(environment.baseUrl + 'api/PatientForms/' + patientId)
      .pipe(tap((forms) => this.patientForms$.next(forms ?? [])));
  }

  getPatientFormById(id: number) {
    return this.http.get<PatientForm>(environment.baseUrl + 'api/PatientForms/PatientForm/' + id);
  }

  addPatientForm(patientForm: PatientForm) {
    // TODO: Look into moving rendered version assignment to back end. As it it won't be recorded for online booking
    // Task# 4514 
    const packageJson = require('./../../../package-lock.json');
    patientForm.rendererVersion = packageJson['packages']['node_modules/@formio/angular']['version'];
    patientForm.clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this.http.post<PatientForm>(environment.baseUrl + 'api/PatientForms', patientForm).pipe(
      tap((postedForm) => {
        this.patientForms$.next(this.patientForms$.getValue().concat(postedForm));
      })
    );
  }

  updatePatientForm(patientForm: PatientForm, sub?: any) {
    if (!patientForm.id) throw new Error('PatientForm invalid Id. Use addPatientForm() instead if creating a new patient form.');
    let submission = sub ? sub.data : this.formEntry$.getValue().data;
    if (submission.dateTime) {
      submission.dateTime = new Date().toISOString();
    }
    patientForm.formEntry = JSON.stringify({
      data: submission,
      metadata: { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    });
  
    return this.http.put<PatientForm>(environment.baseUrl + 'api/PatientForms', patientForm).pipe(
      tap((form) => {
        this.patientForms$.next(this.patientForms$.getValue().map((pf) => (pf.id == form.id ? form : pf)));
        if (this.patientFormSelected$.getValue()?.id == form.id) {
          this.patientFormSelected$.next(form);
        }
        if (form.isSigned) {
          this.patientFormSubmitted.next(form);
        }
      })
    );
  }

  saveSelectedPatientForm(submission?: any) {
    let patientForm = this.patientFormSelected$.getValue();
    return this.updatePatientForm(patientForm, submission);
  }

  submitSelectedPatientForm(submission?: any) {
    let patientForm = this.patientFormSelected$.getValue();
    patientForm.isSigned = true;
    patientForm.signedDate = new Date();
    return this.updatePatientForm(patientForm, submission);
  }

  deletePatientForm(patientFormId: number) {
    return this.http
      .delete(environment.baseUrl + 'api/PatientForms/' + patientFormId)
      .pipe(tap(() => this.patientForms$.next(this.patientForms$.getValue().filter((pf) => pf.id != patientFormId))));
  }

  createFileUrl(): Observable<string> {
    let form = this.patientFormSelected$.getValue();
    if (form.isSigned) {
      let headers = new HttpHeaders().set('Accept', 'application/pdf, application/octet-stream');
      return this.httpBackend.get(form.blobUrl, { headers: headers, responseType: 'blob' }).map((result) => {
        if (result.type !== 'application/pdf') {
          result = result.slice(0, result.size, 'application/pdf');
        }
        return URL.createObjectURL(result);
      });
    }
    return this.convertFormToPdf(form).map((file) => URL.createObjectURL(file));
  }

  private convertFormToPdf(patientForm: PatientForm): Observable<Blob> {
    return this.http
      .put(environment.baseUrl + 'api/PatientForms/ConvertFormToPdf', patientForm, { responseType: 'blob' })
      .pipe(map((data) => new Blob([data], { type: 'application/pdf' })));
  }
}
