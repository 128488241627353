<div class="edit-container position-absolute d-block z-index-3 white-background" *ngIf="addOrEdit">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <div class="row mx-0 teal-background">
      <div class="col px-0">
        <h6 class="white-font uppercase p-2 mb-0">{{ addOrEdit }} User</h6>
      </div>
    </div>
    <app-error *ngIf="serverValidationError" [message]="serverValidationError"></app-error>

    <div class="row mx-0 mt-2">
      <div class="col px-2">
        <mat-form-field>
          <input
            autocomplete="off"
            matInput
            placeholder="First Name"
            class="capitalized"
            aria-label="First Name"
            required
            [(ngModel)]="editedUser.firstName"
            (change)="formatterService.textCapitalize(firstName); updateSubmitButtonState()"
            [formControl]="firstName"
          />
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input
            autocomplete="off"
            matInput
            placeholder="Last Name"
            class="capitalized"
            aria-label="Last Name"
            required
            [(ngModel)]="editedUser.lastName"
            (change)="formatterService.textCapitalize(lastName); updateSubmitButtonState()"
            [formControl]="lastName"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input
            matInput
            autocomplete="off"
            aria-label="User Name"
            placeholder="User Name"
            [formControl]="userName"
            [(ngModel)]="editedUser.userName"
            required
            (change)="updateSubmitButtonState()"
          />
        </mat-form-field>
      </div>
      <div class="col px-2" *ngIf="!this.disableEmail">
        <mat-form-field>
          <form>
            <input
              autocomplete="off"
              matInput
              placeholder="Email"
              aria-label="Email"
              [(ngModel)]="editedUser.email"
              [formControl]="email"
              (change)="updateSubmitButtonState()"
            />
          </form>

          <mat-error *ngIf="email.hasError('email')">Not a Valid Email</mat-error>
          <mat-error *ngIf="email.hasError('required')">Cannot be empty with Patient Portal</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row mx-0">
      <div class="col px-2" *ngIf="addOrEdit === 'Add'">
        <mat-form-field style="width: 180px">
          <form>
            <input
              autocomplete="off"
              matInput
              required
              placeholder="Enter password"
              [type]="hidePassword ? 'password' : 'text'"
              aria-label="Password"
              [formControl]="password"
              (change)="confirmPasswordMatch(); updateSubmitButtonState()"
              (focusout)="confirmPasswordMatch(); updateSubmitButtonState()"
            />
          </form>
          <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
            <mat-icon class="larger-icon">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="password.hasError('passwordMatchError')">Passwords do not match</mat-error>
          <mat-error *ngIf="password.hasError('passwordToSmallError')">Passwords to short</mat-error>
          <mat-error *ngIf="password.hasError('passwordWrongFormatError')">Password incorrect format</mat-error>
        </mat-form-field>
      </div>
      <div class="col px-2" *ngIf="addOrEdit === 'Add'">
        <mat-form-field style="width: 180px">
          <form>
            <input
              autocomplete="off"
              matInput
              required
              placeholder="Confirm password"
              [type]="hideConfirmPassword ? 'password' : 'text'"
              aria-label="Password"
              [formControl]="passwordConfirm"
              (change)="confirmPasswordMatch(); updateSubmitButtonState()"
              (focusout)="confirmPasswordMatch(); updateSubmitButtonState()"
            />
          </form>
          <button mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
            <mat-icon class="larger-icon">{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="password.hasError('passwordMatchError')">Passwords do not match</mat-error>
          <mat-error *ngIf="password.hasError('passwordToSmallError')">Passwords to short</mat-error>
          <mat-error *ngIf="password.hasError('passwordWrongFormatError')">Password incorrect format</mat-error>
        </mat-form-field>
      </div>
      <div class="col px-2" *ngIf="addOrEdit === 'Edit'">
        <mat-form-field style="width: 180px">
          <form>
            <input
              autocomplete="off"
              matInput
              placeholder="Enter new password (optional)"
              [type]="hidePassword ? 'password' : 'text'"
              aria-label="Password"
              [formControl]="password"
              (change)="confirmPasswordMatch(); updateSubmitButtonState()"
              (focusout)="confirmPasswordMatch(); updateSubmitButtonState()"
            />
          </form>
          <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
            <mat-icon class="larger-icon">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="password.hasError('passwordMatchError')">Passwords do not match</mat-error>
          <mat-error *ngIf="password.hasError('passwordToSmallError')">Passwords to short</mat-error>
          <mat-error *ngIf="password.hasError('passwordWrongFormatError')">Password incorrect format</mat-error>
        </mat-form-field>
      </div>
      <div class="col px-2" *ngIf="addOrEdit === 'Edit'">
        <mat-form-field style="width: 180px">
          <form>
            <input
              autocomplete="off"
              matInput
              placeholder="Confirm password"
              [type]="hideConfirmPassword ? 'password' : 'text'"
              aria-label="Password"
              [formControl]="passwordConfirm"
              (change)="confirmPasswordMatch(); updateSubmitButtonState()"
              (focusout)="confirmPasswordMatch(); updateSubmitButtonState()"
            />
          </form>
          <button mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
            <mat-icon class="larger-icon">{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="passwordConfirm.hasError('passwordMatchError')">Passwords do not match</mat-error>
          <mat-error *ngIf="passwordConfirm.hasError('passwordToSmallError')">Passwords to short</mat-error>
          <mat-error *ngIf="passwordConfirm.hasError('passwordWrongFormatError')">Password incorrect format</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 px-2" style="font-size: small; text-align: center">
        Passwords must be at least 8 characters with one alphanumeric character, one digit and one uppercase character.
      </div>
    </div>

    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input
            autocomplete="off"
            matInput
            placeholder="Address 1"
            class="capitalized"
            aria-label="Address1"
            [(ngModel)]="editedUser.address.address1"
            [formControl]="addressAddress1"
            (change)="formatterService.textCapitalize(addressAddress1); updateSubmitButtonState()"
            (focusout)="formatterService.textCapitalize(addressAddress1)"
          />
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input
            autocomplete="off"
            matInput
            placeholder="Address 2"
            class="capitalized"
            aria-label="Address2"
            [(ngModel)]="editedUser.address.address2"
            [formControl]="addressAddress2"
            (change)="formatterService.textCapitalize(addressAddress2); updateSubmitButtonState()"
            (focusout)="formatterService.textCapitalize(addressAddress2)"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input
            autocomplete="off"
            matInput
            placeholder="City"
            class="capitalized"
            aria-label="City"
            [(ngModel)]="editedUser.address.city"
            [formControl]="addressCity"
            (change)="formatterService.textCapitalize(addressCity); updateSubmitButtonState()"
            (focusout)="formatterService.textCapitalize(addressCity)"
          />
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <mat-select
            [(ngModel)]="editedUser.address.province"
            name="usStates"
            (selectionChange)="updateSubmitButtonState()"
          >
            <mat-option *ngFor="let provinceState of provincesAndStates" [value]="provinceState">
              {{ provinceState }}
            </mat-option>
          </mat-select>
          <mat-placeholder class="placeholder"
            >{{ editedUser.address.country == 'United States' ? 'State' : 'Province' }}
          </mat-placeholder>
        </mat-form-field>
      </div>
    </div>

    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <mat-select
            placeholder="Country"
            [(ngModel)]="editedUser.address.country"
            name="usStates"
            (change)="onChangeCountry(); updateSubmitButtonState()"
          >
            <mat-option *ngFor="let country of countriesOfTheWorld" [value]="country">
              {{ country }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input
            autocomplete="off"
            matInput
            aria-label="Postal Code"
            class="uppercased"
            [(ngModel)]="editedUser.address.postalCode"
            [formControl]="addressPostalCode"
            (change)="formatterService.textUppercase(addressPostalCode); updateSubmitButtonState()"
            (focusout)="formatterService.textUppercase(addressPostalCode); updateSubmitButtonState()"
          />
          <mat-placeholder class="placeholder">
            {{ editedUser.address.country == 'United States' ? 'Zip Code' : 'Postal Code' }}</mat-placeholder
          >
          <mat-error *ngIf="addressPostalCode.invalid">
            {{ editedUser.address.country == 'United States' ? 'Not a Valid Zip Code' : 'Not a Valid Postal Code' }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input
            autocomplete="off"
            matInput
            placeholder="Phone Number"
            aria-label="Phone Number"
            [(ngModel)]="editedUser.phoneNumber"
            (change)="formatterService.formatPhoneNumber(phoneNumber); updateSubmitButtonState()"
            [formControl]="phoneNumber"
          />
          <mat-error *ngIf="phoneNumber.invalid">Not a Valid Phone #</mat-error>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <mat-select
            placeholder="User Category"
            [(ngModel)]="editedUser.userUserCategories"
            [compareWith]="compareCategories"
            name="usercategory"
            multiple
            required
            (selectionChange)="updateSubmitButtonState()"
          >
            <mat-option *ngFor="let userCat of userCategories" [value]="userCat">
              {{ userCat.categoryName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field id="userServiceProviderFormField">
          <mat-select
            placeholder="Service Provider"
            [(ngModel)]="editedUser.serviceProvider"
            name="serviceProvider"
            (selectionChange)="updateSubmitButtonState()"
          >
            <mat-option *ngFor="let boolChoice of boolChoices" [value]="boolChoice == 'Yes' ? true : false">
              {{ boolChoice }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col px-2"></div>
    </div>

    <div class="row mx-0 teal-background">
      <div class="col px-0">
        <h6 class="white-font uppercase p-2 mb-0">Prescription Info</h6>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <mat-select
            placeholder="Medical College"
            [(ngModel)]="editedUser.medicalCollegeId"
            name="medicalCollegeId"
            (selectionChange)="onMedicalCollegeSwitched(editedUser.medicalCollegeId); updateSubmitButtonState()"
          >
            <ng-container *ngIf="editedUser.address.country == 'Canada'">
              <mat-option *ngFor="let college of medicalColleges" [value]="college.id">
                {{ college.collegeInitials }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input
            matInput
            [placeholder]="'Medical License #'"
            aria-label="Medical License #"
            [(ngModel)]="editedUser.medicalLicenseNumber"
            (change)="updateSubmitButtonState()"
            [formControl]="medicalLicenseNumber"
          />
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="editedUser.address.country == 'Canada'">
      <div class="row mx-0 teal-background">
        <div class="col px-0">
          <h6 class="white-font uppercase p-2 mb-0">Billing Info</h6>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col px-2">
          <mat-form-field>
            <input
              matInput
              [placeholder]="'Provincial Billing #'"
              aria-label="Provider Number"
              [(ngModel)]="editedUser.provincialBillingNumber"
              (change)="updateSubmitButtonState()"
              [formControl]="provincialBillingNumber"
            />
            <mat-error *ngIf="provincialBillingNumber.invalid">Provincial Billing Number must be numeric</mat-error>
          </mat-form-field>
        </div>
        <div class="col px-2">
          <mat-form-field>
            <input
              matInput
              [placeholder]="'ClinicAid Payee #'"
              aria-label="ClinicAid Payee Number"
              [(ngModel)]="editedUser.payeeNumber"
              (change)="updateSubmitButtonState()"
              [formControl]="payeeNumber"
            />
            <mat-error *ngIf="provincialBillingNumber.invalid">Payee # Number must be numeric</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row mx-0 teal-background">
      <div class="col px-0">
        <h6 class="white-font uppercase p-2 mb-0">Save User</h6>
      </div>
    </div>
    <div class="row mx-0 my-3">
      <div class="col px-2 text-center">
        <button class="btn em-btn em-btn-green" (click)="updateUser()" [disabled]="submitButtonDisabledState">
          {{ addOrEdit == 'Add' ? 'Add' : 'Update' }}
        </button>
      </div>
      <div class="col px-2 text-center">
        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
    <div class="row mx-0 teal-background">
      <div class="col px-0">
        <h6 class="white-font uppercase p-2 mb-0">Edit Commission</h6>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <app-edit-user-commissions [userId]="userIdParam"></app-edit-user-commissions>
      </div>
    </div>
  </perfect-scrollbar>
</div>
