import { Appointment } from '@models/scheduler/appointment';
import { eTreatmentForm } from './etreatment-form';
import { ServiceeTreatmentForm } from './service-etreatment-form';

export class AppointmenteTreatmentForm {
  public id: number;
  public eTreatmentFormId: number;
  public eTreatmentForm: eTreatmentForm;
  public appointmentId: number;
  public appointment: Appointment;
  public formDefinition: string;
  public formEntry: string;
  public rendererVersion: string;
  public createdDate: Date;
  public modifiedDate: Date;

  public static fromForm(serviceeTreatmentForm: ServiceeTreatmentForm) {
    let appointmenteTreatmentForm = new AppointmenteTreatmentForm();
    appointmenteTreatmentForm.eTreatmentFormId = serviceeTreatmentForm.eTreatmentFormId;
    appointmenteTreatmentForm.eTreatmentForm = serviceeTreatmentForm.eTreatmentForm;
    appointmenteTreatmentForm.formDefinition = serviceeTreatmentForm.eTreatmentForm.formDefinition;

    return appointmenteTreatmentForm;
  }

  public constructor(init?: Partial<AppointmenteTreatmentForm>) {
    Object.assign(this, init);
  }
}
