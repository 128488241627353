import { IChargeableAsset } from '@interfaces/IChargeableAsset';
import { ITransactionTargetItem } from '@interfaces/ITransactionTargetItem';
import { AppointmenteTreatmentForm } from '@models/etreatment-forms/appointment-etreatment-form';
import { PatientForm } from '@models/forms/patient-form';
import * as moment from 'moment';
import { ChargeableItem } from '../billing/chargeable-item';
import { ColourVariables } from '../constants/colour-variables';
import { Patient } from '../patient';
import { Service } from '../service/service';
import { User } from '../user';
import { PaymentStatus } from './payment-status';

export class Appointment implements ITransactionTargetItem, IChargeableAsset {
  appointmentId: number;
  appointmentType: number;
  title: string;
  createdById: string;
  createdBy: User;
  createdDate: Date;
  allDay: boolean;
  start: Date;
  end: Date;
  date: Date;
  dateTime: Date;
  startTime: moment.Duration;
  endTime: moment.Duration;
  endDate: Date;
  url: string;
  className: string;
  editable: boolean;
  startEditable: boolean;
  durationEditable: boolean;
  resourceEditable: boolean;
  rendering: string; // background or inverse-background or empty
  overlap: boolean;
  constraint: any;
  source: any; // automatically populated
  serviceId: number;
  service: Service;
  backgroundColor: string;
  borderColor: string;
  textColor: string;
  resourceId: string;
  visitIdString: string;
  visitId: number;
  cancellationReason: string;
  cancellationMessage: string;
  isCancellationAlert: boolean;
  cancellationDate: Date;
  cancelled: boolean;
  reasonCancellationNotCharged: string;
  cancelledByUserId: string;
  cancelledByUser: User;
  editing: boolean;
  color: string;
  staffId: string;
  staff: User;
  recurrence: scheduleRecurrence;
  notes: string;
  patientId: number;
  patient: Patient;
  parentId: number;
  colourVariables = new ColourVariables();
  paymentStatus: PaymentStatus;
  isMinistry: boolean;
  plannedTreatmentId?: number;
  roomName: string;
  isPlaceholder: boolean;
  patientTokenId: number;
  appointmentForms: PatientForm[];
  appointmenteTreatmentForms: AppointmenteTreatmentForm[];
  squareCardId: string;
  requestCreditCard: boolean;
  convergePaymentId: number;
  squarePaymentId: number;
  onlineBooked: boolean;

  constructor(init?: Partial<Appointment>) {
    this.initializeDefaultValues();
    Object.assign(this, init);
  }

  initializeDefaultValues() {
    this.appointmentId = 0;
    this.appointmentType = 0;
    this.title = '';
    this.createdById = '';
    this.createdBy = null;
    this.createdDate = new Date();
    this.allDay = false;
    this.start = null;
    this.end = null;
    this.date = null;
    this.dateTime = null;
    this.startTime = null;
    this.endTime = null;
    this.endDate = null;
    this.url = '';
    this.className = '';
    this.editable = null;
    this.startEditable = null;
    this.durationEditable = null;
    this.resourceEditable = null;
    this.rendering = ''; // background or inverse-background or empty
    this.overlap = null;
    this.constraint = null;
    this.source = null; // automatically populated
    this.serviceId = null;
    this.service = null;
    this.backgroundColor = '';
    this.borderColor = '';
    this.textColor = '';
    this.resourceId = '';
    this.visitIdString = '';
    this.visitId = null;
    this.cancellationReason = '';
    this.cancellationMessage = '';
    this.isCancellationAlert = null;
    this.cancellationDate = null;
    this.cancelled = false;
    this.cancelledByUserId = null;
    this.cancelledByUser = null;
    this.editing = null;
    this.color = '';
    this.staffId = '';
    this.staff = null;
    this.recurrence = 0;
    this.notes = '';
    this.patientId = null;
    this.patient = null;
    this.parentId = 0;
    this.paymentStatus = PaymentStatus.Unpaid;
    this.isMinistry = false;
    this.roomName = '';
    this.appointmentForms = [];
    this.reasonCancellationNotCharged = '';
  }

  getColor(): string {
    return this.colourVariables.grey_three;
  }

  isRoutable(): boolean {
    return true;
  }

  public getBaseAndChildrenChargeableItems(): ChargeableItem[] {
    const chargeableItems: ChargeableItem[] = [];

    // appointments do not have a base charge
    if (this.service != null) {
      const service = new Service(this.service);
      chargeableItems.push(...service.getChargeableItems());
    }

    return chargeableItems;
  }

  public getChargeableItems(): ChargeableItem[] {
    const chargeableItems: ChargeableItem[] = [];

    if (this.service != null) {
      const service = new Service(this.service);
      chargeableItems.push(...service.getChargeableItems());
    }

    return chargeableItems;
  }
}

export enum scheduleRecurrence {
  NoRepeat = 0,
  Weekly = 1,
  Every2Weeks,
  Every3Weeks,
  Every4Weeks,
}

export enum AppointmentType {
  Regular,
  Staff,
  Blocked,
}

export enum UpdateMethod {
  Single,
  Forward,
  All,
}

// export interface PEvent {
//   appointmentId?: string;
//   title?: string;
//   allDay?: boolean;
//   start: string;
//   end?: string;
//   url?: string;
//   className?: string[];
//   editable?: boolean;
//   startEditable?: boolean;
//   durationEditable?: boolean;
//   resourceEditable?: boolean;
//   // background or inverse-background or empty
//   rendering?: string;
//   overlap?: boolean;
//   constraint?: any;
//   // automatically populated
//   source?: any;
//   color?: string;
//   startDate?: string;
//   endDate?: string;
//   resourceId?: string;
//   icon?: string;
//   repeat?: boolean;
//   internalTitle?: string;
//   prefAppoint?: string;
//   planningId: string;
//   visitIdString?: string;
//   backgroundColor?: string;
//   borderColor?: string;
//   textColor?: string;
//   patientId?: number;
//   dow?: any;
//   eventConstraint?: any;
// }

// export interface BEvent {
//   id?: string;
//   title?: string;
//   allDay?: boolean;
//   start: string;
//   end?: string;
//   url?: string;
//   className?: string[];
//   editable?: boolean;
//   startEditable?: boolean;
//   durationEditable?: boolean;
//   resourceEditable?: boolean;
//   // background or inverse-background or empty
//   dow?: any;
//   rendering?: string;
//   overlap?: boolean;
//   constraint?: any;
//   // automatically populated
//   source?: any;
//   color?: string;
//   startDate?: string;
//   endDate?: string;
//   resourceId?: string;
//   icon?: string;
//   repeat?: boolean;
//   internalTitle?: string;
//   prefAppoint?: string;
//   planningId?: string;
//   visitIdString?: string;
//   backgroundColor?: string;
//   borderColor?: string;
//   textColor?: string;
//   patientId?: number;
//   businessId: string;
//   eventConstraint?: any;
//   selectOverlap: boolean;
// }
// export interface IEvent {
//   appointmentId?: string;
//   title?: string;
//   allDay?: boolean;
//   start: string;
//   end?: string;
//   url?: string;
//   className?: string[];
//   editable?: boolean;
//   startEditable?: boolean;
//   durationEditable?: boolean;
//   resourceEditable?: boolean;
//   // background or inverse-background or empty
//   rendering?: string;
//   overlap?: boolean;
//   constraint?: any;
//   // automatically populated
//   source?: any;
//   color?: string;
//   startDate?: string;
//   endDate?: string;
//   resourceId?: string;
//   icon?: string;
//   repeat?: boolean;
//   internalTitle?: string;
//   prefAppoint?: string;
//   planningId: string;
//   visitIdString?: string;
//   backgroundColor?: string;
//   borderColor?: string;
//   textColor?: string;
//   patientId?: number;
//   dow?: any;
// }
