import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { AppointmentViewModel } from '@models/appintment-viewmodel';
import { Clinic } from '@models/clinic';
import { Appointment, AppointmentType, UpdateMethod, scheduleRecurrence } from '@models/scheduler/appointment';
import { ServiceProvider } from '@models/service-provider';
import { WeekDay } from '@models/week-day';
import { AppointmentService } from '@services/appointments.service';
import { ClinicsService } from '@services/clinics.service';
import { CurrentDataService } from '@services/currentData.service';
import { EventsService } from '@services/events.service';
import { PatientService } from '@services/patient.service';
import { ServiceProviderService } from '@services/service-provider.service';
import { ServicesService } from '@services/services.service';
import { VisitService } from '@services/visit.service';
import * as moment from 'moment';
import { Subject, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { ConfirmUpdateMethodDialogComponent } from '../../../../management/dialogs/confirm-update-method/confirm-update-method.component';

@Component({
  selector: 'app-create-shift',
  templateUrl: './create-shift.component.html',
  styleUrls: ['./create-shift.component.less'],
})
export class CreateShiftComponent implements OnInit, OnDestroy {
  schedStaffSelectedStatus: string[] = [];
  staffSchedService;
  blockedSchedService;
  staffSchedVisit;
  blockedSchedVisit;
  staffSchedPatient;
  blockedSchedPatient;
  actionPanelOpened = true;
  selectedStaff: ServiceProvider;
  selectedStaffSchedule: any;
  weekdays = Object.keys(WeekDay);
  recurrenceDays = [];
  startTime = moment().toDate();
  endTime = moment().toDate();
  repeatUntil = moment().toDate();
  clickEvent: AppointmentViewModel;
  minTime = moment().toDate();
  maxTime = moment().toDate();
  minDate = new Date();
  minEndTime = moment().toDate();
  calculatedDuration = '00:10:00';
  durationInMinutes = '';
  minimumDuration: number;
  unsub = new Subject<void>();
  date = moment().startOf('day').toDate();
  todayDayString = null;
  isNew: boolean;
  addOrEdit = 'Add';
  thisClinic: Clinic;
  serviceProviders: ServiceProvider[];
  isRepeatDisabled = false;
  scheduleTypes = ['Staff Schedule', 'Blocked Schedule'];
  scheduleType = 'Staff Schedule';
  updateMethod = UpdateMethod.Single;
  loading = false;
  isCurrentDateSet = false;
  developerPolicy = Policies.developer;

  constructor(
    private router: Router,
    public currentDataService: CurrentDataService,
    private eventsService: EventsService,
    private visitService: VisitService,
    private appointmentService: AppointmentService,
    private patientService: PatientService,
    private servicesService: ServicesService,
    private confirmDialog: MatDialog,
    private route: ActivatedRoute,
    private providerService: ServiceProviderService,
    private clinicsService: ClinicsService
  ) {}

  async ngOnInit() {
    this.isNew = true;
    this.addOrEdit = 'Add';
    this.durationInMinutes = '';

    this.eventsService.currentDate.pipe(takeUntil(this.unsub)).subscribe((value) => {
      if (this.isCurrentDateSet) {
        this.isCurrentDateSet = false;
        this.closePanel();
      }
      this.isCurrentDateSet = true;
      this.date = moment(value).startOf('day').toDate();
      this.todayDayString = moment(this.date).format('dddd');
      this.minDate = this.date;
    });

    this.appointmentService.allApptsUpdated$.pipe(takeUntil(this.unsub)).subscribe((aa) => {
      this.refreshScheduleInPanel();
    });

    if (this.clinicsService.clinic) {
      this.thisClinic = this.clinicsService.clinic;
      this.minimumDuration = this.thisClinic.minimumDuration;
    }

    if (this.durationInMinutes === '') {
      this.durationInMinutes = this.minimumDuration.toString();
    }

    this.providerService.getServiceProviders().subscribe((serviceProviders: ServiceProvider[]) => {
      this.serviceProviders = serviceProviders;
    });

    this.clickEvent = this.eventsService.getTempEvent();
    this.setTimeAndDurationFromEvent(this.clickEvent);
    this.eventsService.updateCreateVisitTimeProvider$
      .pipe(takeUntil(this.unsub))
      .subscribe((event) => this.setTimeAndDurationFromEvent(event));

    this.selectedStaffSchedule = {
      appointmentId: null,
      staffId: '',
      parentId: 0,
      title: '',
      recurrence: scheduleRecurrence.NoRepeat,
      notes: '',
      start: '',
      end: '',
      endDate: '',
    };

    this.visitService.getBlockedScheduleVisit().subscribe((v) => {
      this.blockedSchedVisit = v;
    });
    this.visitService.getStaffScheduleVisit().subscribe((v) => {
      this.staffSchedVisit = v;
    });
    this.patientService.getBlockedSchedulePatient().subscribe((p) => {
      this.blockedSchedPatient = p;
    });
    this.patientService.getStaffSchedulePatient().subscribe((p) => {
      this.staffSchedPatient = p;
    });
    this.servicesService.getBlockedScheduleService().subscribe((s) => {
      this.blockedSchedService = s;
    });
    this.servicesService.getStaffScheduleService().subscribe((s) => {
      this.staffSchedService = s;
    });

    this.route.params.pipe(takeUntil(this.unsub)).subscribe(async (params) => {
      const paramId = params['id'] as string;

      if (this.eventsService.blockedScheduleMode) {
        this.scheduleTypes = ['Blocked Schedule'];
      } else {
        this.scheduleTypes = ['Staff Schedule', 'Blocked Schedule'];
      }
      this.scheduleType = this.scheduleTypes[0];

      if (paramId === '_') {
        // this is a new Staff Schedule, so the resourceId will be the StaffId
        this.schedStaffSelectedStatus = [];
        this.selectedStaff = this.clickEvent.resource;
        this.schedStaffSelectedStatus.push(this.selectedStaff.id);
        const staffSchdule = new Appointment();

        staffSchdule.appointmentType = AppointmentType.Staff;
        staffSchdule.title = this.selectedStaff.title;
        staffSchdule.date = this.appointmentService.stripDateOnlyAsUtcDate(this.date);
        staffSchdule.dateTime = this.date;
        staffSchdule.startTime = moment.duration(moment(this.startTime).format('HH:mm'));
        staffSchdule.endTime = moment.duration(moment(this.endTime).format('HH:mm'));
        staffSchdule.endDate = this.repeatUntil;
        staffSchdule.recurrence = scheduleRecurrence.NoRepeat;
        staffSchdule.staffId = this.selectedStaff.id;

        if (this.eventsService.blockedScheduleMode) {
          staffSchdule.appointmentType = AppointmentType.Blocked;
        }

        this.selectedStaffSchedule = staffSchdule;
      } else {
        this.isNew = false;
        this.addOrEdit = 'Update';
        // this.staffMembersSelect.options.forEach((item: MatOption) => item.deselect());
        this.schedStaffSelectedStatus = [];
        // this is an existing Staff Schedule, so the resourceId will be the StaffScheduleId
        this.selectedStaff = this.clickEvent.resource;

        if (paramId.toLowerCase().includes('_s')) {
          this.scheduleType = this.scheduleTypes[0];
          const selectedStaffScheduleId = Number(paramId.toLowerCase().replace('_s', ''));
          this.appointmentService.getAppointmentById(selectedStaffScheduleId).subscribe(async (ss) => {
            this.selectedStaffSchedule = ss;
            if (this.selectedStaffSchedule.recurrence) {
              await this.detectRecurranceWeekdays();
            }
            this.startTime = moment(this.selectedStaffSchedule.start).toDate();
            this.endTime = moment(this.selectedStaffSchedule.end).toDate();
            this.repeatUntil = moment(this.selectedStaffSchedule.endDate).toDate();
            this.isRepeatDisabled = this.selectedStaffSchedule.recurrence !== 0;
          });
        } else {
          const index = this.eventsService.blockedScheduleMode ? 0 : 1;
          this.scheduleType = this.scheduleTypes[index];
          const selectedBlockedScheduleId = Number(params['id'].toLowerCase().replace('_b', ''));
          this.schedStaffSelectedStatus.push(this.selectedStaff.id);
          this.appointmentService.getAppointmentById(selectedBlockedScheduleId).subscribe(async (bs) => {
            this.selectedStaffSchedule = bs;
            if (this.selectedStaffSchedule.recurrence) {
              await this.detectRecurranceWeekdays();
            }
            this.date = this.selectedStaffSchedule.date;
            this.todayDayString = moment(this.date).format('dddd');
            this.startTime = moment(this.selectedStaffSchedule.start).toDate();
            this.endTime = moment(this.selectedStaffSchedule.end).toDate();
            this.repeatUntil = moment(this.selectedStaffSchedule.endDate).toDate();
            this.isRepeatDisabled = this.selectedStaffSchedule.recurrence !== 0;
          });
        }
      }
    });

    this.eventsService.updateCreateVisitTimeProvider$.pipe(takeUntil(this.unsub)).subscribe(async (update) => {
      this.clickEvent = this.eventsService.getTempEvent();
      this.selectedStaff = update.provider;
      this.schedStaffSelectedStatus = [];
      this.schedStaffSelectedStatus.push(this.selectedStaff.id);
    });
  }

  async detectRecurranceWeekdays() {
    let selectedStaffScheduleRelatedAppointments = await this.appointmentService
      .getAppointmentsByParentId(
        this.selectedStaffSchedule.parentId == 0
          ? this.selectedStaffSchedule.appointmentId
          : this.selectedStaffSchedule.parentId
      )
      .toPromise();
    this.recurrenceDays = [];
    var weekdays = [...new Set(selectedStaffScheduleRelatedAppointments.map((a) => moment(a.date).format('dddd')))];
    weekdays.forEach((weekday) => (this.recurrenceDays[weekday] = true));
  }

  closePanel() {
    this.loading = false;
    this.router.navigate(['/schedule', { outlets: { 'action-panel': null } }]);
    this.eventsService.closePanel();
  }

  compareStaff(provider1: ServiceProvider, provider2: ServiceProvider): boolean {
    return provider1.id === provider2.id;
  }

  refreshScheduleInPanel() {
    const selectedStaffSchedule = this.appointmentService.activeAppointments.find(
      (a) => a.appointmentId === this.selectedStaffSchedule.appointmentId
    );
    if (selectedStaffSchedule) {
      this.selectedStaff.id = selectedStaffSchedule.staffId;
      this.startTime = selectedStaffSchedule.start;
      this.endTime = selectedStaffSchedule.end;
      this.schedStaffSelectedStatus = [];
      this.schedStaffSelectedStatus.push(this.selectedStaff.id);
    }
  }

  recurrenceChanged($event) {
    if (!$event) {
      this.recurrenceDays = [];
    } else {
      this.recurrenceDays[moment(this.date).format('dddd')] = true;
    }
  }

  dayChecked($event, day) {
    if ($event.checked) {
      this.recurrenceDays[day] = true;
    } else {
      delete this.recurrenceDays[day];
    }
  }

  public addUpdateAndCloseSchedule() {
    this.confirmAddUpdateSchedule();
    this.closePanel();
  }

  public confirmAddUpdateSchedule() {
    if (this.addOrEdit === 'Add') {
      this.addOrUpdateSchedule();
      return;
    }

    if (this.selectedStaffSchedule.recurrence === 0) {
      this.addOrUpdateSchedule();
      return;
    }

    const dialogRef = this.confirmDialog.open(ConfirmUpdateMethodDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        switch (result) {
          case 'single':
            this.updateMethod = UpdateMethod.Single;
            this.addOrUpdateSchedule();
            break;
          case 'forward':
            this.updateMethod = UpdateMethod.Forward;
            this.addOrUpdateSchedule();
            break;
          case 'all':
            this.updateMethod = UpdateMethod.All;
            this.addOrUpdateSchedule();
            break;
        }
      });
  }

  public deleteSchedule() {
    if (this.selectedStaffSchedule.recurrence === 0) {
      this.appointmentService
        .removeAppointment(this.selectedStaffSchedule.appointmentId)
        .subscribe(() => this.closePanel());
      return;
    }

    const dialogRef = this.confirmDialog.open(ConfirmUpdateMethodDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        switch (result) {
          case 'single':
            this.appointmentService
              .removeAppointment(this.selectedStaffSchedule.appointmentId)
              .subscribe(() => this.closePanel());
            break;
          case 'forward':
            this.appointmentService
              .removeAppointment(this.selectedStaffSchedule.appointmentId, UpdateMethod.Forward)
              .subscribe(() => this.closePanel());
            break;
        }
      });
  }

  private async addOrUpdateSchedule() {
    const staffId = this.selectedStaff.id;
    let scheduleType: AppointmentType = AppointmentType.Blocked;
    if (this.scheduleType === 'Staff Schedule') {
      scheduleType = AppointmentType.Staff;
    }
    this.loading = true;
    let newSchduleTitle = '';
    let newSchedule = new Appointment();

    newSchedule.createdDate = new Date();
    newSchedule.appointmentId = this.selectedStaffSchedule.appointmentId;
    newSchedule.appointmentType = scheduleType;
    newSchedule.title = this.selectedStaff.title;
    newSchedule.date = this.appointmentService.stripDateOnlyAsUtcDate(this.date);
    newSchedule.dateTime = this.date;
    newSchedule.startTime = moment.duration(moment(this.startTime).format('HH:mm'));
    newSchedule.endTime = moment.duration(moment(this.endTime).format('HH:mm'));
    newSchedule.endDate = this.repeatUntil;
    newSchedule.service = this.staffSchedService;
    newSchedule.resourceId = staffId;
    newSchedule.cancellationDate = this.startTime;
    (newSchedule.staffId = staffId), (newSchedule.recurrence = this.selectedStaffSchedule.recurrence);
    newSchedule.notes = this.selectedStaffSchedule.notes;
    newSchedule.patient = this.staffSchedPatient;
    newSchedule.parentId = this.selectedStaffSchedule.parentId;
    switch (scheduleType) {
      case AppointmentType.Staff:
        newSchedule.backgroundColor = AppointmentService.staffUnavailabilityBackgroundColor;
        newSchedule.color = AppointmentService.staffUnavailabilityColor;
        newSchduleTitle = this.selectedStaff.title;
        newSchedule.visitId = this.staffSchedVisit.visitId;
        newSchedule.serviceId = this.staffSchedService.serviceId;
        newSchedule.patientId = this.staffSchedPatient.patientId;
        newSchedule.rendering = 'inverse-background';
        break;
      case AppointmentType.Blocked:
        newSchedule.backgroundColor = AppointmentService.blockedUnavailabilityBackgroundColor;
        newSchedule.color = AppointmentService.blockedUnavailabilityColor;
        newSchduleTitle = 'Blocked Time';
        newSchedule.visitId = this.blockedSchedVisit.visitId;
        newSchedule.serviceId = this.blockedSchedService.serviceId;
        newSchedule.patientId = this.blockedSchedPatient.patientId;
        newSchedule.rendering = 'background';
        break;
    }
    newSchedule.title = newSchduleTitle;
    newSchedule.borderColor = null;
    newSchedule.textColor = null;

    if (newSchedule.appointmentId !== 0) {
      // this is an update, not adding a new one
      this.appointmentService.updateAppointment(newSchedule, this.updateMethod).subscribe((ss) => {
        this.loading = false;
        //this.closePanel(); // we won't close the panel automatically. We've added a separate Update & Close button for that
      });
      return;
    }

    // this is a brand new, so add the baseSchedule first to get back it's id for use as the parentId of the rest
    if (scheduleType !== AppointmentType.Blocked) {
      this.schedStaffSelectedStatus = [];
      this.schedStaffSelectedStatus.push(staffId);
    }
    const origStaffSched = Object.assign({}, newSchedule);
    for (let i = 0, len = this.schedStaffSelectedStatus.length; i < len; i++) {
      newSchedule = Object.assign({}, origStaffSched);
      newSchedule.title = newSchduleTitle;
      newSchedule.staffId = this.schedStaffSelectedStatus[i];
      newSchedule.resourceId = this.schedStaffSelectedStatus[i].toString();

      //if its a recurrence multi-day then use the original date plus moment math to start the others as chained appointments
      if (this.selectedStaffSchedule.recurrence && Object.keys(this.recurrenceDays).length > 1) {
        const originalDate = newSchedule.dateTime;
        let originalAppointment: Appointment = await this.addAppointment(newSchedule, scheduleType).toPromise();
        delete this.recurrenceDays[moment(newSchedule.dateTime).format('dddd')]; //remove the first appointment d.o.w. from list and loop thru the rest
        newSchedule.parentId = originalAppointment.appointmentId;
        Object.keys(this.recurrenceDays).forEach((day) => {
          var weekdayDateVal =
            moment(originalDate).weekday() > moment(originalDate).day(day).day()
              ? moment(originalDate).day(day).day() + 7
              : moment(originalDate).day(day).day();
          newSchedule.dateTime = moment(originalDate).day(weekdayDateVal).toDate();
          newSchedule.date = this.appointmentService.stripDateOnlyAsUtcDate(newSchedule.dateTime);

          this.addAppointment(newSchedule, scheduleType).subscribe((apptNew) => {
            // console.log(apptNew);
          });
        });
      } else {
        await this.addAppointment(newSchedule, scheduleType).toPromise();
      }
    }
  }

  addAppointment(newSchdule: Appointment, scheduleType: AppointmentType) {
    return this.appointmentService.addAppointment(newSchdule).pipe(
      catchError((err) => {
        this.loading = false;
        return of(null);
      }),
      map((ss) => {
        if (ss) {
          ss.appointmentType = scheduleType;

          switch (scheduleType) {
            case AppointmentType.Staff:
              ss.backgroundColor = AppointmentService.staffUnavailabilityBackgroundColor;
              ss.color = AppointmentService.staffUnavailabilityColor;
              ss.service = this.staffSchedService;
              ss.rendering = 'inverse-background';
              break;
            case AppointmentType.Blocked:
              ss.backgroundColor = AppointmentService.blockedUnavailabilityBackgroundColor;
              ss.color = AppointmentService.blockedUnavailabilityColor;
              ss.service = this.blockedSchedService;
              ss.rendering = 'background';
              break;
          }
        }
        this.loading = false;
        return ss;
      })
    );
  }

  updateSelectedStaffMembers(event) {
    this.schedStaffSelectedStatus = event.value as Array<string>;
  }

  private setTimeAndDurationFromEvent(event) {
    this.date = moment(moment(event.start).format()).startOf('day').toDate();
    this.todayDayString = moment(this.date).format('dddd');
    const start = moment.duration(moment(event.start).format('HH:mm'));
    const end = moment.duration(moment(event.end).format('HH:mm'));
    this.startTime = moment(this.date).add(start).toDate();
    this.endTime = moment(this.date).add(end).toDate();
    this.repeatUntil = moment(this.date).add(4, 'weeks').add(1, 'day').toDate();

    if (event.start !== event.end) {
      const durationTime = end.subtract(start);
      this.calculatedDuration = durationTime.format('HH:mm:ss');
      this.durationInMinutes = durationTime.asMinutes.toString();
    }
    this.setMinMaxTimeFromHoursOfOperation();
  }

  onStartTimeChange(date: Date) {
    this.minEndTime = moment(date).add(this.minimumDuration.toString(), 'minutes').toDate();
    if (this.endTime.getTime() < date.getTime()) {
      this.endTime = moment(date).add(this.minimumDuration.toString(), 'minutes').toDate();
    }
  }

  setMinMaxTimeFromHoursOfOperation() {
    const dayOfWeek = moment(this.startTime).day();
    const openTime = this.thisClinic.hoursOfOperation.hoursOfOperationDays[dayOfWeek].openTime;
    const closeTime = this.thisClinic.hoursOfOperation.hoursOfOperationDays[dayOfWeek].closeTime;

    if (closeTime.asMinutes() - openTime.asMinutes() > 0) {
      // any open hours this day?
      this.minTime = moment().startOf('day').add(openTime).toDate();
      this.minEndTime = moment(this.startTime).add(this.minimumDuration, 'minutes').toDate();
      this.maxTime = moment().startOf('day').add(closeTime).toDate();
    } else {
      this.minTime = moment().startOf('day').toDate();
      this.minEndTime = moment().startOf('day').toDate();
      this.maxTime = moment().endOf('days').toDate();
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
