import { NgModule } from '@angular/core';
import { ExtraOptions, Route, RouterModule, Routes } from '@angular/router';
import { EditEmailTemplatesComponent } from './management/email-templates/actionpanel/edit-email-templates/edit-email-templates.component';
import { FormLibraryOverviewComponent } from './management/forms/form-library-overview/form-library-overview.component';
import { OrgClinicGeneralComponent } from './management/organization/org-clinic-general/org-clinic-general.component';
import { WizardComponent } from './patients/patient-tabs/patient-profile-tab/patient-portal-overview/wizard/wizard.component';
import { PatientsDocumentTabComponent } from './patients/patient-tabs/patients-documents-tab/patients-document-tab.component';
import { PatientReviewComponent } from './reviews/patient-review/patient-review.component';
import { ReviewsComponent } from './reviews/reviews.component';

import { AuthComponent } from './auth/auth-component/auth-component.component';
import { CatalogueComponent } from './management/catalogue/catalogue.component';
import { ManagementComponent } from './management/management.component';
import { NudgesComponent } from './nudges/nudges.component';
import { EditPatientComponent } from './patients/actionpanel/edit-patient/edit-patient.component';
import { PatientAccountTabComponent } from './patients/patient-tabs/patient-account-tab/patient-account-tab.component';
import { PatientChartTabComponent } from './patients/patient-tabs/patient-chart-tab/patient-chart-tab.component';
import { PatientCommunicationTabComponent } from './patients/patient-tabs/patient-communication-tab/patient-communication-tab.component';
import { PatientPhotosConsentedTabComponent } from './patients/patient-tabs/patient-photos-consented-tab/patient-photos-consented-tab.component';
import { PatientPhotosTabComponent } from './patients/patient-tabs/patient-photos-tab/patient-photos-tab.component';
import { PatientProfileTabComponent } from './patients/patient-tabs/patient-profile-tab/patient-profile-tab.component';
import { PatientTabsComponent } from './patients/patient-tabs/patient-tabs.component';
import { PatientTransactionsTabComponent } from './patients/patient-tabs/patient-transactions-tab/patient-transactions-tab.component';
import { PatientComponent } from './patients/patient.component';
import { PatientsListComponent } from './sidenav/quick-links/patients-list/patients-list.component';

import { AddressBookComponent } from './management/address-book/address-book.component';
import { BillingComponent } from './management/billing/billing.component';
import { CatalogueDocumentTypesComponent } from './management/catalogue/catalogue-document-types/catalogue-document-types.component';
import { CatalogueProductsComponent } from './management/catalogue/catalogue-products/catalogue-products.component';
import { CatalogueServicesComponent } from './management/catalogue/catalogue-services/catalogue-services.component';
import { CommunicationsComponent } from './management/communications/communications.component';
import { OrgResourcesComponent } from './management/organization/org-resources/org-resources.component';
import { OrgUsersComponent } from './management/organization/org-users/org-users.component';
import { OrganizationComponent } from './management/organization/organization.component';
import { AppointmentsComponent } from './schedule/appointments/appointments.component';
import { RoomsComponent } from './schedule/rooms/rooms.component';
import { TasksComponent } from './schedule/tasks/tasks.component';

import { AuthGuard } from './auth/app.auth.guard';
import { EmilyComponent } from './emily/emily.component';
import { EditDocumentTypeComponent } from './management/catalogue/actionpanel/edit-document-type/edit-document-type.component';
import { EditProductComponent } from './management/catalogue/actionpanel/edit-product/edit-product.component';
import { EditServiceComponent } from './management/catalogue/actionpanel/edit-service/edit-service.component';
import { EditResourceComponent } from './management/organization/actionpanel/edit-resource/edit-resource.component';
import { EditUserComponent } from './management/organization/actionpanel/edit-user/edit-user.component';
import { VisitsComponent } from './schedule/appointments/actionpanel/visits/visits.component';
// import { authPermissions, createPolicy } from './auth/auth-permissions';
import { EditDoctorComponent } from './management/address-book/actionpanel/edit-doctor/edit-doctor.component';
import { EditLabComponent } from './management/address-book/actionpanel/edit-lab/edit-lab.component';
import { EditPharmacyComponent } from './management/address-book/actionpanel/edit-pharmacy/edit-pharmacy.component';
import { AddressBookDoctorsComponent } from './management/address-book/address-book-doctors/address-book-doctors.component';
import { AddressBookLabsComponent } from './management/address-book/address-book-labs/address-book-labs.component';
import { AddressBookPharmaciesComponent } from './management/address-book/address-book-pharmacies/address-book-pharmacies.component';
import { BillingSettingsEditComponent } from './management/billing/billing-settings-edit/billing-settings-edit.component';
import { BillingSettingsListComponent } from './management/billing/billing-settings-list/billing-settings-list.component';
import { EditTextTemplateComponent } from './management/catalogue/actionpanel/edit-text-template/edit-text-template.component';
import { CatalogueClinicSuppliesComponent } from './management/catalogue/catalogue-clinic-supplies/catalogue-clinic-supplies.component';
import { ClinicSuppliesEditComponent } from './management/catalogue/catalogue-clinic-supplies/clinic-supplies-edit/clinic-supplies-edit.component';
import { CatalogueTextTemplatesComponent } from './management/catalogue/catalogue-text-templates/catalogue-text-templates.component';
import { ClinicDocumentsEditComponent } from './management/clinic-documents/clinic-documents-edit/clinic-documents-edit.component';
import { ClinicDocumentsListComponent } from './management/clinic-documents/clinic-documents-list/clinic-documents-list.component';
import { ClinicDocumentsComponent } from './management/clinic-documents/clinic-documents.component';
import { EditCancellationReasonComponent } from './management/organization/actionpanel/edit-cancellation-reason/edit-cancellation-reason.component';
import { EditAccessLocationComponent } from './management/organization/org-access-locations/edit-access-location/edit-access-location.component';
import { OrgAccessLocationsComponent } from './management/organization/org-access-locations/org-access-locations.component';
import { OrgCancellationReasonComponent } from './management/organization/org-cancellation-reason/org-cancellation-reason.component';
import { OrgClinicsComponent } from './management/organization/org-clinics/org-clinics.component';
import { EditCompanyComponent } from './management/organization/org-company/org-company.component';
import { MessagingPanelComponent } from './messaging/messaging-panel/messaging-panel.component';
import { AccountOverviewComponent } from './patients/patient-tabs/patient-account-tab/account-overview/account-overview.component';
import { StandardInvoiceComponent } from './patients/patient-tabs/patient-account-tab/standard-invoice/standard-invoice.component';
import { ChartOverviewComponent } from './patients/patient-tabs/patient-chart-tab/chart-overview/chart-overview.component';
import { AddPrescriptionComponent } from './patients/patient-tabs/patient-chart-tab/prescriptions/add-prescription/add-prescription.component';
import { PrescriptionsViewComponent } from './patients/patient-tabs/patient-chart-tab/prescriptions/prescriptions-view/prescriptions-view.component';
import { TransactionsOverviewComponent } from './patients/patient-tabs/patient-transactions-tab/transactions-overview/transactions-overview.component';
import { CreateShiftComponent } from './schedule/appointments/actionpanel/create-shift/create-shift.component';
import { ConfirmDeactivateGuard } from './shared/guards/confirm-deactivate.guard';

import { Policies } from './auth/auth-policies';
import { EditEtreatmentFormComponent } from './management/catalogue/actionpanel/edit-etreatment-form/edit-etreatment-form.component';
import { EditObservationGroupComponent } from './management/catalogue/actionpanel/edit-observation-group/edit-observation-group.component';
import { CatalogueEtreatmentFormsComponent } from './management/catalogue/catalogue-etreatment-forms/catalogue-etreatment-forms.component';
import { ClinicEformEditComponent } from './management/clinic-documents/clinic-eform-edit/clinic-eform-edit.component';
import { EformBuilderComponent } from './management/clinic-documents/eform-builder/eform-builder.component';
import { EformPrintComponent } from './management/clinic-documents/eform-print/eform-print.component';
import { EformViewerComponent } from './management/clinic-documents/eform-viewer/eform-viewer.component';
import { CommunicationSummaryComponent } from './management/communication-summary/communication-summary.component';
import { CommunicationWrapperComponent } from './management/communication-wrapper/communication-wrapper.component';
import { EditEmailVisitReminderComponent } from './management/communications/actionpanel/edit-email-visit-reminder/edit-email-visit-reminder.component';
import { EditVisitReminderSettingsComponent } from './management/communications/actionpanel/edit-visit-reminder-settings/edit-visit-reminder-settings.component';
import { SendTestVisitReminderComponent } from './management/communications/actionpanel/send-test-visit-reminder/send-test-visit-reminder.component';
import { SendTestEmailComponent } from './management/dialogs/send-test-email/send-test-email.component';
import { EmailTemplatesComponent } from './management/email-templates/email-templates.component';
import { FormBuilderComponent } from './management/forms/form-builder/form-builder.component';
import { FormEditComponent } from './management/forms/form-edit/form-edit.component';
import { FormViewerComponent } from './management/forms/form-viewer/form-viewer.component';
import { FormsListComponent } from './management/forms/forms-list/forms-list.component';
import { FormsComponent } from './management/forms/forms.component';
import { GiftCardsOverviewComponent } from './management/gift-cards/gift-cards-overview/gift-cards-overview.component';
import { GiftCardsSettingsComponent } from './management/gift-cards/gift-cards-settings/gift-cards-settings.component';
import { GiftCardsComponent } from './management/gift-cards/gift-cards.component';
import { EditMerchantDeviceComponent } from './management/organization/actionpanel/edit-merchant-device/edit-merchant-device.component';
import { EditOnlineBookingCategoryComponent } from './management/organization/actionpanel/edit-online-booking-category/edit-online-booking-category.component';
import { EditOnlineBookingProviderComponent } from './management/organization/actionpanel/edit-online-booking-provider/edit-online-booking-provider.component';
import { EditOnlineBookingServiceComponent } from './management/organization/actionpanel/edit-online-booking-service/edit-online-booking-service.component';
import { EditPaymentMethodComponent } from './management/organization/actionpanel/edit-payment-method/edit-payment-method.component';
import { OrgMerchantDevicesComponent } from './management/organization/org-merchant-devices/org-merchant-devices.component';
import { OrgOnlineBookingComponent } from './management/organization/org-online-booking/org-online-booking.component';
import { OrgPaymentOverviewComponent } from './management/organization/org-payment-overview/org-payment-overview.component';
import { OrgPhotosComponent } from './management/organization/org-photos/org-photos.component';
import { OrgRolePermissionsComponent } from './management/organization/org-role-permissions/org-role-permissions.component';
import { EditTaxComponent } from './management/organization/org-taxes/edit-tax/edit-tax.component';
import { OrgUserPermissionsComponent } from './management/organization/org-user-permissions/org-user-permissions.component';
import { EditRetentionCampaignComponent } from './management/retention-campaigns/actionpanel/edit-retention-campaign/edit-retention-campaign.component';
import { PreviewCampaignRecipientsComponent } from './management/retention-campaigns/actionpanel/preview-campaign-recipients/preview-campaign-recipients.component';
import { RetentionCampaignsComponent } from './management/retention-campaigns/retention-campaigns.component';
import { MergePatientsComponent } from './patients/merge-patients/merge-patients.component';
import { PatientTreatmentPlanTabComponent } from './patients/patient-tabs/patient-treatment-plan-tab/patient-treatment-plan-tab.component';
import { ServiceDetailComponent } from './patients/patient-tabs/patient-treatment-plan-tab/service-detail/service-detail.component';
import { PowerBiReportsComponent } from './reports/reports/power-bi-reports/power-bi-reports.component';
import { ReportsComponent } from './reports/reports/reports.component';
import { ReviewsHistoryComponent } from './reviews/reviews-history/reviews-history.component';
import { ReviewsReportsComponent } from './reviews/reviews-reports/reviews-reports.component';
import { ReviewsSettingsComponent } from './reviews/reviews-settings/reviews-settings.component';
import { ConfirmDeactivateServiceDetailGuard } from './shared/guards/confirm-deactivate-service-detail.guard';
import { PatientMessagingComponent } from './messaging/patient-messaging/patient-messaging.component';
import { CatalogueMembershipsComponent } from './management/catalogue/catalogue-memberships/catalogue-memberships.component';
import { EditMembershipComponent } from './management/catalogue/actionpanel/edit-membership/edit-membership.component';

const addEditPatient: Route = {
  path: 'edit-patient/:patid',
  component: EditPatientComponent,
  canActivate: [AuthGuard],
  outlet: 'action-panel',
};

const patientPanel: Route = {
  path: 'patient/:patId',
  component: PatientComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', redirectTo: 'patienttabs', pathMatch: 'full' },
    {
      path: 'patienttabs',
      component: PatientTabsComponent,
      canActivate: [AuthGuard],
      data: Policies.patientPanel,
      children: [
        addEditPatient,
        {
          path: 'patientprofiletab',
          component: PatientProfileTabComponent,
          canActivate: [AuthGuard],
        },
        {
          path: 'patienttreatmentplantab',
          component: PatientTreatmentPlanTabComponent,
          children: [
            {
              path: 'overview',
              component: ServiceDetailComponent,
              canDeactivate: [ConfirmDeactivateServiceDetailGuard],
            },
            {
              path: 'detail/:id/:treatmentIsFromToday/:treatmentPlanning/:forceLock',
              component: ServiceDetailComponent,
              canDeactivate: [ConfirmDeactivateServiceDetailGuard],
            },
            {
              path: 'detail/:id/:treatmentIsFromToday/:treatmentPlanning',
              component: ServiceDetailComponent,
              canDeactivate: [ConfirmDeactivateServiceDetailGuard],
            },
            {
              path: '',
              redirectTo: 'overview',
              pathMatch: 'full',
            },
          ],
        },
        {
          path: 'patientcharttab',
          component: PatientChartTabComponent,
          children: [
            {
              path: 'overview',
              component: ChartOverviewComponent,
              canDeactivate: [ConfirmDeactivateGuard],
            },
            {
              path: 'detail/:id/:treatmentIsFromToday/:treatmentPlanning/:forceLock',
              component: ServiceDetailComponent,
              canDeactivate: [ConfirmDeactivateServiceDetailGuard],
            },
            {
              path: 'detail/:id/:treatmentIsFromToday/:treatmentPlanning',
              component: ServiceDetailComponent,
              canDeactivate: [ConfirmDeactivateServiceDetailGuard],
            },
            {
              path: '',
              redirectTo: 'overview',
              pathMatch: 'full',
            },
            {
              path: 'prescription',
              component: AddPrescriptionComponent,
            },
            {
              path: 'prescription/view',
              component: PrescriptionsViewComponent,
            },
          ],
        },
        {
          path: 'patientnudgestab',
          component: NudgesComponent,
          canActivate: [AuthGuard],
          children: [addEditPatient],
        },
        {
          path: 'patientaccounttab',
          component: PatientAccountTabComponent,
          canActivate: [AuthGuard],
          data: Policies.patientAccount,
          children: [
            {
              path: 'overview',
              component: AccountOverviewComponent,
            },
            {
              path: 'invoice/:id',
              component: StandardInvoiceComponent,
            },
            {
              path: 'detail/:id/:treatmentIsFromToday/:treatmentPlanning/:forceLock',
              component: ServiceDetailComponent,
              canDeactivate: [ConfirmDeactivateServiceDetailGuard],
            },
            {
              path: 'detail/:id/:treatmentIsFromToday/:treatmentPlanning',
              component: ServiceDetailComponent,
              canDeactivate: [ConfirmDeactivateServiceDetailGuard],
            },
            {
              path: '',
              redirectTo: 'overview',
              pathMatch: 'full',
            },
          ],
        },
        {
          path: 'patientphotostab',
          component: PatientPhotosTabComponent,
          canActivate: [AuthGuard],
          children: [addEditPatient],
        },
        {
          path: 'patientcommunicationtab',
          component: PatientCommunicationTabComponent,
          canActivate: [AuthGuard],
          children: [addEditPatient],
        },
        {
          path: 'patientdocumentstab',
          component: PatientsDocumentTabComponent,
          canActivate: [AuthGuard],
          canDeactivate: [ConfirmDeactivateGuard],
          children: [addEditPatient],
        },
        {
          path: 'patienttransactionstab',
          component: PatientTransactionsTabComponent,
          canActivate: [AuthGuard],
          children: [
            {
              path: 'overview',
              component: TransactionsOverviewComponent,
            },
            {
              path: 'detail/:id/:treatmentIsFromToday/:treatmentPlanning/:forceLock',
              component: ServiceDetailComponent,
              canDeactivate: [ConfirmDeactivateServiceDetailGuard],
            },
            {
              path: 'detail/:id/:treatmentIsFromToday/:treatmentPlanning',
              component: ServiceDetailComponent,
              canDeactivate: [ConfirmDeactivateServiceDetailGuard],
            },
            {
              path: '',
              redirectTo: 'overview',
              pathMatch: 'full',
            },
          ],
        },
      ],
    },
  ],
  outlet: 'action-panel',
};

const appRoutes: Routes = [
  { path: 'login', component: AuthComponent },
  { path: 'PatientReview/:patientId', component: PatientReviewComponent },
  { path: 'PatientReview/:patientId/:appointmentId', component: PatientReviewComponent },
  { path: 'PatientReview/:patientId/Unsubscribe/Review', component: PatientReviewComponent },

  { path: '', redirectTo: 'schedule', pathMatch: 'full' },
  {
    path: 'print-eform/:docId/:patientId',
    canActivate: [AuthGuard],
    component: EformPrintComponent,
  },
  {
    path: '',
    component: EmilyComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'schedule',
        component: AppointmentsComponent,
        children: [
          addEditPatient,
          patientPanel,
          {
            path: 'visit-details/:id/:patientId',
            component: VisitsComponent,
            outlet: 'action-panel',
          },
          {
            path: 'create-shift/:id',
            component: CreateShiftComponent,
            outlet: 'action-panel',
          },
        ],
      },
      {
        path: 'schedule/tasks',
        component: TasksComponent,
        children: [addEditPatient, patientPanel],
      },
      {
        path: 'schedule/rooms',
        component: RoomsComponent,
        children: [addEditPatient, patientPanel],
      },
      {
        path: 'nudges',
        component: NudgesComponent,
        children: [addEditPatient, patientPanel],
      },
      {
        path: 'patient-messaging',
        component: PatientMessagingComponent,
        children: [patientPanel],
        canActivate: [AuthGuard],
        data: Policies.patientMessaging,
      },
      {
        path: 'reviews',
        component: ReviewsComponent,
        canActivateChild: [AuthGuard],
        data: Policies.reviews,
        children: [
          addEditPatient,
          patientPanel,
          { path: '', redirectTo: 'history', pathMatch: 'full' },
          {
            path: 'history',
            component: ReviewsHistoryComponent,
            canActivate: [AuthGuard],
            data: Policies.reviewsView,
          },
          {
            path: 'reports',
            component: ReviewsReportsComponent,
          },
          {
            path: 'settings',
            component: ReviewsSettingsComponent,
            canActivate: [AuthGuard],
            data: Policies.reviewsEdit,
          },
          {
            path: 'send-test-visit-reminder/:type',
            component: SendTestVisitReminderComponent,
            outlet: 'action-panel',
          },
        ],
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivateChild: [AuthGuard],
        data: Policies.reports,
        children: [addEditPatient, patientPanel],
      },
      {
        path: 'epower-reports',
        component: PowerBiReportsComponent,
        canActivateChild: [AuthGuard],
        data: Policies.reportsPower,
      },
      {
        path: 'gallery',
        component: PatientPhotosConsentedTabComponent,
        children: [addEditPatient, patientPanel],
      },
      {
        path: 'messages',
        component: MessagingPanelComponent,
        children: [addEditPatient, patientPanel],
      },
      {
        path: 'management',
        component: ManagementComponent,
        canActivateChild: [AuthGuard],
        data: Policies.management,
        children: [
          {
            path: 'communication',
            component: CommunicationWrapperComponent,
            children: [
              { path: '', redirectTo: 'summary', pathMatch: 'full' },
              {
                path: 'summary',
                component: CommunicationSummaryComponent,
                canActivateChild: [AuthGuard],
                data: Policies.communicationsSummary,
                children: [addEditPatient, patientPanel],
              },
              {
                path: 'appointments',
                component: CommunicationsComponent,
                canActivateChild: [AuthGuard],
                data: Policies.communicationsAppointments,
                children: [
                  addEditPatient,
                  patientPanel,
                  {
                    path: 'edit-visit-reminder-settings/:type',
                    component: EditVisitReminderSettingsComponent,
                    outlet: 'action-panel',
                  },
                  {
                    path: 'edit-email-visit-reminder',
                    component: EditEmailVisitReminderComponent,
                    outlet: 'action-panel-secondary',
                  },
                  {
                    path: 'send-test-visit-reminder/:type',
                    component: SendTestVisitReminderComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
              {
                path: 'retention-campaigns',
                component: RetentionCampaignsComponent,
                canActivateChild: [AuthGuard],
                data: Policies.communicationsRetentionCampaigns,
                children: [
                  addEditPatient,
                  patientPanel,
                  {
                    path: 'edit-retention-campaigns/:id',
                    component: EditRetentionCampaignComponent,
                    outlet: 'action-panel-secondary',
                    children: [patientPanel],
                  },
                  {
                    path: 'preview-campaign-recipients/:id',
                    component: PreviewCampaignRecipientsComponent,
                    outlet: 'action-panel',
                  },
                  {
                    path: 'send-test-retention-campaign-email/:id',
                    component: SendTestEmailComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
              {
                path: 'email-templates',
                component: EmailTemplatesComponent,
                canActivateChild: [AuthGuard],
                data: Policies.communicationsEmailTemplates,
                children: [
                  addEditPatient,
                  patientPanel,
                  {
                    path: 'edit-email-templates/:id/:templateName',
                    component: EditEmailTemplatesComponent,
                    outlet: 'action-panel',
                  },
                  {
                    path: 'edit-email-templates/:id',
                    component: EditEmailTemplatesComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
            ],
          },
          { path: '', redirectTo: 'catalogue', pathMatch: 'full' },
          {
            path: 'catalogue',
            component: CatalogueComponent,
            canActivateChild: [AuthGuard],
            data: Policies.managementCatalogue,
            children: [
              { path: '', redirectTo: 'services', pathMatch: 'full' },
              {
                path: 'services',
                component: CatalogueServicesComponent,
                children: [
                  addEditPatient,
                  patientPanel,
                  {
                    path: 'edit-service/:catname/:servid',
                    component: EditServiceComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
              {
                path: 'products',
                component: CatalogueProductsComponent,
                children: [
                  addEditPatient,
                  patientPanel,
                  {
                    path: 'edit-product/:catname/:prodid',
                    component: EditProductComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
              // // Unused components, may be implemented later
              // {
              //   path: 'packages',
              //   component: CataloguePackagesComponent,
              //   children: [
              //     addEditPatient,
              //     patientPanel,
              //     {
              //       path: 'edit-package/:packid',
              //       component: EditPackageComponent,
              //       outlet: 'action-panel'
              //     }
              //   ]
              // },
              // {
              //   path: 'specials',
              //   component: CatalogueSpecialsComponent,
              //   children: [
              //     addEditPatient,
              //     patientPanel,
              //     {
              //       path: 'edit-special/:specid',
              //       component: EditSpecialComponent,
              //       outlet: 'action-panel'
              //     }
              //   ]
              // },
              {
                path: 'document-types',
                component: CatalogueDocumentTypesComponent,
                children: [
                  addEditPatient,
                  patientPanel,
                  {
                    path: 'edit-document-type/:id',
                    component: EditDocumentTypeComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
              {
                path: 'clinic-supplies',
                component: CatalogueClinicSuppliesComponent,
                children: [
                  addEditPatient,
                  patientPanel,
                  {
                    path: 'edit-clinic-supply-item/:id',
                    component: ClinicSuppliesEditComponent,
                    outlet: 'action-panel',
                  },
                  {
                    path: 'edit-observation-group/:id',
                    component: EditObservationGroupComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
              {
                path: 'text-templates',
                component: CatalogueTextTemplatesComponent,
                children: [
                  addEditPatient,
                  patientPanel,
                  {
                    path: 'edit-text-template/:id',
                    component: EditTextTemplateComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
              {
                path: 'memberships',
                component: CatalogueMembershipsComponent,
                children: [
                  {
                    path: 'edit-membership/:id',
                    component: EditMembershipComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
              /*
              {
                path: 'custom-tags',
                component: CatalogueTagsComponent,
                children: [
                  addEditPatient,
                  patientPanel,
                  {
                    path: 'edit-tag/:id',
                    component: EditTagComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
              */
            ],
          },
          {
            path: 'address-book',
            component: AddressBookComponent,
            canActivateChild: [AuthGuard],
            data: Policies.addressBook,
            children: [
              { path: '', redirectTo: 'doctors', pathMatch: 'full' },
              {
                path: 'doctors',
                component: AddressBookDoctorsComponent,
                children: [
                  addEditPatient,
                  patientPanel,
                  {
                    path: 'edit-doctor/:doctid',
                    component: EditDoctorComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
              {
                path: 'pharmacies',
                component: AddressBookPharmaciesComponent,
                children: [
                  addEditPatient,
                  patientPanel,
                  {
                    path: 'edit-pharmacy/:pharmid',
                    component: EditPharmacyComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
              {
                path: 'labs',
                component: AddressBookLabsComponent,
                children: [
                  addEditPatient,
                  patientPanel,
                  {
                    path: 'edit-lab/:labid',
                    component: EditLabComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
            ],
          },
          {
            path: 'visit-reminders',
            component: CommunicationsComponent,
            canActivateChild: [AuthGuard],
            data: Policies.communicationsSummary,
            children: [
              addEditPatient,
              patientPanel,
              {
                path: 'edit-visit-reminder-settings/:type',
                component: EditVisitReminderSettingsComponent,
                outlet: 'action-panel',
              },
              {
                path: 'edit-email-visit-reminder',
                component: EditEmailVisitReminderComponent,
                outlet: 'action-panel-secondary',
              },
              {
                path: 'send-test-visit-reminder/:type',
                component: SendTestVisitReminderComponent,
                outlet: 'action-panel',
              },
            ],
          },
          {
            path: 'retention-campaigns',
            component: RetentionCampaignsComponent,
            canActivateChild: [AuthGuard],
            data: Policies.communicationsRetentionCampaigns,
            children: [
              addEditPatient,
              patientPanel,
              {
                path: 'edit-retention-campaigns/:id',
                component: EditRetentionCampaignComponent,
                outlet: 'action-panel',
                children: [patientPanel],
              },
              {
                path: 'preview-campaign-recipients/:id',
                component: PreviewCampaignRecipientsComponent,
                outlet: 'action-panel',
              },
              {
                path: 'send-test-retention-campaign-email/:id',
                component: SendTestEmailComponent,
                outlet: 'action-panel',
              },
            ],
          },
          /*    {
            path: 'billing',
            component: BillingComponent,
            canActivateChild: [AuthGuard],
            data: Policies.billing,
            children: [
              addEditPatient,
              patientPanel,
              { path: '', redirectTo: 'list', pathMatch: 'full' },
              {
                path: 'list',
                component: BillingSettingsListComponent,
                children: [
                  {
                    path: 'edit-billing-settings/:id',
                    component: BillingSettingsEditComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
            ],
          }, */
          {
            path: 'gift-cards',
            component: GiftCardsComponent,
            /*canActivateChild: [AuthGuard],
            data: createPermissions(null, [
              authPermissions.ReadBillingManagement,
              authPermissions.WriteBillingManagement,
            ]),*/
            children: [
              { path: '', redirectTo: 'overview', pathMatch: 'full' },
              {
                path: 'overview',
                component: GiftCardsOverviewComponent,
              },
              {
                path: 'settings',
                component: GiftCardsSettingsComponent,
              },
            ],
          },
          // {
          //   path: 'clinic-documents',
          //   component: ClinicDocumentsComponent,
          //   canActivateChild: [AuthGuard],
          //   data: Policies.managementDocuments,
          //   children: [
          //     { path: '', redirectTo: 'list', pathMatch: 'full' },
          //     {
          //       path: 'list',
          //       component: ClinicDocumentsListComponent,
          //       children: [
          //         {
          //           path: 'edit-clinic-document/:id',
          //           component: ClinicDocumentsEditComponent,
          //           outlet: 'action-panel',
          //         },
          //         {
          //           path: 'edit-clinic-eform/:id',
          //           component: ClinicEformEditComponent,
          //           outlet: 'action-panel',
          //         },
          //       ],
          //     },
          //     {
          //       path: 'eform-builder/:id',
          //       component: EformBuilderComponent,
          //     },
          //     {
          //       path: 'eform-viewer/:id',
          //       component: EformViewerComponent,
          //     },
          //   ],
          // },
          {
            path: 'forms',
            component: FormsComponent,
            canActivateChild: [AuthGuard],
            data: Policies.managementForms,
            children: [
              { path: '', redirectTo: 'list', pathMatch: 'full' },
              {
                path: 'list',
                component: FormsListComponent,
                children: [
                  {
                    path: 'edit-form/:id',
                    component: FormEditComponent,
                    outlet: 'action-panel',
                  },
                  {
                    path: 'form-library-overview',
                    component: FormLibraryOverviewComponent,
                    outlet: 'action-panel-secondary',
                  },
                ],
              },
              {
                path: 'form-builder/:id',
                component: FormBuilderComponent,
              },
              {
                path: 'form-viewer/:id',
                component: FormViewerComponent,
              },
              {
                path: 'etreatment-forms',
                component: CatalogueEtreatmentFormsComponent,
                children: [
                  {
                    path: 'edit-etreatment-form/:id',
                    component: EditEtreatmentFormComponent,
                    outlet: 'action-panel',
                  },
                ],
              },
            ],
          },
          {
            path: 'organization',
            component: OrganizationComponent,
            canActivateChild: [AuthGuard],
            data: Policies.managementOrganization,
            children: [
              { path: '', redirectTo: 'clinics', pathMatch: 'full' },
              {
                path: 'clinics',
                component: OrgClinicsComponent,
                canActivateChild: [AuthGuard],
                data: Policies.clinic,
                children: [
                  // { path: '', redirectTo: 'general', pathMatch: 'full' },
                  {
                    path: 'general',
                    component: OrgClinicGeneralComponent,
                    canActivate: [AuthGuard],
                    data: Policies.clinicGeneral,
                  },
                  {
                    path: 'users',
                    component: OrgUsersComponent,
                    canActivate: [AuthGuard],
                    data: Policies.clinicUsers,
                    children: [
                      addEditPatient,
                      patientPanel,
                      {
                        path: 'edit-user/:userid',
                        component: EditUserComponent,
                        canActivate: [AuthGuard],
                        outlet: 'action-panel',
                      },
                    ],
                  },
                  {
                    path: 'user-permissions',
                    component: OrgUserPermissionsComponent,
                    canActivate: [AuthGuard],
                    data: Policies.clinicUserPermissions,
                  },
                  {
                    path: 'role-permissions',
                    component: OrgRolePermissionsComponent,
                    canActivate: [AuthGuard],
                    data: Policies.developer,
                  },
                  {
                    path: 'resources',
                    component: OrgResourcesComponent,
                    canActivate: [AuthGuard],
                    data: Policies.clinicResources,
                    children: [
                      addEditPatient,
                      patientPanel,
                      {
                        path: 'edit-resource/:rsrcid',
                        component: EditResourceComponent,
                        outlet: 'action-panel',
                      },
                    ],
                  },
                  {
                    path: 'photos',
                    component: OrgPhotosComponent,
                    canActivate: [AuthGuard],
                    data: Policies.clinicPhotos,
                  },
                  {
                    path: 'cancellation-reason',
                    component: OrgCancellationReasonComponent,
                    canActivate: [AuthGuard],
                    data: Policies.clinicCancellationReasons,
                    children: [
                      addEditPatient,
                      patientPanel,
                      {
                        path: 'edit-cancellation-reason/:cnclid',
                        component: EditCancellationReasonComponent,
                        canActivate: [AuthGuard],
                        outlet: 'action-panel',
                      },
                    ],
                  },
                  {
                    path: 'access-locations',
                    component: OrgAccessLocationsComponent,
                    canActivate: [AuthGuard],
                    data: Policies.clinicAccessLocations,
                    children: [
                      addEditPatient,
                      patientPanel,
                      {
                        path: 'edit-access-location/:accessId',
                        component: EditAccessLocationComponent,
                        canActivate: [AuthGuard],
                        outlet: 'action-panel',
                      },
                    ],
                  },
                  {
                    path: 'payments',
                    component: OrgPaymentOverviewComponent,
                    canActivate: [AuthGuard],
                    data: Policies.clinicPayments,
                    children: [
                      addEditPatient,
                      patientPanel,
                      {
                        path: 'edit-payment-method/:id',
                        component: EditPaymentMethodComponent,
                        canActivate: [AuthGuard],
                        outlet: 'action-panel',
                      },
                      {
                        path: 'edit-tax/:taxId',
                        component: EditTaxComponent,
                        canActivate: [AuthGuard],
                        outlet: 'action-panel',
                      },
                      {
                        path: 'edit-billing-settings/:id',
                        component: BillingSettingsEditComponent,
                        canActivateChild: [AuthGuard],
                        data: Policies.billing,
                        outlet: 'action-panel',
                      },
                    ],
                  },
                  /*
                  {
                    path: 'payment-methods',
                    component: OrgPaymentMethodsComponent,
                    children: [
                      addEditPatient,
                      patientPanel,
                      {
                        path: 'edit-payment-method/:id',
                        component: EditPaymentMethodComponent,
                        canActivate: [AuthGuard],
                        outlet: 'action-panel',
                      },
                    ],
                  },
                  {
                    path: 'taxes',
                    component: OrgTaxesComponent,
                    children: [
                      addEditPatient,
                      patientPanel,
                      {
                        path: 'edit-tax/:taxId',
                        component: EditTaxComponent,
                        canActivate: [AuthGuard],
                        outlet: 'action-panel',
                      },
                    ],
                  },
                  */
                  {
                    path: 'online-booking',
                    component: OrgOnlineBookingComponent,
                    canActivate: [AuthGuard],
                    data: Policies.clinicOnlineBooking,
                    children: [
                      addEditPatient,
                      patientPanel,
                      {
                        path: 'edit-online-booking-provider/:provId',
                        component: EditOnlineBookingProviderComponent,
                        canActivate: [AuthGuard],
                        outlet: 'action-panel',
                      },
                      {
                        path: 'edit-online-booking-category/:catId',
                        component: EditOnlineBookingCategoryComponent,
                        canActivate: [AuthGuard],
                        outlet: 'action-panel',
                      },
                      {
                        path: 'edit-online-booking-service/:servId',
                        component: EditOnlineBookingServiceComponent,
                        canActivate: [AuthGuard],
                        outlet: 'action-panel',
                      },
                    ],
                  },
                  {
                    path: 'merchant-devices',
                    component: OrgMerchantDevicesComponent,
                    canActivate: [AuthGuard],
                    data: Policies.clinicMerchantDevices,
                    children: [
                      {
                        path: 'edit-merchant-device/:deviceId',
                        component: EditMerchantDeviceComponent,
                        outlet: 'action-panel',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'patients-list',
        component: PatientsListComponent,
        canActivateChild: [AuthGuard],
        data: Policies.patientList,
        children: [addEditPatient, patientPanel],
      },
      {
        path: 'merge-patients',
        component: MergePatientsComponent,
        canActivateChild: [AuthGuard],
        children: [addEditPatient],
        data: Policies.patientMerge,
      },
      /*
        Date: 05-01-2021
        Description: merge patient route
      */
      {
        path: 'merge-patients/:id1/:id2',
        component: MergePatientsComponent,
        canActivateChild: [AuthGuard],
        data: Policies.patientMerge,
      },
    ],
  },
  {
    path: 'portal/patientportalwizard/:patId',
    component: WizardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'patientdocumentstab/:patId/:kioskMode',
    component: PatientsDocumentTabComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':clinic/patientdocumentstab/:patId/:kioskMode',
    component: PatientsDocumentTabComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  relativeLinkResolution: 'legacy',
};
@NgModule({
  imports: [RouterModule.forRoot(appRoutes, routingConfiguration)],
  exports: [RouterModule],
})
export class Routing {}
