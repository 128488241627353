import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmCancelWithReasonDialogComponent } from '@app/management/dialogs/confirm-cancel-with-reason/confirm-cancel-with-reason.component';
import { ConfirmCancelData } from '@models/billing/confirm-cancel-data';
import { ServicePurchased } from '@models/invoice/service-purchased';
import { Appointment } from '@models/scheduler/appointment';
import { forkJoin } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AppointmentService } from './appointments.service';
import { TreatmentPlanService } from './treatment-planning/treatment-plan.service';
import { ServicesService } from './services.service';
import { UsersService } from './users.service';

type CreditPurchased = {
  name: string;
  pricePaid: number;
  quantity: number;
};

@Injectable({
  providedIn: 'root',
})
export class ReturnService {

  constructor(
    private appointmentService: AppointmentService,
    private treatmentPlanService: TreatmentPlanService,
    private servicesService: ServicesService,
    private userService: UsersService,
    private dialog: MatDialog
  ) {}

  async checkForServiceCancellation(servicesPurchased: ServicePurchased[]): Promise<boolean> {
    const appointmentsToCancel: Appointment[] = [];
    for (const purchasedService of servicesPurchased) {
      if (!purchasedService.plannedTreatmentMultipleId) {
        const ptAppointment = await this.treatmentPlanService
          .getAssociatedTreatmentByServiceId(purchasedService.serviceId)
          .pipe(
            filter((pt) => pt != null),
            map((pt) => pt.scheduledService),
            filter((service) => Boolean(service.isLocked) == false),
            mergeMap((service) => this.appointmentService.getAppointmentByServiceId(service.serviceId)),
            filter((appointment) => appointment != null)
          )
          .toPromise();
        if (ptAppointment && ptAppointment.cancelled === false) {
          appointmentsToCancel.push(ptAppointment);
        } else {
          const service = await this.servicesService.getServiceById(purchasedService.serviceId).toPromise();
          if (!service.isLocked) {
            const appointment = await this.appointmentService
              .getAppointmentByServiceId(purchasedService.serviceId)
              .toPromise();
            if (appointment && appointment.cancelled === false) {
              appointmentsToCancel.push(appointment);
            }
          }
        }
      }
    }

    if (appointmentsToCancel.length > 0) {
      const result = await this.confirmAppointmentCancelation();
      if (result) {
        this.cancelAppointments(appointmentsToCancel, result);
        return true;
      }
      return false;
    }
    return true;
  }

  private async confirmAppointmentCancelation(): Promise<ConfirmCancelData> {
    const dialogRef = this.dialog.open(ConfirmCancelWithReasonDialogComponent, {
      width: '380px',
      data: {
        title:
          'The selected service has a scheduled appointment. Refunding this service will automatically cancel the appointment',
        result: '',
        selectedCancelReason: '',
        customCancelReason: '',
      },
    });

    const result = await dialogRef.afterClosed().toPromise();
    if (result.event === 'confirm') {
      return result.data;
    }
    return null;
  }

  private cancelAppointments(appointmentsToCancel: Appointment[], cancelReason: ConfirmCancelData) {
    if (appointmentsToCancel && appointmentsToCancel.length > 0) {
      const updateCalls = appointmentsToCancel.map((appointment) => {
        appointment.cancelledByUserId = this.userService.loggedInUser.id;
        appointment.cancellationDate = new Date();
        appointment.cancelled = true;
        appointment.cancellationReason = cancelReason.selectedCancelReason;
        appointment.cancellationMessage = cancelReason.customCancelReason;
        appointment.source = null;
        appointment.className = '';
        return this.appointmentService.updateAppointment(appointment);
      });

      forkJoin(updateCalls).subscribe((cancelledAppointments) => {
        this.appointmentService.onAllApptsUpdated();
      });
    }
  }

}
