<form [formGroup]="appointmentForm">
  <div class="row mx-0">
    <div class="col-6 pl-2">
      <mat-form-field [floatLabel]="'always'" class="w-100">
        <mat-select
          name="services"
          placeholder="Select Service"
          kendoTooltip
          tooltipClass="em-tooltip"
          [title]="
            editAppointment?.plannedTreatmentId
              ? 'Cannot change service for a scheduled planned treatment. Create a new tx or a new appointment unrelated to the tx.'
              : ''
          "
          [disabled]="
            editAppointment?.paymentStatus == PaymentStatus.Paid ||
            editAppointment?.service?.isLocked ||
            editAppointment?.plannedTreatmentId
          "
          [(value)]="selectedServiceTemplateId"
          (selectionChange)="serviceSelectionChange()"
        >
          <input
            placeholder="Search..."
            matInput
            type="text"
            (keyup)="onServiceSearchKey($event)"
            (keydown)="$event.stopPropagation()"
            class="dropdown-search-input px-2 sticky-top"
          />
          <mat-option *ngFor="let serviceTemplate of searchFilteredTemplates" [value]="serviceTemplate?.id">
            {{ serviceTemplate.serviceName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="w-100 remove-underline" [floatLabel]="'always'">
        <input
          class="w-100 service-provider-field"
          matInput
          placeholder="Service Provider"
          [readonly]="true"
          [value]="selectedStaff ? selectedStaff?.title : 'Please select provider'"
          [ngClass]="selectedStaff ? '' : 'red-font'"
          [disabled]="editAppointment?.service?.isLocked"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="row mx-0">
    <div class="col-6 pl-2 pt-3">
      <label for="startTime" class="custom-label teal-font" [ngClass]="{ disabled: editAppointment?.service?.isLocked }"
        >Start Time</label
      >
      <kendo-timepicker
        class="w-100"
        name="startTime"
        [min]="minTime"
        [max]="maxTime"
        [steps]="{ minute: 5 }"
        [(value)]="startTime"
        (valueChange)="onStartTimeChange($event)"
        [disabled]="editAppointment?.service?.isLocked"
      >
      </kendo-timepicker>
    </div>
    <div class="col-6">
      <mat-form-field class="w-100" [floatLabel]="'always'">
        <mat-select
          placeholder="Duration (minutes)"
          aria-label="Duration"
          [(value)]="calculatedDuration"
          (selectionChange)="onDurationChange($event)"
          [disabled]="editAppointment?.service?.isLocked"
        >
          <mat-option *ngFor="let durOpt of [calculatedDuration].concat(durationOptions)" [value]="durOpt">
            {{ durOpt }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Forms -->
  <div *ngIf="selectedServiceForms?.length > 0" class="row mt-2 mb-3">
    <div class="col pt-0 grey-two-background">
      <div class="row mx-0 mt-3">
        <div class="col px-2">
          <h6 class="sub-heading pl-1">Assign Forms</h6>
        </div>
      </div>
      <div class="row mx-0 mb-1">
        <ng-container *ngFor="let serviceForm of selectedServiceForms; let index = index">
          <div class="col">
            <mat-checkbox
              (change)="formsSelectionModel.toggle(serviceForm.clinicForm.id)"
              [checked]="formsSelectionModel.isSelected(serviceForm.clinicForm.id)"
              >{{ serviceForm.clinicForm.name }}</mat-checkbox
            >
          </div>
          <div class="w-100" *ngIf="(index + 1) % 2 === 0"></div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Credit Card Payments -->
  <div class="p-3 grey-two-background mb-3" *ngIf="patient && selectedService?.requireCardOnFile">
    <div class="d-flex justify-content-between align-items-center pb-2">
      <h6 class="uppercase">Credit Card Information</h6>
      <div class="align-top mb-1">
        <label class="ml-3 mb-0 teal-font text-truncate d-inline">Cancellation Fee?</label>
        <mat-slide-toggle
          title="Toggle credit card payment"
          [checked]="takeCreditCardPayment"
          (change)="onTakeCreditCardPaymentChanged()"
          class="align-middle"
        >
        </mat-slide-toggle>
      </div>
    </div>
    <div class="d-flex flex-column white-background p-2">
      <div class="mb-1">
        Cancellation Fee: <strong>{{ clinic.staffBookingCancellationPrice | currency }}</strong>
      </div>
      <ng-container *ngIf="takeCreditCardPayment; else reasonInput">
        <app-square-card
          *ngIf="isSquareLinked"
          [patient]="patient"
          [selectedSquareCardId]="selectedSquareCardId"
          [requestCreditCard]="requestCreditCard"
          (requestCreditCardChangedEvent)="onRequestCreditCardChanged($event)"
          (cardSelectedEvent)="onSquareCardSelected($event)"
          [showRequestCreditCardButton]="editAppointment != null"
          [appointment]="editAppointment"
        ></app-square-card>
        <app-converge-payment
          *ngIf="false"
          [isDisabled]="!takeCreditCardPayment"
          [patient]="patient"
          [cancellationFee]="clinic.onlineBookingCancellationPrice"
          (cardSelectedEvent)="onConvergeCardSelected($event)"
        ></app-converge-payment>
      </ng-container>
      <ng-template #reasonInput>
        <mat-label class="label-header teal-font mt-2"
          >Reason for not charging credit card: <span class="text-danger">*</span></mat-label
        >
        <textarea
          required
          rows="1"
          placeholder="Add reason"
          class="form-control input-field mb-2"
          [(ngModel)]="reasonCancellationNotCharged"
          [ngModelOptions]="{ standalone: true }"
        ></textarea>
      </ng-template>
    </div>
  </div>

  <!-- Resources -->
  <div
    class="row mx-0"
    *ngIf="selectedService?.serviceTemplateResources?.length && selectedService.serviceTemplateResources.length > 0"
  >
    <div class="col-6">
      <mat-form-field
        class="w-100 remove-underline"
        [floatLabel]="'always'"
        *ngIf="servicesService.hasSingleRoom(selectedService.serviceTemplate) && roomResources[0]?.disabled"
      >
        <input
          class="w-100 service-provider-field"
          matInput
          placeholder="Room"
          [readonly]="true"
          [value]="servicesService.getFirstRoom(selectedService.serviceTemplate)?.name"
          [disabled]="editAppointment?.service?.isLocked"
        />
      </mat-form-field>
      <mat-form-field
        [floatLabel]="'always'"
        class="w-100"
        *ngIf="
          servicesService.hasMultipleRooms(selectedService.serviceTemplate) ||
          (roomResources.length == 1 && !roomResources[0].disabled)
        "
      >
        <mat-select
          formControlName="roomSelect"
          placeholder="Select Room"
          multiple="false"
          [compareWith]="onCompareResource"
          [required]="!editAppointment?.service?.isLocked"
          [disabled]="editAppointment?.service?.isLocked"
        >
          <mat-option *ngFor="let resource of roomResources" [value]="resource" [disabled]="resource.disabled">
            {{ resource.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-6 pl-2">
      <mat-form-field
        class="w-100 remove-underline"
        [floatLabel]="'always'"
        *ngIf="servicesService.hasSingleEquipment(selectedService.serviceTemplate) && !equipmentResources[0]?.disabled"
      >
        <input
          class="w-100 service-provider-field"
          matInput
          placeholder="Equipment"
          [readonly]="true"
          [value]="servicesService.getFirstEquipment(selectedService.serviceTemplate)?.name"
          [disabled]="editAppointment?.service?.isLocked"
        />
      </mat-form-field>
      <mat-form-field
        [floatLabel]="'always'"
        class="w-100"
        *ngIf="
          servicesService.hasMultipleEquipment(selectedService.serviceTemplate) ||
          (equipmentResources.length == 1 && equipmentResources[0].disabled)
        "
      >
        <mat-select
          formControlName="equipmentSelect"
          placeholder="Select Equipment"
          multiple="false"
          [compareWith]="onCompareResource"
          [required]="!editAppointment?.service?.isLocked"
          [disabled]="editAppointment?.service?.isLocked"
        >
          <mat-option *ngFor="let resource of equipmentResources" [value]="resource" [disabled]="resource.disabled">
            {{ resource.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Buttons -->
  <div *ngIf="addMode" class="row mx-0 pb-3">
    <div class="col px-2">
      <button
        class="btn em-btn em-btn-green mr-2"
        data-test-id="add-and-close-create-appointment-button"
        (click)="validateAndCreateAppointment(true)"
        [disabled]="!isFormValid()"
      >
        Add &amp; Close
      </button>
      <button
        class="btn em-btn em-btn-green mr-2"
        data-test-id="add-create-appointment-button"
        (click)="validateAndCreateAppointment(false)"
        [disabled]="!isFormValid()"
      >
        Add
      </button>
      <button class="btn em-btn em-btn-green mr-2" data-test-id="close-panel-button" (click)="closePanel()">
        Close
      </button>
    </div>
  </div>

  <div *ngIf="!addMode && !editAppointment?.service?.isLocked" class="row mx-0 pb-3">
    <div class="col">
      <button
        class="btn em-btn em-btn-green btn-appt mr-2"
        (click)="validateAndUpdateAppointment(editAppointment)"
        [disabled]="!appointmentForm.valid"
      >
        Update Appointment
      </button>
      <button class="btn em-btn em-btn-green btn-appt" (click)="editAppointment.editing = false">Cancel</button>
    </div>
  </div>
</form>
