import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { Clinic } from '@models/clinic';
import { defaultHoursOfOperation } from '@models/hoursofoperation';
import { ClinicsService } from '@services/clinics.service';
import { UsersService } from '@services/users.service';

@Component({
  selector: 'app-select-clinic',
  templateUrl: './select-clinic.component.html',
  styleUrls: ['./select-clinic.component.less'],
})
export class SelectClinicComponent implements OnInit, OnChanges {
  @Input() set showDevClinic(show: boolean) {
    this._showDevClinic = show;
    this.init();
  }
  @Input() disabled = false;
  get showDevClinic(): boolean {
    return this._showDevClinic;
  }
  private _showDevClinic = false;
  clinics: Clinic[] = [];
  selectedClinicId: number;
  developerPolicySatisfied = false;
  organizationPolicySatisfied = false;

  constructor(
    private usersService: UsersService,
    private clinicsService: ClinicsService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.developerPolicySatisfied = this.authService.userSatisfiesPolicy(Policies.developer);
    this.organizationPolicySatisfied = this.authService.userSatisfiesPolicy(Policies.organization);
    this.clinicsService.clinicIdSelected$.subscribe((clinicId) => {
      if (clinicId != this.selectedClinicId && clinicId !== 0) {
        this.init();
      }
      this.selectedClinicId = clinicId;
    });
    this.init();
  }

  init() {
    this.clinicsService.getClinicsForCompany().subscribe((clinics) => {
      this.clinics = clinics ?? [];
      if (this.developerPolicySatisfied && this.showDevClinic) {
        this.clinics.push(this.getDevClinic());
      }
      if (!clinics.some((clinic) => clinic.clinicId == this.selectedClinicId)) {
        const loggedInClinic = this.usersService.loggedInUserClinic;
        this.clinicsService.clinicIdSelected$.next(loggedInClinic);
      }
    });
  }

  ngOnChanges() {
    this.init();
  }

  swapClinic(event: Event) {
    const clinicId = +(event.target as HTMLSelectElement).value;
    if (clinicId != 0) {
      this.clinicsService.swapToClinic(clinicId);
    } else {
      this.clinicsService.clinicIdSelected$.next(clinicId);
    }
  }

  getDevClinic() {
    const clinic = {
      clinicId: 0,
      name: 'Developers',
      blobContainerName: '',
      address: {
        addressId: 0,
        address1: '',
        address2: '',
        city: '',
        country: '',
        postalCode: '',
        province: '',
      },
      clinicRooms: [],
      clinicTaxes: [],
      companyId: 0,
      email: '',
      website: '',
      srFaxId: null,
      srFaxPassword: '',
      hoursOfOperation: defaultHoursOfOperation,
      timezone: this.usersService.loggedInUserTimezone,
      minimumDuration: 5,
      loyaltyPointsToDollarsRate: null,
      customInvoiceText: '',
      sendPatientPortalEmail: false,
    };
    return clinic;
  }
}
