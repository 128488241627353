import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getArrayFrom } from '@app/shared/helpers';
import { environment } from '@environments/environment';
import * as moment from 'moment';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, share, switchMap, take } from 'rxjs/operators';
import { IPolicy } from './auth-policies';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';

const claimId = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier';
const claimRole = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

interface IAuthToken {
  [claimId]: string;
  [claimRole]: string | string[];
  companyId: string;
  clinicId: string;
  clinicUrl: string;
  Permission: string | string[];
  exp: number;
  iss: string;
  aud: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // Store the parsed token in memory and clear on logout
  private _parsedToken: IAuthToken;
  private _expiration: number;
  private _previousUserId: string;
  private _previousPermissions: string[];
  private _isAuthenticated: boolean;
  private _returnUrl: string;
  // private _refreshTokenTimer: any;

  private _userIdUpdatedSource$ = new ReplaySubject<string>(1);
  private _userPermissionUpdatedSource$ = new ReplaySubject<string[]>(1);

  // User Id change detector
  get userIdUpdated$() {
    return this._userIdUpdatedSource$.asObservable();
  }

  // User permissions change detector
  get userPermissionsUpdated$() {
    return this._userPermissionUpdatedSource$.asObservable();
  }
  public get _router(): Router {
    //this creates router property on your service.
    return this._injector.get(Router);
  }
  public get _activatedRoute(): ActivatedRoute {
    //this creates router property on your service.
    return this._injector.get(ActivatedRoute);
  }
  public get _http(): HttpClient {
    //this creates router property on your service.
    return this._injector.get(HttpClient);
  }

  constructor(private _injector: Injector) {
    // Have to bind the current objects context before passing it in, otherwise could do arrow function
    this.authenticationResults = this.authenticationResults.bind(this);

    // Make sure the previous are set
    this._previousPermissions = [];
    this._previousUserId = '';
  }

  // This gets run after login and refresh token
  private authenticationResults(result: { idToken: string }) {
    this.loginToken = result.idToken;
    this._expiration = this.parsedToken && this.parsedToken.exp;
    this._isAuthenticated = true;
    localStorage.setItem('clinicId', this.parsedToken.clinicId.toString());
    
    const currentUserId = this.userId;
    if (currentUserId && currentUserId !== this._previousUserId) {
      this._userIdUpdatedSource$.next(this.userId);
      this._previousUserId = currentUserId;
    }

    const currentUserPermissions = this.userPermissions;
    if (
      // Make sure there are permissions
      currentUserPermissions.length &&
      // if the lengths differ let it through as it is different
      (currentUserPermissions.length !== this._previousPermissions.length ||
        // or if there are the same number, make sure one permission is different
        this._previousPermissions.some((permission) => currentUserPermissions.indexOf(permission) === -1))
    ) {
      this._userPermissionUpdatedSource$.next(this.userPermissions);
      this._previousPermissions = currentUserPermissions;
    }

    // We do nothing with the routes when it is triggered as the user is using the app
    const activeUserUrl = 'emilyEMRActiveUserRefresh';

    // This is to decide how we route the user after login / refresh / just using the app (no action)
    return this._activatedRoute.queryParams.pipe(
      // just take 1 and then close out the observable
      take(1),
      map((params) => {
        if (parseInt(this.activeClinicId) === -1) {
          //This is a company with no clinic set-up so we will route to Add Clinic
          this._router.navigateByUrl('/management/organization/clinics/general');
        } else if (params['returnUrl']) {
          this._router.navigate([params['returnUrl']]);
        } else if (this._returnUrl === activeUserUrl) {
          // this will execute if a user is logged in and the timeout fires the refresh token (we don't want to do anything with the routes)
        } else if (this._returnUrl) {
          this._router.navigateByUrl(this._returnUrl);
        } else {
          // this will execute if a user went to index url and did not have an access token so logged in
          this._router.navigate(['/']);
        }
        // Reset the returnUrl to null
        this._returnUrl = null;
        return this.userId;
      })
    );
  }

  userSatisfiesPolicy(policy: IPolicy) {
    return !(
      (policy.allPermissions &&
        // If the user does not have all of the permissions
        !(policy.allPermissions as string[]).every((permission) => this.userPermissions.includes(permission))) ||
      (policy.orPermissions &&
        // If the user does not have some of the permissions
        !(policy.orPermissions as string[]).some((permission) => this.userPermissions.includes(permission)))
    );
  }

  login(userName, password) {
    return this._http
      .post<{ idToken: string }>(`${environment.baseUrl}api/auth`, {
        userName,
        password,
      })
      .pipe(switchMap(this.authenticationResults));
  }

  logout() {
    // Close all open dialogs before logout.
    // Using injector here instead of the constructor to avoid NgbModal circular dependency.
    this._injector.get(NgbModal).dismissAll('logout');
    this._injector.get(MatDialog).closeAll();

    return this._http
      .put<{ idToken: string }>(`${environment.baseUrl}api/auth/logout`, {})
      .pipe(catchError((d) => of(null)))
      .subscribe(() => {
        this.clearLoginTokens();

        localStorage.removeItem('companyId');
        localStorage.removeItem('clinicId');
        localStorage.removeItem('timezone');
        localStorage.removeItem('home');
        localStorage.removeItem('testEmail');
        localStorage.removeItem('testSMS');

        this._parsedToken = null;
        this._expiration = null;
        this._returnUrl = null;
        this._isAuthenticated = false;
        this._userIdUpdatedSource$.next('');
        this._previousUserId = '';
        this._userPermissionUpdatedSource$.next([]);
        this._previousPermissions = [];

        this._router.navigate(['/login']);
        return true;
      });
  }

  clearLoginTokens() {
    localStorage.removeItem('login_token');
    localStorage.removeItem('login_token_expires_at');
  }

  refreshInProgress: Observable<string> = null;
  refreshTokenRequest(returnUrl: string = null) {
    this._returnUrl = returnUrl;
    //only one refresh at a time
    if (!this.refreshInProgress)
      this.refreshInProgress = this._refreshTokenRequest()
        .pipe(share())
        .finally(() => {
          this.refreshInProgress = null; //refresh complete - can do a fresh call next time
        });
    return this.refreshInProgress;
  }

  private _refreshTokenRequest(): Observable<string> {
    // do a token refresh (like login)
    // pass in an empty function so it doesn't keep calling the http request
    return this._http.put<{ idToken: string }>(`${environment.baseUrl}api/auth/refreshtoken`, null).pipe(
      switchMap(this.authenticationResults),
      catchError((err) => {
        console.error('Login failed:', err);
        return of(null);
      }),
      map((data) => {
        if (data) {
          console.log('Refresh success:', data);
          return this.loginToken;
        } else {
          this.logout();
          return this.loginToken;
        }
      })
    );
  }

  changeClinicRequest(clinicId: number): Observable<void> {
    // do a token refresh (like login)
    // pass in an empty function so it doesn't keep calling the http request
    return this._http.put<{ idToken: string }>(`${environment.baseUrl}api/auth/ChangeClinic`, clinicId).pipe(
      map((data) => {
        // Store the token so that our interceptor can get it on any requests
        this.loginToken = data.idToken;
        // Clear the parsed token, so it is recreated from the new token
        this._parsedToken = null;
        // Store the expiration too for faster access
        this._expiration = this.parsedToken && this.parsedToken.exp;

        if (data) {
          console.log('Change clinic success:', data);
        } else {
          this.logout();
          console.log('Change clinic fail:', data);
        }
      })
    );
  }

  parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
  }

  get loginToken() {
    return localStorage.getItem('login_token');
  }

  set loginToken(loginToken: string) {
    localStorage.setItem('login_token', loginToken);
  }

  get userId() {
    if (this.parsedToken) {
      return this.parsedToken[claimId];
    }
    return '';
  }

  get clinicUrl() {
    if (this.parsedToken) {
      return this.parsedToken.clinicUrl;
    }
    return '';
  }

  get activeClinicId() {
    if (this.parsedToken) {
      return this.parsedToken.clinicId === '' ? '-1' : this.parsedToken.clinicId;
    }
    return '';
  }

  get userCompanyId() {
    if (this.parsedToken) {
      return this.parsedToken.companyId;
    }
    return '';
  }

  get userPermissions() {
    if (this.parsedToken) {
      return getArrayFrom(this.parsedToken['Permission']);
    }
    return [];
  }

  get userRoles() {
    if (this.parsedToken) {
      return getArrayFrom(this.parsedToken[claimRole]);
    }
    return [];
  }

  get parsedToken() {
    if (!this._parsedToken) {
      if (this.loginToken) {
        this._parsedToken = this.parseJwt(this.loginToken);
      }
    }

    return this._parsedToken;
  }

  get isAuthenticated(): boolean {
    return this._isAuthenticated && this._expiration > moment().unix();
  }

  // Added as a helper so we don't need to declare a public property on components and can just inject this service
  // get permissionList() {
  //   return authPermissions;
  // }

  // Added as a helper so we don't need to declare a public property on components and can just inject this service
  // get createPermissionObject() {
  //   return createPolicy;
  // }
}
