import { Injectable } from '@angular/core';

import { ITransactionTargetItem } from '@interfaces/ITransactionTargetItem';
import { PatientDocument } from '@models/document/patient-document';
import { PatientTransaction } from '@models/patient-transaction/patient-transaction';
import { TargetTypes } from '@models/patient-transaction/target-types';
import { Appointment } from '@models/scheduler/appointment';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';

@Injectable({
  providedIn: 'root'
})
export class PatientTransactionFactoryService {

  constructor() {}

  getTargetObject(patientTransaction: PatientTransaction): ITransactionTargetItem {
    if (patientTransaction.targetTypeId === TargetTypes.PatientDocument) {
      return new PatientDocument();
    } else if (patientTransaction.targetTypeId === TargetTypes.Appointment) {
      return new Appointment();
    } else if (patientTransaction.targetTypeId === TargetTypes.Photo) {
      return new PhotoMetaData();
    } else if (patientTransaction.targetTypeId === TargetTypes.TreatmentPlan) {
      return new TreatmentPlan();
    }
    return null;
  }
}
