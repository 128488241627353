import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { Clinic } from '@models/clinic';
import { AppointmenteTreatmentForm } from '@models/etreatment-forms/appointment-etreatment-form';
import { ServiceeTreatmentForm } from '@models/etreatment-forms/service-etreatment-form';
import { PatientForm } from '@models/forms/patient-form';
import { ServiceForm } from '@models/forms/service-form';
import { Patient } from '@models/patient';
import { TargetTypes } from '@models/patient-transaction/target-types';
import { ConvergeCard } from '@models/payments/converge-card';
import { Resource } from '@models/resource';
import { ResourceType } from '@models/resource-type';
import { Appointment, AppointmentType } from '@models/scheduler/appointment';
import { PaymentStatus } from '@models/scheduler/payment-status';
import { ServiceProvider } from '@models/service-provider';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { Service } from '@models/service/service';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { ServiceNote } from '@models/service/service-note';
import { ServiceResource } from '@models/service/service-resource';
import { SquareCard } from '@models/square/square-card';
import { SquareCardInfoRequest } from '@models/square/square-card-info-request';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { TreatmentState } from '@models/treatment-planning/treatment-state';
import { Visit } from '@models/visit';
import { VisitConfirmedStatus } from '@models/visit-confirm-status';
import { AppointmentService } from '@services/appointments.service';
import { ClinicsService } from '@services/clinics.service';
import { EventsService } from '@services/events.service';
import { ObservationService } from '@services/observation.service';
import { PatientFormService } from '@services/patient-form.service';
import { ResourcesService } from '@services/resources.service';
import { ServiceProviderService } from '@services/service-provider.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { ServicesService } from '@services/services.service';
import { SquareService } from '@services/square.service';
import { TreatmentPlanFormService } from '@services/treatment-planning/treatment-plan-form.service';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { VisitService } from '@services/visit.service';
import moment from 'moment';
import { Observable, Subject, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-visit-edit-appointment',
  templateUrl: './visit-edit-appointment.component.html',
  styleUrls: ['./visit-edit-appointment.component.less'],
})
export class VisitEditAppointmentComponent implements OnInit, OnDestroy {
  @Input() patient: Patient;

  private _visit: Visit;

  patientTokenId: number;
  reasonCancellationNotCharged: string = '';
  selectedSquareCardId: string;
  selectedSquareCard: SquareCard = null;
  squareCardInfoRequest: SquareCardInfoRequest = null;
  isSquareLinked: boolean;
  requestCreditCard: boolean = false;

  @Input() set visit(newVisit: Visit) {
    this._visit = newVisit;
    this.setStartAndDurationFromEvent();
  }
  get visit(): Visit {
    return this._visit;
  }
  @Output() visitChange: EventEmitter<Visit> = new EventEmitter<Visit>();

  private _selectedStaff: ServiceProvider;
  @Input() set selectedStaff(staff: ServiceProvider) {
    this._selectedStaff = staff;
    this.ensureSelectedServiceProviderIsAvailable(this.filteredProviders, staff);
    this.ensureSelectedServiceProviderIsAvailable(this.serviceProviders, staff);
    this.updateAuthorizedServiceTemplates(staff);
  }
  get selectedStaff(): ServiceProvider {
    return this._selectedStaff;
  }

  private _editAppointment: Appointment;
  @Input() set editAppointment(appointment: Appointment) {
    this._editAppointment = appointment;
    this.setParamsFromAppointment(appointment);
  }
  get editAppointment(): Appointment {
    return this._editAppointment;
  }
  get addMode(): Boolean {
    return !this._editAppointment;
  }

  private _selectedTreatmentService: Service;
  @Input() set selectedTreatmentService(service: Service) {
    this._selectedTreatmentService = service;
    if (service) {
      this.setParamsFromService(service);
    }
  }
  get selectedTreatmentService(): Service {
    return this._selectedTreatmentService;
  }

  private _clinic: Clinic;
  set clinic(newClinic: Clinic) {
    this._clinic = newClinic;
    this.setMinMaxTimeFromHoursOfOperation();
  }
  get clinic(): Clinic {
    return this._clinic;
  }

  get loading(): boolean {
    return this.visitService.visitPanelLoading;
  }
  set loading(state: boolean) {
    this.visitService.visitPanelLoading = state;
  }

  selectedService: Service;
  selectedServiceTemplateId: number;
  appointmentForm: FormGroup;

  private authorizedServiceTemplates: ClinicServiceTemplate[] = [];
  searchFilteredTemplates: ClinicServiceTemplate[] = [];
  selectedServiceForms: ServiceForm[];
  formsSelectionModel: SelectionModel<number> = new SelectionModel<number>();

  startTime = new Date();
  minTime = moment().toDate();
  maxTime = moment().toDate();
  durationOptions: number[] = [
    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135,
    140, 145, 150, 155, 160, 165, 170, 175, 180, 185, 190, 195, 200, 205, 210, 215, 220, 225, 230, 235, 240, 245,
  ];
  calculatedDuration: number = 15;
  // customDuration: FormControl;

  equipmentResources: Resource[] = [];
  roomResources: Resource[] = [];

  private currentDate: Date;

  private filteredProviders: ServiceProvider[] = [];
  private serviceProviders: ServiceProvider[] = [];
  private unsub: Subject<void> = new Subject<void>();

  PaymentStatus = PaymentStatus;

  takeCreditCardPayment = true;

  constructor(
    private eventsService: EventsService,
    private providerService: ServiceProviderService,
    public servicesService: ServicesService,
    private serviceTemplatesService: ServiceTemplatesService,
    private clinicsService: ClinicsService,
    private appointmentService: AppointmentService,
    private treatmentPlanService: TreatmentPlanService,
    private dialog: MatDialog,
    private visitService: VisitService,
    private resourcesService: ResourcesService,
    private formBuilder: FormBuilder,
    private patientFormService: PatientFormService,
    private observationService: ObservationService,
    private treatmentPlanFormService: TreatmentPlanFormService,
    private squareService: SquareService
  ) {
    this.initForms();
  }

  ngOnInit(): void {
    this.clinic = this.clinicsService.clinic;
    this.isSquareLinked = this.clinicsService.isSquareLinked;
    this.initSubscriptions();
  }

  private initSubscriptions() {
    this.clinicsService.clinicIdSelected$.subscribe(() => (this.clinic = this.clinicsService.clinic));

    this.eventsService.currentDate.pipe(takeUntil(this.unsub)).subscribe((date) => {
      this.currentDate = date;
      this.providerService
        .getServiceProviderByDate(this.currentDate)
        .pipe(take(1))
        .subscribe((serviceProviders: ServiceProvider[]) => {
          this.serviceProviders = [...serviceProviders];
          this.filteredProviders = [...serviceProviders];
        });
    });

    this.eventsService.updateCreateVisitTimeProvider$.pipe(takeUntil(this.unsub)).subscribe(async (time) => {
      if (time) {
        const clickedDate = this.visitService.fixFullCalendarDate(time.start);
        const isSameDate = clickedDate.isSame(moment(this.currentDate), 'day');
        if (!isSameDate) {
          this.currentDate = clickedDate.toDate();
        }
        if (this.addMode) this.setStartAndDurationFromEvent();
      }
    });
  }

  private initForms() {
    this.appointmentForm = this.formBuilder.group({
      roomSelect: new FormControl(),
      roomSingle: new FormControl(),
      equipmentSelect: new FormControl(),
      equipmentSingle: new FormControl(),
    });
  }

  private setMinMaxTimeFromHoursOfOperation() {
    if (this.clinic) {
      const dayOfWeek = moment(this.startTime).day();
      const openTime = this.clinic.hoursOfOperation.hoursOfOperationDays[dayOfWeek].openTime;
      const closeTime = this.clinic.hoursOfOperation.hoursOfOperationDays[dayOfWeek].closeTime;

      if (closeTime.asMinutes() - openTime.asMinutes() > 0) {
        this.minTime = moment().startOf('day').add(openTime).toDate();
        this.maxTime = moment().startOf('day').add(closeTime).toDate();
      } else {
        this.minTime = moment().startOf('day').toDate();
        this.maxTime = moment().endOf('days').toDate();
      }
    }
  }

  private setStartAndDurationFromEvent() {
    let tempEvent = this.eventsService.getTempEvent();

    if (tempEvent.isSelection) {
      this.startTime = this.visitService.fixFullCalendarDate(tempEvent.start).toDate();
      let tempTimeSelect = Math.abs(moment(tempEvent.start).diff(moment(tempEvent.end), 'minutes'));
      this.calculatedDuration = tempTimeSelect;
    }

    if (!tempEvent.isSelection && this.visit && this.visit.appointments.length > 0) {
      const lastAppt = this.visit.appointments[this.visit.appointments.length - 1];
      this.startTime = moment(lastAppt.date).startOf('day').add(moment.duration(lastAppt.endTime)).toDate();
    }
  }

  private setParamsFromAppointment(appointment: Appointment) {
    this.startTime = moment(appointment.date).startOf('day').add(moment.duration(appointment.startTime)).toDate();
    this.calculatedDuration = moment
      .duration(appointment.endTime)
      .subtract(moment.duration(appointment.startTime))
      .asMinutes();

    this.selectedService = appointment.service;
    this.selectedServiceTemplateId = appointment.service.serviceTemplate.id;
    this.selectedService.serviceTemplate = appointment.service.serviceTemplate;
    this.selectedService.serviceTemplateResources = appointment.service.serviceTemplate.serviceTemplateResources;

    this.providerService
      .getServiceProviderById(appointment.resourceId)
      .subscribe((serviceProvider: ServiceProvider) => {
        this.selectedStaff = Boolean(serviceProvider) ? serviceProvider : new ServiceProvider();
      });
    // Update resources lists
    this.updateResources(this.selectedService);
    if (appointment.reasonCancellationNotCharged) {
      this.takeCreditCardPayment = false;
      this.reasonCancellationNotCharged = appointment.reasonCancellationNotCharged;
    } else {
      if (appointment.squareCardId) {
        this.selectedSquareCardId = appointment.squareCardId;
      }
    }
    this.requestCreditCard = appointment.requestCreditCard;
  }

  private setParamsFromService(service: Service) {
    this.selectedService = service;
    this.selectedServiceTemplateId = service.serviceTemplate.id;
    this.selectedService.serviceTemplateResources = service.serviceTemplate.serviceTemplateResources;
    this.calculatedDuration = service.defaultDurationMinutes ?? this.clinicsService.minimumDuration;
    this.updateResources(this.selectedService);
  }

  private updateAuthorizedServiceTemplates(selectedStaff: ServiceProvider): void {
    if (!selectedStaff) return;
    const requests = selectedStaff.authorizedServiceIds.map((templateId) =>
      this.serviceTemplatesService.getServiceTemplateById(templateId)
    );
    forkJoin(requests).subscribe((serviceTemplates) => {
      this.authorizedServiceTemplates = serviceTemplates.sort((a, b) => (a.serviceName > b.serviceName ? 1 : -1));
      this.searchFilteredTemplates = this.authorizedServiceTemplates;
      if (this.selectedService) {
        this.filteredProviders = [];
        this.serviceProviders.forEach((sp: ServiceProvider) => {
          if (sp.authorizedServiceIds.some((templateId) => templateId === this.selectedService.templateId)) {
            this.filteredProviders.push(sp);
          }
        });
      }
    });
  }

  private ensureSelectedServiceProviderIsAvailable(
    serviceProviders: ServiceProvider[],
    selectedProvider: ServiceProvider
  ) {
    if (selectedProvider) {
      if (!serviceProviders) {
        serviceProviders = [];
      }
      if (!serviceProviders.find((o) => o.id === selectedProvider.id)) {
        serviceProviders.push(selectedProvider);
      }
    }
  }

  validateAndUpdateAppointment(appointment: Appointment) {
    this.appointmentService.validateAppointment(this.startTime, this.calculatedDuration, this.selectedStaff.id, () => {
      this.updateAppointmentAndVisitConfirmation(appointment);
    });
  }

  private updateAppointmentAndVisitConfirmation(appointment: Appointment) {
    const dt = this.appointmentService.getStartEndTime(this.startTime, this.calculatedDuration);
    // Check resources allocated for the Appointment
    var editedResources = this.getResourcesForService(this.selectedService, this.appointmentForm);
    this.appointmentService.validateResourcesAndCreateOrUpdateAppointment(
      editedResources,
      this.selectedStaff,
      this.startTime,
      dt,
      () => {
        // If the start time hasn't changed, don't update the confirmed status of the appointment/visit
        var oldStartTime = moment.duration(appointment.startTime);
        var updateConfirmationStatus =
          oldStartTime.hours() != dt.startTime.hours() || oldStartTime.minutes() != dt.startTime.minutes();
        this.updateAppointment(appointment, dt);
        this.visitService.getVisitById(appointment.visitId).subscribe({
          next: (visit) => {
            if (updateConfirmationStatus) this.visitService.updateAutoConfirmation(appointment, visit, true);
          },
        });
      },
      () => true
    );
  }

  async updateAppointment(
    appointment: Appointment,
    dt: { date: Date; startTime: moment.Duration; endTime: moment.Duration }
  ) {
    appointment.startTime = dt.startTime;
    appointment.endTime = dt.endTime;
    if (this.selectedStaff) {
      appointment.resourceId = this.selectedStaff.id;
    }
    appointment.editing = false;
    if (appointment.service.templateId !== this.selectedServiceTemplateId) {
      if (!appointment.service.governmentBilling && this.selectedService.governmentBilling == true) {
        //do not allow switching from private to medical billing - must cancel
        const dialogRef = this.dialog.open(GenericDialogComponent, {
          width: '330px',
          data: {
            title: 'Cannot Switch Appointment Service',
            content:
              'You are trying to switch a privately billed service to a government service. Please cancel and schedule a new appointment instead.',
            confirmButtonText: 'OK',
            showCancel: false,
          },
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsub))
          .subscribe((result) => {});
        return;
      } else {
        // The appointment service has been updated
        const newService = this.servicesService.mapServiceFromTemplate(
          this.selectedService.serviceTemplate,
          this.patient
        );

        newService.serviceId = appointment.serviceId;
        newService.userCategories = null;
        newService.serviceBillingCodes.forEach((sb) => {
          sb.serviceId = newService.serviceId;
          sb.id = 0;
        });
        appointment.title = newService.serviceName;

        const cst: ClinicServiceTemplate = this.authorizedServiceTemplates.find(
          (service) => service.id === this.selectedService.templateId
        );
        this.addFormsToAppointment(appointment);
        this.addeTreatmentFormsToAppointment(appointment, cst);
        this.addResourcesToService(newService, this.appointmentForm);
        // Replace the old service with the new one
        await this.servicesService.updateService(newService).toPromise();
      }
    } else {
      // Update the service resources for the servicesService
      this.addResourcesToService(appointment.service, this.appointmentForm);
      // Replace the old service with the new one
      await this.servicesService.updateService(appointment.service).toPromise();
    }
    // Add Cancellation No Charge Reason
    if (this.takeCreditCardPayment == false) {
      appointment.reasonCancellationNotCharged = this.reasonCancellationNotCharged;
      // Clear out the Patient Tokens
      appointment.patientTokenId = null;
      appointment.squareCardId = null;
    } else {
      appointment.reasonCancellationNotCharged = null;
      // Add Patient Token
      appointment.patientTokenId = this.patientTokenId;
      // Add Square Card Id
      appointment.squareCardId = this.selectedSquareCard?.id;
    }
    appointment.requestCreditCard = this.requestCreditCard;
    const updatedAppointment = await this.appointmentService.updateAppointment(appointment).toPromise();
    updatedAppointment.service = await this.servicesService.getServiceById(updatedAppointment.serviceId).toPromise();
    this.editAppointment = updatedAppointment;
  }

  validateAndCreateAppointment(closeAfterCreate = false) {
    if (!this.appointmentForm.valid) {
      return;
    }
    this.loading = true;
    this.getUnplannedTreatments()
      .pipe(
        catchError((err) => {
          this.loading = false;
          return of(err);
        })
      )
      .subscribe(async (plannedTreatments) => {
        let treatmentPlanScheduled: PlannedTreatment = undefined;
        plannedTreatments?.some((treatment) => {
          if (
            treatment &&
            treatment.service &&
            treatment.service.serviceName &&
            treatment.service.serviceName == this.selectedService.serviceName &&
            treatment.treatmentState == TreatmentState.Unplanned
          ) {
            treatmentPlanScheduled = treatment;
            return true;
          }
          return false;
        });

        let shouldApptScheduleContinue = await this.appointmentService.checkPatientRushNotes(this.patient).toPromise();
        if (!shouldApptScheduleContinue) {
          this.loading = false;
          return;
        }
        let createAppointment =
          !treatmentPlanScheduled || //no planned treatment with the same service template as the selected service template (ie good to go)
          (treatmentPlanScheduled &&
            treatmentPlanScheduled.service &&
            this.selectedService &&
            treatmentPlanScheduled.service.serviceId == this.selectedService.serviceId) //the selected service IS the PT (g2g)
            ? true
            : await this.verifyNewServiceFromTemplate().toPromise();
        if (createAppointment) {
          const dayOfWeek = moment(this.startTime).day();
          const dt = this.appointmentService.getStartEndTime(this.startTime, this.calculatedDuration);
          const visitStartTime = dt.startTime.asMilliseconds();
          const openTime = this.clinic.hoursOfOperation.hoursOfOperationDays[dayOfWeek].openTime.asMilliseconds();
          const closeTime = this.clinic.hoursOfOperation.hoursOfOperationDays[dayOfWeek].closeTime.asMilliseconds();
          if (visitStartTime < openTime || visitStartTime > closeTime) {
            const dialogRef = this.dialog.open(GenericDialogComponent, {
              width: '300px',
              data: {
                title: 'Warning',
                content: 'You are about to add an appointment outside of working hours. Do you want to proceed?',
                confirmButtonText: 'Ok',
                showCancel: true,
              },
            });
            dialogRef
              .afterClosed()
              .pipe(takeUntil(this.unsub))
              .subscribe((data) => {
                if (data === 'confirm') {
                  // Check resources allocated for the Appointment
                  var resources = this.getResourcesForService(this.selectedService, this.appointmentForm);
                  this.appointmentService.validateResourcesAndCreateOrUpdateAppointment(
                    resources,
                    this.selectedStaff,
                    this.startTime,
                    dt,
                    () => this.createAppointment(closeAfterCreate),
                    this.resourceConflictCancel
                  );
                } else {
                  this.loading = false;
                  return;
                }
              });
          }
          // Check resources allocated for the Appointment
          var resources = this.getResourcesForService(this.selectedService, this.appointmentForm);
          this.appointmentService.validateResourcesAndCreateOrUpdateAppointment(
            resources,
            this.selectedStaff,
            this.startTime,
            dt,
            () => this.createAppointment(closeAfterCreate),
            this.resourceConflictCancel
          );
        } else {
          this.loading = false;
        }
      });
  }

  private createAppointment(closeAfterCreate = false) {
    const selectedPatient = { ...this.patient };
    selectedPatient.observations = null;
    const appDateTime = this.appointmentService.getStartEndTime(this.startTime, this.calculatedDuration);
    const svc: ClinicServiceTemplate = this.authorizedServiceTemplates.find(
      (service) => service.id === this.selectedService.templateId
    );
    const service = this.servicesService.mapServiceFromTemplate(svc, this.patient);
    // we don't want the default service created to be treated as a Consultation so we clear it's subType
    if (service.serviceDetailTemplateId === ServiceDetailTemplate.TreatmentPlan) {
      service.subType = '';
    }
    if (this.selectedService && this.selectedService.defaultPrice != service.defaultPrice) {
      service.defaultPrice = this.selectedService.defaultPrice;
    }
    if (this.selectedService && this.selectedService.overrideDefaultPrice != service.overrideDefaultPrice) {
      service.overrideDefaultPrice = this.selectedService.overrideDefaultPrice;
    }
    if (this.selectedService && this.selectedService.chargeAmount != service.chargeAmount) {
      service.chargeAmount = this.selectedService.chargeAmount;
    }

    const newAppointment = new Appointment();
    newAppointment.appointmentType = AppointmentType.Regular;
    newAppointment.date = this.appointmentService.stripDateOnlyAsUtcDate(this.currentDate);
    newAppointment.dateTime = this.currentDate;
    newAppointment.startTime = appDateTime.startTime;
    newAppointment.endTime = appDateTime.endTime;
    newAppointment.endDate = this.appointmentService.stripDateOnlyAsUtcDate(this.currentDate);
    newAppointment.resourceId = this.selectedStaff.id;
    newAppointment.visitIdString = selectedPatient.patientId.toString() + this.currentDate.toDateString();
    newAppointment.visitId = this.visit.visitId;
    newAppointment.color = svc.serviceIDColour;
    newAppointment.patientId = this.visit.patientId;
    newAppointment.patient = selectedPatient;
    newAppointment.staffId = this.selectedStaff.id;
    newAppointment.start = moment(this.currentDate).startOf('day').add(newAppointment.startTime).toDate();
    newAppointment.end = moment(this.currentDate).startOf('day').add(newAppointment.endTime).toDate();
    newAppointment.createdDate = new Date();

    // Add Forms to Appointment
    this.addFormsToAppointment(newAppointment);
    // Add eTreatmentForms to Appointment
    this.addeTreatmentFormsToAppointment(newAppointment, svc);
    // add Selected Service Resources
    this.addResourcesToService(service, this.appointmentForm);
    // Add Cancellation No Charge Reason
    if (this.takeCreditCardPayment == false) {
      newAppointment.reasonCancellationNotCharged = this.reasonCancellationNotCharged;
      // Clear out the Patient Tokens
      newAppointment.patientTokenId = null;
      newAppointment.squareCardId = null;
    } else {
      newAppointment.reasonCancellationNotCharged = null;
      // Add Patient Token
      newAppointment.patientTokenId = this.patientTokenId;
      // Add Square Card Id
      newAppointment.squareCardId = this.selectedSquareCard?.id;
      newAppointment.requestCreditCard = this.requestCreditCard;
    }
    this.servicesService
      .addService(service)
      .pipe(
        catchError((err) => {
          this.loading = false;
          return throwError(err);
        })
      )
      .subscribe(async (srv) => {
        if (
          this.treatmentPlanService.treatmentPlanScheduled?.service?.observations?.length &&
          this.treatmentPlanService.treatmentPlanScheduled.service.observations.length > 0
        ) {
          await this.duplicateObservationsFromTreatmentPlan(
            srv,
            this.treatmentPlanService.treatmentPlanScheduled.service
          );
        }
        newAppointment.title = selectedPatient.firstName + ' ' + srv.serviceName;
        newAppointment.serviceId = srv.serviceId;
        newAppointment.service = srv;
        newAppointment.service.serviceTemplate = svc;

        if (this.treatmentPlanService.treatmentPlanScheduled) {
          newAppointment.plannedTreatmentId = this.treatmentPlanService.treatmentPlanScheduled.id;
        }
        if (newAppointment.start < moment(new Date()).add(1, 'd').toDate()) {
          this.visit.confirmedStatus = VisitConfirmedStatus.StaffConfirmed;
          this.visit.confirmedTime = new Date();
          this.visitService.updateVisit(this.visit).subscribe((visit) => (this.visit = visit));
        }
        this.visit.totalVisitCost = this.visit.totalVisitCost ? this.visit.totalVisitCost : 0;
        this.visit.totalVisitCost += newAppointment.service.defaultPrice ?? 0;
        this.appointmentService.deleteAppointmentReservation();
        await this.updateEventInUI(newAppointment).toPromise();
        this.visit = await this.visitService.getVisitById(this.visit.visitId).toPromise();
        this.visitChange.emit(this.visit);
        this.treatmentPlanService.treatmentPlanScheduled = null;
        this.eventsService.getTempEvent().isSelection = false;
        //clear service tab in visit panel after add
        this.selectedService = null;
        this.selectedServiceTemplateId = null;
        this.calculatedDuration = null;
        this.startTime = newAppointment.end;
        this.loading = false;
        if (closeAfterCreate) {
          this.visitService.closePanel();
        }
      });
  }

  onStartTimeChange(startTime: Date) {
    const event = this.eventsService.getTempEvent();
    if (event) {
      const calculatedDuration = Math.abs(moment(event.start).diff(moment(event.end), 'minutes'));
      const dt = this.appointmentService.getStartEndTime(startTime, calculatedDuration);
      event.start = moment(event.start).startOf('day').add(dt.startTime);
      event.end = moment(event.start).startOf('day').add(dt.endTime);
      this.eventsService.setTempEvent(event);
    }
    this.updateResources(this.selectedService);
  }

  onDurationChange(change: MatSelectChange) {
    const event = this.eventsService.getTempEvent();
    if (event) {
      event.end = moment(event.start).add(change.value, 'minutes');
      this.eventsService.setTempEvent(event);
    }
    this.updateResources(this.selectedService);
  }

  onCompareResource(a: Resource, b: Resource): boolean {
    if (a === null || a === undefined) {
      return false;
    }
    if (b === null || b === undefined) {
      return false;
    }
    return a.resourceId === b.resourceId;
  }

  private getUnplannedTreatments(): Observable<Array<PlannedTreatment>> {
    return this.treatmentPlanService.getUnplannedTreatmentPlanByPatientId(this.patient.patientId).pipe(
      takeUntil(this.unsub),
      map((tp) => {
        let tpc: TreatmentPlan = this.treatmentPlanFormService.onCleanTreatmentPlan(tp);
        return tpc.plannedTreatments;
      })
    );
  }

  private verifyNewServiceFromTemplate(): Observable<boolean> {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Warning: Are you sure you want to add this service?',
        content:
          'Please confirm if you want to add this service and ignore the existing service in the treatment plan.',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancel: true,
      },
    });

    return dialogRef.afterClosed().pipe(
      takeUntil(this.unsub),
      map((result) => {
        if (result === 'confirm') {
          return true;
        }
        return false;
      })
    );
  }

  private getResourcesForService(service: Service, appointmentFormToUpdate: FormGroup): Resource[] {
    var resources: Resource[] = [];
    var selectedRoom;
    if (this.servicesService.hasSingleRoom(service.serviceTemplate)) {
      selectedRoom = appointmentFormToUpdate.value.roomSingle;
    } else {
      selectedRoom = appointmentFormToUpdate.value.roomSelect;
    }
    if (selectedRoom !== null && selectedRoom !== undefined) {
      resources.push(selectedRoom);
    }
    var selectedEquipment;
    if (this.servicesService.hasSingleEquipment(service.serviceTemplate)) {
      selectedEquipment = appointmentFormToUpdate.value.equipmentSingle;
    } else {
      selectedEquipment = appointmentFormToUpdate.value.equipmentSelect;
    }
    if (selectedEquipment !== null && selectedEquipment !== undefined) {
      resources.push(selectedEquipment);
    }
    return resources;
  }

  private resourceConflictCancel = () => {
    this.loading = false;
    return;
  };

  private addFormsToAppointment(event: Appointment) {
    if (this.formsSelectionModel && this.formsSelectionModel.selected) {
      event.appointmentForms = [];
      this.formsSelectionModel.selected.forEach((clinicFormId: number) => {
        const serviceForm = this.selectedServiceForms.find((sf) => sf.clinicFormId === clinicFormId);
        let newPatientForm = PatientForm.fromForm(this.patient.patientId, serviceForm.clinicForm);
        event.appointmentForms.push(newPatientForm);
      });
    }
  }

  private addeTreatmentFormsToAppointment(event: Appointment, serviceTemplate: ClinicServiceTemplate) {
    // Add the eTreatmentForms that need to be selected for this entry
    event.appointmenteTreatmentForms = [];
    // Get the default eTreatmentForms that should be selected
    let selectedeTreatmentForms = serviceTemplate.clinicServiceTemplateeTreatmentForms.filter(
      (etf) => etf.isSelectedByDefault
    );
    selectedeTreatmentForms.forEach((selectedeTreatmentForm: ServiceeTreatmentForm) => {
      event.appointmenteTreatmentForms.push(
        new AppointmenteTreatmentForm({
          eTreatmentFormId: selectedeTreatmentForm.eTreatmentFormId,
          formDefinition: selectedeTreatmentForm.eTreatmentForm.formDefinition,
        })
      );
    });
  }

  private async duplicateObservationsFromTreatmentPlan(service: Service, serviceToDuplicate: Service): Promise<any> {
    const observations = this.servicesService.applyTreatmentObservationsToService(
      serviceToDuplicate,
      service,
      this.patient
    );
    return this.observationService.addObservations(observations, service.serviceId, false).toPromise();
  }

  private addResourcesToService(service: Service, appointmentFormToUpdate: FormGroup): void {
    service.serviceResources = [];
    var selectedRoom;
    if (this.servicesService.hasSingleRoom(service.serviceTemplate)) {
      selectedRoom = appointmentFormToUpdate.value.roomSingle;
    } else {
      selectedRoom = appointmentFormToUpdate.value.roomSelect;
    }
    if (selectedRoom !== null && selectedRoom !== undefined) {
      service.serviceResources.push(
        new ServiceResource({
          serviceId: service.serviceId,
          resourceId: selectedRoom.resourceId,
          resource: selectedRoom,
        })
      );
    }
    var selectedEquipment;
    if (this.servicesService.hasSingleEquipment(service.serviceTemplate)) {
      selectedEquipment = appointmentFormToUpdate.value.equipmentSingle;
    } else {
      selectedEquipment = appointmentFormToUpdate.value.equipmentSelect;
    }
    if (selectedEquipment !== null && selectedEquipment !== undefined) {
      service.serviceResources.push(
        new ServiceResource({
          serviceId: service.serviceId,
          resourceId: selectedEquipment.resourceId,
          resource: selectedEquipment,
        })
      );
    }
  }

  isFormValid(): boolean {
    return (
      this.patient &&
      this.selectedService &&
      this.appointmentForm.valid &&
      (!this.selectedService.requireCardOnFile ||
        (this.selectedService.requireCardOnFile &&
          !this.takeCreditCardPayment &&
          this.reasonCancellationNotCharged?.length > 0) ||
        (this.selectedService.requireCardOnFile &&
          (this.patientTokenId !== null || this.selectedSquareCard !== null)) ||
        this.requestCreditCard)
    );
  }

  private updateEventInUI(event: Appointment) {
    if (this.treatmentPlanService.treatmentPlanScheduled)
      event.plannedTreatmentId = this.treatmentPlanService.treatmentPlanScheduled.id;

    return this.appointmentService.addVisitedAppointment(event).pipe(
      takeUntil(this.unsub),
      catchError((err) => {
        return throwError(err);
      }),
      switchMap((appointment) => {
        if (appointment.plannedTreatmentId) {
          this.treatmentPlanService.treatmentPlanScheduled$.next();
        }
        this.treatmentPlanService.treatmentPlanScheduled = null;
        if (
          this.treatmentPlanService.treatmentPlanScheduled &&
          this.treatmentPlanService.treatmentPlanScheduled.notes &&
          this.treatmentPlanService.treatmentPlanScheduled.notes.length > 0
        ) {
          const serviceNote = new ServiceNote({
            serviceNoteId: 0,
            entryDate: new Date(),
            enteredBy:
              'Automatically copied from planned treatment #' + this.treatmentPlanService.treatmentPlanScheduled.id,
            entryText: this.treatmentPlanService.treatmentPlanScheduled.notes,
          });
          return this.servicesService.addServiceNote(appointment.appointmentId, serviceNote, TargetTypes.Appointment);
        } else {
          return of();
        }
      })
    );
  }

  closePanel() {
    this.visitService.closePanel();
  }

  onServiceSearchKey(event: KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value;
    const searchTerms = value.split(' ');
    this.searchFilteredTemplates = this.authorizedServiceTemplates.filter((product) => {
      var name = product.serviceName.toLowerCase();
      let match = true;
      for (var term of searchTerms) {
        if (!name.includes(term.toLowerCase())) {
          match = false;
          break;
        }
      }
      if (match) return product;
    });
    if (this.searchFilteredTemplates.length === 0) {
      this.searchFilteredTemplates = this.authorizedServiceTemplates;
    }
  }

  serviceSelectionChange() {
    // If there was a previous cancel authorization, we can void it
    if (this.patientTokenId) {
      // Todo: Void the previous authorization
    }
    this.patientTokenId = null;
    this.reasonCancellationNotCharged = null;
    this.selectedSquareCardId = null;

    let serviceTemplate = this.authorizedServiceTemplates.find((st) => st.id === this.selectedServiceTemplateId);
    let serviceId = this.selectedService && this.selectedService.serviceId ? this.selectedService.serviceId : 0;
    this.selectedService = this.servicesService.mapServiceFromTemplate(serviceTemplate, this.patient);
    this.selectedService.serviceTemplate = serviceTemplate;
    this.selectedService.serviceId = serviceId;

    this.selectedServiceForms = serviceTemplate.serviceForms;
    if (this.selectedServiceForms !== undefined && this.selectedServiceForms !== null) {
      let checkedForms = this.selectedServiceForms.filter((sf) => sf.isSelectedByDefault);
      this.formsSelectionModel = new SelectionModel<number>(
        true, // <- multi-select
        checkedForms.map((f: ServiceForm) => f.clinicForm.id) || [], // <- Initial selections
        true // <- emit an event on selection change
      );
    } else {
      this.formsSelectionModel = new SelectionModel<number>();
    }

    this.calculatedDuration = Math.abs(
      moment(this.eventsService.getTempEvent().start).diff(moment(this.eventsService.getTempEvent().end), 'minutes')
    );

    this.calculatedDuration =
      this.clinicsService.minimumDuration == this.calculatedDuration
        ? this.selectedService.defaultDurationMinutes
        : this.calculatedDuration;

    this.updateResources(this.selectedService);
  }

  updateResources(service: Service): void {
    this.equipmentResources = [];
    this.roomResources = [];
    this.appointmentForm.reset();

    if (service?.serviceTemplate?.serviceTemplateResources) {
      const sortedRoomsAndResources = this.resourcesService.sortRoomsAndResources(
        service.serviceTemplate.serviceTemplateResources
      );
      this.roomResources = sortedRoomsAndResources[0];
      this.equipmentResources = sortedRoomsAndResources[1];

      // Get the Date and Time of Appointment
      const dt = this.appointmentService.getStartEndTime(this.startTime, this.calculatedDuration);
      // Get resources allocated for the Appointment
      this.resourcesService
        .getResourcesAllocated(undefined, dt.date, dt.startTime, dt.endTime)
        .subscribe((allocatedResources) => {
          this.roomResources.forEach((resource) => {
            if (allocatedResources.some((r) => r.resourceId == resource.resourceId)) {
              resource.disabled = true;
            } else {
              resource.disabled = false;
            }
          });
          this.equipmentResources.forEach((resource) => {
            if (allocatedResources.some((r) => r.resourceId == resource.resourceId)) {
              resource.disabled = true;
            } else {
              resource.disabled = false;
            }
          });

          // If there is more than one equipment option available then we make it required; otherwise clear the validators
          if (this.equipmentResources.length > 1) {
            {
              this.appointmentForm.get('equipmentSelect').setValidators([Validators.required]);
            }
          } else {
            this.appointmentForm.get('equipmentSelect').setValidators(null);
          }
          this.appointmentForm.get('equipmentSelect').updateValueAndValidity();

          // If there is more than one room option available then we make it a required
          if (this.roomResources.length > 1) {
            {
              this.appointmentForm.get('roomSelect').setValidators([Validators.required]);
            }
          } else {
            this.appointmentForm.get('roomSelect').setValidators(null);
          }
          this.appointmentForm.get('roomSelect').updateValueAndValidity();

          // If there is only one of room or equipment and it's not disabled, we will mark them as the ones selected
          if (this.roomResources.length === 1 && this.roomResources[0].disabled == false) {
            this.appointmentForm.patchValue({ roomSingle: this.roomResources[0] });
            this.appointmentForm.patchValue({ roomSelect: this.roomResources[0] });
          }
          if (this.equipmentResources.length === 1 && this.equipmentResources[0].disabled == false) {
            this.appointmentForm.patchValue({ equipmentSingle: this.equipmentResources[0] });
            this.appointmentForm.patchValue({ equipmentSelect: this.equipmentResources[0] });
          }

          // Select the previously selected Resources
          if (service.serviceResources) {
            service.serviceResources.forEach((serviceResource: ServiceResource) => {
              if (serviceResource.resource.resourceType === ResourceType.Equipment) {
                this.appointmentForm.patchValue({ equipmentSelect: serviceResource.resource });
              }
              if (serviceResource.resource.resourceType === ResourceType.Room) {
                this.appointmentForm.patchValue({ roomSelect: serviceResource.resource });
              }
            });
          } else {
            // console.log('no rooms or resources found for %s', service.serviceName);
          }
        });
    }
  }

  onTakeCreditCardPaymentChanged(): void {
    this.takeCreditCardPayment = !this.takeCreditCardPayment;
    if (!this.takeCreditCardPayment) {
      this.selectedSquareCard = null;
      this.selectedSquareCardId = null;
      this.patientTokenId = null;
      this.requestCreditCard = false;
    }
  }

  onConvergeCardSelected(card: ConvergeCard) {
    this.patientTokenId = card.patientTokenId;
  }

  onSquareCardSelected(card: SquareCard) {
    this.selectedSquareCard = card;
  }

  onRequestCreditCardChanged(event): void {
    this.requestCreditCard = event;
    if (this.requestCreditCard) {
      this.selectedSquareCard = null;
      this.selectedSquareCardId = null;
      this.patientTokenId = null;
    }
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
