import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, combineLatest, merge } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { isNullOrUndefined } from '@app/shared/helpers';

import { PatientDocument } from '@models/document/patient-document';
import { Tag } from '@models/tag/tag';
import { PatientService } from '@services/patient.service';
import { PatientDocumentService } from '@services/patient-documents.service';
import { TagService } from '@services/tag.service';
import { AddDocumentComponent } from '../add-document/add-document.component';
import { TagType } from '@models/tag/tag-type';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { AssignClinicFormComponent } from '../assign-clinic-form/assign-clinic-form.component';
import { PatientFormService } from '@services/patient-form.service';
import { PatientForm } from '@models/forms/patient-form';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';

enum DocumentType {
  Upload,
  LegacyForm,
  Form,
}

interface FormItem {
  id: number;
  type: DocumentType;
  form: PatientDocument | PatientForm;
  name: string;
  date: Date;
}

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.less'],
})
export class DocumentListComponent implements OnInit, OnDestroy {
  DocumentType = DocumentType; //Adding enum to component for use in template
  uploadedDocs: PatientDocument[] = [];
  legacyForms: PatientDocument[] = [];
  forms: PatientForm[] = [];
  formItems: FormItem[] = [];
  editItem: PatientDocument;
  currentItem: { id: number; docType: DocumentType; doc: PatientDocument | PatientForm };

  tags: Tag[];
  formTags: Tag[];
  pillColours: String[];

  unsub: Subject<void> = new Subject<void>();
  isIpad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
  loading = false;
  patientFormDirty = false;
  patientId: number;
  kioskMode: boolean = false;

  constructor(
    private patientService: PatientService,
    private patientDocumentService: PatientDocumentService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private tagService: TagService,
    private confirmDialog: MatDialog,
    private patientFormService: PatientFormService,
    private dialogService: MatDialog
  ) {
    this.pillColours = this.tagService.getPillColors();
    this.patientId = +this.route.snapshot.params.patId.split('_')[0];
    var kioskMode = this.route.snapshot.params.kioskMode;
    if (kioskMode && kioskMode.toLocaleLowerCase() === 'true') {
      this.kioskMode = JSON.parse(kioskMode);
    }
  }

  ngOnInit() {
    this.patientId = +this.route.snapshot.params.patId.split('_')[0];
    if (this.patientId) {
      this.loadData(this.patientId);
    }

    this.patientFormService.formDirty$.subscribe((isDirty) => (this.patientFormDirty = isDirty));

    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe((patient) => {
      this.patientId = patient.patientId;
      this.loadData(patient.patientId);
    });

    this.loading = true;
    combineLatest([this.patientDocumentService.documents$, this.patientFormService.patientForms$, this.route.params])
      .pipe(takeUntil(this.unsub))
      .subscribe(([docs, forms, params]) => {
        if (!this.kioskMode) {
          this.uploadedDocs = docs.filter((i) => !i.isClinicDocument);
          this.legacyForms = docs.filter((i) => i.isClinicDocument);
        }
        this.forms = forms;
        this.setFormItems();
        this.setDocFromRoute(params);
        this.loading = false;
      });

    this.loading = true;
    merge(this.patientDocumentService.documentSelected, this.patientFormService.patientFormSelected$)
      .pipe(takeUntil(this.unsub))
      .subscribe((doc: PatientDocument | PatientForm) => {
        if (doc) {
          if (this.isPatientForm(doc)) {
            this.router.navigate(['./', { patientFormId: doc.id }], { relativeTo: this.route }).toString();
            this.setDocument(doc, DocumentType.Form);
          } else if (doc.isClinicDocument) {
            this.router.navigate(['./', { clinicDocumentId: doc.id }], { relativeTo: this.route }).toString();
            this.setDocument(doc, DocumentType.LegacyForm);
          } else {
            this.router.navigate(['./', { documentId: doc.id }], { relativeTo: this.route }).toString();
            this.setDocument(doc, DocumentType.Upload);
          }
        } else {
          this.selectInitialDocument();
        }
        this.loading = false;
      });

    this.pillColours = this.tagService.getPillColors();

    this.tagService.getDocumentTags().subscribe((tags) => {
      this.tags = tags;
      this.formTags = tags;
    });
  }

  private setFormItems() {
    let legacyForms =
      this.legacyForms?.map((form) => ({
        id: form.id,
        type: DocumentType.LegacyForm,
        form: form,
        name: form.name,
        date: new Date(form.uploadDate),
      })) ?? [];
    let newForms =
      this.forms?.map((form) => ({
        id: form.id,
        type: DocumentType.Form,
        form: form,
        name: form.clinicForm.name,
        date: new Date(form.assignedDate),
      })) ?? [];
    this.formItems = this.sortForms([...legacyForms, ...newForms]);
  }

  isPatientForm(doc: PatientDocument | PatientForm): doc is PatientForm {
    return (doc as PatientForm).formEntry !== undefined;
  }

  private selectInitialDocument() {
    let firstUpload = this.uploadedDocs[0];
    let firstFormItem = this.formItems[0];
    if (firstUpload) {
      this.patientDocumentService.documentSelected.next(firstUpload);
    } else if (firstFormItem) {
      if (this.isPatientForm(firstFormItem.form)) {
        this.patientFormService.patientFormSelected$.next(firstFormItem.form);
      } else {
        this.patientDocumentService.documentSelected.next(firstFormItem.form);
      }
    }
  }

  private loadData(patientId: number) {
    this.loading = true;
    this.patientDocumentService.getDocuments(patientId).toPromise();
    this.patientFormService.getPatientForms(patientId).toPromise();
    this.loading = false;
  }

  private removeSelectedTags(tags: Tag[]) {
    tags.forEach((initialTag) => {
      const tagIndex = this.formTags.findIndex((tag) => tag.tagId === initialTag.tagId);
      if (tagIndex >= 0) {
        this.formTags.splice(tagIndex, 1);
      }
    });
  }

  private processTags(tags: any[]): Tag[] {
    const result: Tag[] = [];
    tags.forEach((t) => {
      if (isNullOrUndefined(t.patientDocumentId)) {
        const type = !isNullOrUndefined(t.type) ? t.type : TagType.custom;
        const title = !isNullOrUndefined(t.title) ? t.title : t.display;
        let tagRefId = '';
        if (t.type !== TagType.custom) {
          if (!isNullOrUndefined(t.tagId)) {
            tagRefId = t.tagId.split('-')[1];
          }
        }
        result.push(new Tag(t.id, t.tagId, title, type, tagRefId));
      } else {
        result.push(t);
      }
    });
    return result;
  }

  private sortForms(formItems: FormItem[]) {
    return formItems.sort((formA, formB) => new Date(formB.date).getTime() - new Date(formA.date).getTime());
  }

  private setDocFromRoute(params: Params) {
    let paramId;
    let docType;
    if (!isNaN(+params.documentId)) {
      paramId = +params.documentId;
      docType = DocumentType.Upload;
    } else if (!isNaN(+params.clinicDocumentId)) {
      paramId = +params.clinicDocumentId;
      docType = DocumentType.LegacyForm;
    } else if (!isNaN(+params.patientFormId)) {
      paramId = +params.patientFormId;
      docType = DocumentType.Form;
    } else {
      this.selectInitialDocument();
    }

    //make sure item to set is not already current item
    if (this.currentItem?.id != paramId || this.currentItem?.docType != docType) {
      this.setDocumentById(paramId, docType);
    }
  }
  private setDocumentById(docId: number, docType: DocumentType) {
    let doc: PatientDocument | PatientForm;
    if (docType == DocumentType.Upload) {
      doc = this.uploadedDocs?.find((doc) => doc.id == docId);
      this.patientDocumentService.documentSelected.next(doc);
    } else if (docType == DocumentType.LegacyForm) {
      doc = this.legacyForms?.find((doc) => doc.id == docId);
      this.patientDocumentService.documentSelected.next(doc);
    } else if (docType == DocumentType.Form) {
      doc = this.forms?.find((doc) => doc.id == docId);
      this.patientFormService.patientFormSelected$.next(doc);
    }
  }

  private setDocument(doc: PatientDocument | PatientForm, docType: DocumentType) {
    doc['loadingDocument'] = true;
    let id = doc.id;
    this.currentItem = { id, docType, doc };
    setTimeout(() => {
      doc['loadingDocument'] = false;
    }, 2000);
  }

  private async confirmLeaveForm(): Promise<boolean> {
    const dialogRef = this.dialogService.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Warning',
        content: 'You have unsaved changes to this form. Are you sure you want to proceed?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancel: true,
      },
    });
    let proceed = await dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .map((result) => result === 'confirm')
      .toPromise();
    return proceed;
  }

  onSelectDocument(doc: PatientDocument) {
    this.patientDocumentService.documentSelected.next(doc);
  }

  async onSelectForm(formItem: FormItem) {
    if (this.isPatientForm(formItem.form)) {
      let proceed = true;
      if (this.currentItem.id !== formItem.id && this.patientFormDirty) {
        proceed = await this.confirmLeaveForm();
      }
      if (proceed) {
        this.patientFormService.patientFormSelected$.next(formItem.form);
      }
    } else {
      this.patientDocumentService.documentSelected.next(formItem.form);
    }
  }

  uploadDocument() {
    const uploadModal = this.modalService.open(AddDocumentComponent, {
      windowClass: 'add-photo-modal',
      centered: true,
    });
    uploadModal.result.then((doc) => {
      if (doc) {
        this.patientDocumentService.documentSelected.next(doc);
      }
    });
  }

  assignForm() {
    const assignModal = this.modalService.open(AssignClinicFormComponent);
    assignModal.result.then((form) => {
      if (form) {
        this.loading = true;
        const patientForm = PatientForm.fromForm(this.patientId, form);
        this.patientFormService.addPatientForm(patientForm).subscribe((addedPatientForm) => {
          this.patientFormService.patientFormSelected$.next(addedPatientForm);
          this.loading = false;
        });
      }
    });
  }

  onDelete(event: PatientDocument | PatientForm) {
    const dialogRef = this.confirmDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.loading = true;
          if (this.isPatientForm(event)) {
            this.patientFormService
              .deletePatientForm(event.id)
              .pipe(takeUntil(this.unsub))
              .subscribe(() => {});
          } else {
            this.patientDocumentService
              .deleteDocument(event)
              .pipe(takeUntil(this.unsub))
              .subscribe(() => {});
          }
          if (this.currentItem.id === event.id) {
            if (this.isPatientForm(event)) {
              this.patientFormService.patientFormSelected$.next(null);
            } else {
              this.patientDocumentService.documentSelected.next(null);
            }
          }
        }
      });
  }

  enterEditMode(item: PatientDocument) {
    this.editItem = item;
    this.removeSelectedTags(item.tags);
  }

  reAddTagToList(tag: Tag) {
    this.formTags.push(tag);
  }

  exitEditMode() {
    this.editItem = null;
    this.formTags = this.tags;
  }

  save(item: PatientDocument | PatientForm) {
    this.loading = true;

    if (!this.isPatientForm(item)) {
      item = this.editItem;
      item.tags = this.processTags(item.tags);
      this.editItem = null;
    }

    if (this.isPatientForm(item)) {
      this.patientFormService
        .saveSelectedPatientForm()
        .pipe(first())
        .subscribe(() => (this.loading = false));
    } else if (item.isClinicDocument) {
      this.patientDocumentService
        .updatePatientClinicDocument(item)
        .pipe(first())
        .subscribe(() => (this.loading = false));
    } else {
      this.patientDocumentService
        .updateDocument(item)
        .pipe(first())
        .subscribe(() => (this.loading = false));
    }
  }

  downloadForm(dataItem) {
    let iframe = <HTMLIFrameElement>document.getElementsByClassName('formio-iframe')[0];
    let doc_name = dataItem.name + '_' + Date.now() + '.pdf';
    iframe.contentWindow.postMessage({ data: 'downloadMe::' + doc_name }, '*');
  }

  ngOnDestroy() {
    this.patientFormService.patientForms$.next(null);
    this.unsub.next();
    this.unsub.complete();
  }
}
