<app-balloons
  *ngIf="appointmentTile?.daysUntilBirthday <= 7"
  [showBalloons]="appointmentTile?.daysUntilBirthday === 0 && !appointmentTile.isPlaceholder"
></app-balloons>
<div
  id="appointment"
  class="appointment-card w-100 h-100 fc-event"
  style="position: relative"
  [ngClass]="appointmentTile?.className"
  [attr.data-test-id]="'schedule-appointment-tile'"
  [attr.data-test-value]="appointmentTile.id"
>
  <ngx-loading class="d-none" [id]="appointmentTile?.id + '-tile-loading'" [show]="true"></ngx-loading>
  <div
    *ngIf="appointmentTile?.apptType === appointmentType.Regular || eventsService.scheduleView === 'StaffSchedules'"
    class="color-bar"
    [ngStyle]="{ background: appointmentTile?.color }"
  ></div>
  <div
    [ngClass]="{
      'birthday-background': appointmentTile?.daysUntilBirthday <= 7 && !appointmentTile.isPlaceholder,
      'event-selected': isApptSelected(),
      'event-marked': isApptMarked(),
      'appointment-tile':
        appointmentTile?.apptType === appointmentType.Regular ||
        (eventsService.scheduleView === 'StaffSchedules' && !(appointmentTile?.apptType === appointmentType.Blocked)),
      'staff-tile':
        (appointmentTile?.apptType === appointmentType.Staff ||
          appointmentTile?.apptType === appointmentType.Blocked) &&
        eventsService.scheduleView !== 'StaffSchedules',
      'blocked-tile':
        appointmentTile?.apptType === appointmentType.Blocked &&
        (eventsService.scheduleView === 'Appointments' || eventsService.scheduleView === 'StaffSchedules'),
      'grey-seven-background': !appointmentTile?.onlineBooked && !appointmentTile?.isPlaceholder,
      'grey-six-background': appointmentTile?.isPlaceholder,
      'online-booked-background': appointmentTile?.onlineBooked && !appointmentTile?.isPlaceholder
    }"
  >
    <div *ngIf="!appointmentTile?.rendering" class="d-flex justify-content-between h-100 w-100">
      <div class="mt-1 title-container flex-grow-1 mr-1">
        <div
          id="apptPatientName"
          class="title-name fc-title font-weight-bold d-flex align-items-center"
          [ngClass]="{ 'w-100 mr-0': eventsService?.scheduleView !== scheduleView.Appointments }" 
        >
          <span
            id="titleSpan"
            class="title-name-content"
            *ngIf="
              !(appointmentTile?.apptType === appointmentType.Blocked && eventsService.scheduleView === 'Appointments')
            "
          >
            {{ getAppointmentTitle() }}
          </span>
          <span *ngIf="appointmentTile?.isPreferred" id="titleSpan" class="isPreferred ml-2"
            ><i class="fas fa-star"></i
          ></span>
        </div>
        <div
          *ngIf="
            eventsService.scheduleView === 'StaffSchedules' ||
            (appointmentTile?.apptType === appointmentType.Blocked && eventsService.scheduleView === 'Appointments')
          "
          class="appointment-notes"
          [innerHTML]="appointmentTile?.notes | keepHtml"
        ></div>
        <div *ngIf="!appointmentTile?.rendering" class="smaller appointment-specific-notes" id="apptTitle">
          <span
            class="text-uppercase"
            *ngIf="
              !(
                appointmentTile?.apptType === appointmentType.Blocked && eventsService.scheduleView === 'Appointments'
              ) && eventsService.scheduleView !== 'StaffSchedules'
            "
            [innerHTML]="
              (appointmentTile?.roomName ? 'ROOM ' + appointmentTile?.roomName + '&nbsp;&ndash;&nbsp;' : '') +
              (appointmentTile?.serviceName !== '_BlockedScheduleService' ? appointmentTile?.serviceName : '')
            "
          ></span>
          <span
            *ngIf="
              appointmentTile.notes &&
              eventsService.scheduleView === 'Appointments' &&
              appointmentTile?.apptType === appointmentType.Regular &&
              !(appointmentTile?.isPlaceholder && appointmentTile?.onlineBooked)
            "
            class="smaller"
            >{{ ' - ' + appointmentTile.notes }}</span
          >
        </div>
        <div
          *ngIf="appointmentTile?.isPlaceholder && appointmentTile?.onlineBooked"
          class="appointment-actions text-right medium px-1"
        >
          <span [id]="appointmentTile.id + '-reservation-time'"></span>
        </div>
      </div>
      <div
        class="title-bar-icons"
        id="apptPatientCheckIn"
        *ngIf="
          !isWeeklyMode &&
          !(
            appointmentTile?.apptType === appointmentType.Blocked &&
            eventsService.scheduleView === scheduleView.Appointments
          ) &&
          eventsService.scheduleView === scheduleView.Appointments
        "
        [ngClass]="{ 'hide-element': appointmentTile?.isPlaceholder, touch: isIpad }"
      >
        <ng-container *ngIf="!isIpad && !isOverlappingEvent && !actionPanelOpened">
          <button *appAuthGuard="patientProfilePolicy" class="btn patient-btn px-1 fa-fw">
            <i class="fal fa-user" id="patientprofiletab" title="Patient Profile"></i>
          </button>
        </ng-container>
        <button *appAuthGuard="patientChartPolicy" class="btn patient-btn px-1 fa-fw">
          <i class="fal fa-notes-medical" id="patientcharttab" title="Patient Chart"></i>
        </button>
        <ng-container *ngIf="!isIpad && !isOverlappingEvent && !actionPanelOpened">
          <button *appAuthGuard="patientAccountPolicy" class="btn patient-btn px-1 fa-fw">
            <i class="fal fa-file-invoice-dollar" id="patientaccounttab" title="Patient Invoices"></i>
          </button>
        </ng-container>
        <ng-container
          *ngIf="
            isIpad &&
            !(actionPanelOpened || isOverlappingEvent) &&
            appointmentTile?.apptType === appointmentType.Regular &&
            !appointmentTile?.isPlaceholder
          "
        >
          <ng-container *ngTemplateOutlet="statusIcons"> </ng-container>
        </ng-container>
        <ng-container *appAuthGuard="appointmentsPolicy">
          <button
            *ngIf="appointmentVisitStatus == 'uncomfirmed'"
            id="checkin"
            class="btn patient-btn bigger px-1 fa-fw"
          >
            <i class="fal fa-calendar-minus" #first title="Check-in Unconfirmed"></i>
          </button>
          <button
            *ngIf="appointmentVisitStatus == 'confirmed'"
            id="checkin"
            class="btn patient-btn bigger btn-blue px-1 fa-fw"
          >
            <i class="fal fa-calendar-check" title="Check-in Confirmed"></i>
          </button>
          <button
            *ngIf="appointmentVisitStatus == 'checkedIn'"
            id="checkin"
            class="btn patient-btn bigger px-1 fa-fw btn-green"
          >
            <i class="fal fa-calendar-check" title="Checked in"></i>
          </button>
          <button
            *ngIf="appointmentVisitStatus == 'noShow'"
            id="checkin"
            class="btn patient-btn bigger px-1 fa-fw btn-red"
          >
            <i class="fal fa-calendar-exclamation" title="No Show"></i>
          </button>
        </ng-container>
      </div>
    </div>
    <div
      *ngIf="
        !isIpad &&
        !(actionPanelOpened && isOverlappingEvent) &&
        appointmentTile?.apptType === appointmentType.Regular &&
        !appointmentTile?.isPlaceholder
      "
      class="appointment-actions text-right medium px-1"
    >
      <ng-container *ngTemplateOutlet="statusIcons"> </ng-container>
    </div>
  </div>
  <div class="fc-resizer fc-end-resizer"></div>
</div>

<ng-template #statusIcons>
  <i
    *ngIf="
      appointmentTile?.requestCreditCard &&
      appointmentTile?.convergePaymentId == null &&
      appointmentTile?.squarePaymentId == null
    "
    class="far fa-credit-card fa-fw navy-font"
    [ngClass]="isIpad ? 'mr-1' : 'mr-2'"
    title="Credit Card requested for Cancellation"
  ></i>
  <i
    *ngIf="
      !appointmentTile?.requestCreditCard && (appointmentTile?.convergePaymentId || appointmentTile?.squarePaymentId)
    "
    class="far fa-credit-card fa-fw calendar-green-font"
    [ngClass]="isIpad ? 'mr-1' : 'mr-2'"
    title="Credit Card assigned for Cancellation"
  ></i>
  <i
    *ngIf="appointmentTile?.reasonCancellationNotCharged"
    class="far fa-credit-card fa-fw calendar-red-font"
    [ngClass]="isIpad ? 'mr-1' : 'mr-2'"
    title="Credit Card not taken for Cancellation"
  ></i>
  <i
    *ngIf="appointmentTile?.onlineBooked"
    class="fas fa-cloud fa-fw calendar-blue-font"
    [ngClass]="isIpad ? 'mr-1' : 'mr-2'"
    title="Booked online"
  ></i>
  <i
    *ngIf="appointmentTile?.isLocked"
    class="fas fa-lock fa-fw navy-font"
    [ngClass]="isIpad ? 'mr-1' : 'mr-2'"
    title="Locked"
  ></i>
  <i
    *ngIf="!appointmentTile?.isLocked"
    class="fas fa-unlock fa-fw calendar-red-font"
    [ngClass]="isIpad ? 'mr-1' : 'mr-2'"
    title="Unlocked"
  ></i>
  <i
    *ngIf="appointmentTile?.isMinistry"
    [ngClass]="
      appointmentTile?.paymentStatus == AppointmentPaymentStatus.Paid ? 'calendar-green-font' : 'calendar-red-font'
    "
    class="fas fa-university"
    title="Government"
  ></i>
  <i
    *ngIf="!appointmentTile?.isMinistry"
    [ngClass]="{
      'calendar-green-font': appointmentTile?.paymentStatus == AppointmentPaymentStatus.Paid,
      'calendar-red-font': appointmentTile?.paymentStatus == AppointmentPaymentStatus.Unpaid,
      'purple-font': appointmentTile?.paymentStatus == AppointmentPaymentStatus.Refund
    }"
    [class]="
      appointmentTile?.paymentStatus == AppointmentPaymentStatus.Refund ? 'fas fa-sad-tear' : 'fas fa-hand-holding-usd'
    "
    [title]="
      appointmentTile?.paymentStatus == AppointmentPaymentStatus.Paid
        ? 'Paid'
        : appointmentTile?.paymentStatus == AppointmentPaymentStatus.Unpaid
        ? 'Unpaid'
        : 'Refunded'
    "
  ></i>
</ng-template>
