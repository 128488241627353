<ngx-loading [show]="navLocked"></ngx-loading>
<div
  [attr.data-test-id]="'action-panel-container-opened'"
  [attr.data-test-value]="actionPanelOpen"
  #patientPanelSlider
  id="patientPanelSlider"
  cssTransitionEnd
  (transitionEnded)="patientPanelOpen($event)"
  class="patient-panel-container d-flex flex-column h-100 z-index-3 position-fixed"
  [ngClass]="{ 'w-100': canShow, 'w-0': !canShow }"
>
  <div class="patient-panel-links" kendoTooltip tooltipClass="em-tooltip" position="top" *ngIf="!navLocked">
    <div class="re-links">
      <button date-test-id="close-patient-panel-button" (click)="closePatientPanel()" class="btn em-btn close-panel">
        <i class="far fa-times" title="Close Panel"></i>
      </button>
    </div>
    <div class="re-links">
      <button (click)="openNudgeModal()" class="btn em-btn close-panel white-border-top">
        <i class="far fa-bell-plus" title="Add Nudge"></i>
      </button>
    </div>
    <div class="re-links">
      <button (click)="bookAppointment()" class="btn em-btn close-panel white-border-top">
        <i class="far fa-calendar-plus" title="Add Appointment"></i>
      </button>
    </div>
    <div *appAuthGuard="messagingPolicy" class="re-links">
      <button (click)="openChatModal()" class="btn em-btn close-panel white-border-top">
        <i class="far fa-comments-alt" title="Open Chat"></i>
      </button>
    </div>
  </div>

  <div
    class="patient-panel-header row m-0"
    #PatientPanelHandle
    id="PatientPanelHandle"
    kendoTooltip
    [ngClass]="{
      'birthday-background': getDaysUntilBirthday() <= 7,
      'grey-two-background': !(getDaysUntilBirthday() <= 7)
    }"
    tooltipClass="em-tooltip"
    position="top"
  >
    <app-balloons
      [showBalloons]="true"
      *ngIf="thePatient && getDaysUntilBirthday() === 0"
      [customHeight]="'5em'"
    ></app-balloons>
    <ng-container *ngIf="thePatient">
      <!-- Patient Photo -->
      <div class="patient-photo d-flex">
        <div class="d-flex flex-nowrap justify-content-around" style="position: absolute; top: 0">
          <button class="btn" (click)="onSetAvatar()">
            <i class="fas fa-camera teal-font" title="Add Photo"></i>
          </button>
          <!-- <button *ngIf="thePatient?.avatar" class="btn" (click)="removeAvatar()" >
            <i class="fas fa-times teal-font" title="Delete Avatar"></i>
          </button> -->
        </div>

        <img [attr.src]="onGetAvatarURI(thePatient.avatar)" class="w-100 h-auto" />
      </div>
      <div class="patient-detail-row d-flex text-nowrap pl-2 py-1" *ngIf="thePatient">
        {{ thePatient.gender ? ('(' + thePatient.gender[0] + ') - ' | uppercase) : '' }}
        {{
          thePatient.firstName +
            ' ' +
            (thePatient.nickName ? '"' + thePatient.nickName + '" ' : '') +
            thePatient.lastName | titlecase
        }}
        - {{ thePatient.address.city ? thePatient.address.city + ', ' : ''
        }}{{ thePatient.address.province | provinceCode }}
        {{ thePatient.mobileNumber ? ' | Mobile ' + thePatient.mobileNumber : '' }} | DOB
        {{ thePatient.birthDate | date : 'yyyy-MM-dd' }} Age {{ getAge(thePatient.birthDate) }} | Allergies:
        <div
          *ngIf="patientAllergies?.description?.length > 0"
          style="color: red"
          class="pl-1 text-truncate"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="bottom"
          title="{{ patientAllergies.description }}"
        >
          {{ patientAllergies.description }}
        </div>
        <button (click)="openAllergyModal()" class="btn btn-sm em-btn em-btn-green mx-1" style="line-height: 1em">
          <i class="fas fa-pencil small" title="Edit"></i>
        </button>
      </div>
    </ng-container>
  </div>

  <!-- Nav Tabs -->
  <nav class="patient-panel-tabs tabs-no-pagination" data-test-id="patient-tab-a" mat-tab-nav-bar>
    <a
      *ngFor="let tab of authorizedTabLinks | keyvalue : originalOrder"
      mat-tab-link
      class="btn em-btn tab-btn"
      style="border-left: none"
      [routerLink]="tab.value"
      [active]="currentTabLink === tab.value"
    >
      {{ tab.key | uppercase }}
    </a>
  </nav>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
