import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { isNullOrUndefined } from '@app/shared/helpers';
import { environment } from '@environments/environment';
import { PagedDataResult } from '@models/paged-data-result.model';
import { Patient, PatientHistoryNotes, PatientSocialHistoryEntry } from '@models/patient';
import { PatientConsent } from '@models/patient-consent';
import { PatientStatus } from '@models/patient-status.enum';
import { PatientPortalCreatedData } from '@models/patientportal-createdData';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { moment } from 'fullcalendar';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { removeURIQueryString } from '../lib/fun';
import { BlobService } from './blob.service';
import { EventsService } from './events.service';
import { ObservationService } from './observation.service';
import { PatientLite } from '@models/patient-lite';

@Injectable()
export class PatientService {
  private thePatientUpdated = new Subject<any>();
  thePatientUpdated$ = this.thePatientUpdated.asObservable();

  private patientAdded = new Subject<any>();
  patientAdded$ = this.patientAdded.asObservable();

  private patientsSourceUpdated = new Subject<any>();
  patientsSourceUpdated$ = this.patientsSourceUpdated.asObservable();

  editPatientSourceURL: string;
  previousPage = '';

  /**
   * Gets the current patient object.
   */
  get patientPanelPatient(): Patient {
    return this._patientPanelPatient;
  }

  /**
   * Sets the current patient object.
   */
  set patientPanelPatient(p: Patient) {
    this._patientPanelPatient = p;
  }

  _patientPanelPatient: Patient = {
    patientId: 0,
    clientId: '',
    firstName: '',
    lastName: '',
    nickName: '',
    birthDate: new Date('January 1, 1980'),
    avatar: null,
    gender: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      province: '',
      country: 'Canada',
      postalCode: '',
    },
    familyPhysician: null,
    preferredPharmacy: null,
    communicationPreference: '',
    sendAppointmentEmailNotifications: true,
    sendAppointmentSMSNotifications: true,
    sendRetentionEmails: false,
    isPreferred: false,
    socialHistory: [],
    notesAndAlerts: '',
    email: '',
    homeNumber: '',
    mobileNumber: '',
    serviceAppointments: [],
    signature: '',
    patientStatus: PatientStatus.Active,
  };

  templatePatient: Patient = this.patientPanelPatient;
  editedPatient: Patient = this.patientPanelPatient;
  reservationPatient: Patient = null;

  constructor(
    private http: HttpClient,
    private observationService: ObservationService,
    private blobService: BlobService,
    private eventsService: EventsService,
    private authService: AuthService
  ) {
    authService.userPermissionsUpdated$
      .pipe(
        filter((permissions) => permissions && !!permissions.length),
        switchMap(() => this.getTemplatePatient())
      )
      .subscribe((patient) => {
        this.templatePatient = patient;
      });

    eventsService.closeSidePanel$.subscribe(() => {
      this.reservationPatient = null;
      this.editedPatient = null;
      this.patientHasBeenAdded(null);
    });
  }

  thePatientHasBeenUpdated(updatedPatient: Patient) {
    this.thePatientUpdated.next(updatedPatient);
  }

  thePatientsSourceHasBeenUpdated() {
    this.patientsSourceUpdated.next();
  }

  thePatientHasBeenUpdatedById(patientId: number) {
    this.getPatientById(patientId).subscribe((updatedPatient: Patient) => {
      this.patientPanelPatient = updatedPatient;
      this.thePatientUpdated.next(updatedPatient);
    });
  }

  mergePatientData(patientId: number, duplicatePatientId: number) {
    return this.http.post(environment.baseUrl + 'api/Patients/merge/' + patientId + '/' + duplicatePatientId, null);
  }

  patientHasBeenAdded(value: Patient) {
    this.patientAdded.next(value);
  }

  addPatient(patient: Patient) {
    return this.http.post<Patient>(environment.baseUrl + 'api/Patients', patient);
  }

  updatePatient(patient: Patient) {
    if (patient.observations != null) {
      this.patientObservationsToString(patient);
    }
    if (!isNullOrUndefined(patient.avatar)) {
      patient.avatar = removeURIQueryString(patient.avatar);
    }

    return this.http.put<void>(environment.baseUrl + 'api/Patients/' + patient.patientId, patient);
  }

  removePatient(patient: Patient) {
    return this.http.delete(environment.baseUrl + 'api/Patients/' + patient.patientId);
  }

  // Not in use. Back end is actually returning Paged Data Result causing error.
  getAllPatients() {
    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.get<Patient[]>(environment.baseUrl + 'api/Patients'),
    ]).pipe(
      map(([readOnlySAS, patients]) => {
        patients.forEach((patient) => {
          patient.avatar = readOnlySAS && patient.avatar ? patient.avatar.trim() + readOnlySAS : null;
        });
        return patients;
      })
    );
  }

  getPatientsLite() {
    return this.http.get<PatientLite[]>(environment.baseUrl + 'api/Patients/PatientsLite');
  }

  getPatientsList(state: DataSourceRequestState, searchText: string) {
    const data = {
      state,
      searchText,
    };

    return combineLatest([
      this.blobService.getReadOnlySASObservable(),

      this.http.post<PagedDataResult<Patient>>(environment.baseUrl + 'api/Patients/PatientsList', data),
    ]).pipe(
      map(([readOnlySAS, patientsResult]) => {
        patientsResult.data.forEach((patient) => {
          patient.avatar = readOnlySAS && patient.avatar ? patient.avatar.trim() + readOnlySAS : null;
        });
        return patientsResult;
      })
    );
  }

  getPatientById(patientId) {
    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.get<Patient>(environment.baseUrl + 'api/Patients/' + patientId),
    ])
      .pipe(take(1))
      .pipe(
        map(([readOnlySAS, patient]) => {
          patient.photos.forEach((photo) => {
            photo.filePath = (photo.filePath ? photo.filePath.trim() : '') + readOnlySAS;
            photo.filePathThumb = (photo.filePathThumb ? photo.filePathThumb.trim() : '') + readOnlySAS;
            photo.filePathOriginal = (photo.filePathOriginal ? photo.filePathOriginal.trim() : '') + readOnlySAS;

            if (photo && photo.seriesPhotos && photo.seriesPhotos.length > 0) {
              photo.seriesPhotos.forEach((photo2) => {
                photo2.filePath = (photo2.filePath ? photo2.filePath.trim() : '') + readOnlySAS;
                photo2.filePathThumb = (photo2.filePathThumb ? photo2.filePathThumb.trim() : '') + readOnlySAS;
              });
            }
          });
          patient.avatar = readOnlySAS && patient.avatar ? patient.avatar.trim() + readOnlySAS : null;
          this.patientObservationsToJson(patient);
          return patient;
        })
      );
  }

  static getDaysUntilBirthday(patient: Patient, currentDate: moment.Moment = moment()) {
    if (!(patient && patient.birthDate && patient.firstName[0] != '_')) return 365;

    let patientBirthday = moment(patient.birthDate).set('year', currentDate.year()).startOf('day');
    currentDate = currentDate.startOf('day');
    return Math.abs(patientBirthday.diff(currentDate, 'days'));
  }

  getTemplatePatient() {
    return this.http.get<Patient>(environment.baseUrl + 'api/Patients/template');
  }

  getBlockedSchedulePatient() {
    return this.http.get<Patient>(environment.baseUrl + 'api/Patients/Blocked');
  }

  getStaffSchedulePatient() {
    return this.http.get<Patient>(environment.baseUrl + 'api/Patients/Staff');
  }

  patientObservationsToJson(patient: Patient) {
    // Map observations details from string to TS JSON object
    this.observationService.detailsToJson(patient.observations);
  }

  patientObservationsToString(patient: Patient) {
    // Map observations details from string to TS JSON object
    this.observationService.detailsToString(patient.observations);
  }

  searchPatientByTerm(term: string, filterBy = 'name', statuses?: PatientStatus[]): Observable<Patient[]> {
    if (!term || term == '') return of([]);

    const params = {
      searchText: term,
      statuses: statuses,
      filterBy: filterBy,
    };

    // request returning nickname and not being mapped to nickName. Manually mapping for now. Fix in back end
    return this.http.post<Patient[]>(environment.baseUrl + 'api/Patients/SearchPatient', params).pipe(
      map((patients) => {
        for (const patient of patients) {
          if ((patient as any).nickname) {
            patient.nickName = (patient as any).nickname;
          }
        }
        return patients;
      })
    );
  }

  getPatientConsent(id: number) {
    return this.http.get<PatientConsent>(environment.baseUrl + `api/Consent/GetPatientPhotoConsent/${id}`);
  }

  updatePatientNotesAlerts(patientNotes: PatientHistoryNotes) {
    return this.http.put<Patient>(environment.baseUrl + 'api/Patients/UpdatePatientNotesAlerts', patientNotes);
  }

  updatePatientHistoryNotes(patientNotes: PatientHistoryNotes) {
    return this.http.put<Patient>(environment.baseUrl + 'api/Patients/UpdatePatientHistoryNotes', patientNotes);
  }

  getSocialHistoryByPatientId(id: number) {
    return this.http.get<PatientSocialHistoryEntry>(environment.baseUrl + `api/SocialHistory/ByPatientId/${id}`);
  }

  createSocialHistory(note: PatientSocialHistoryEntry) {
    return this.http.post<PatientSocialHistoryEntry>(environment.baseUrl + `api/SocialHistory/`, note);
  }

  updateSocialHistory(note: PatientSocialHistoryEntry) {
    return this.http.put<PatientSocialHistoryEntry>(environment.baseUrl + `api/SocialHistory`, note);
  }

  updatePatientAvatar(id: number, avatarUri: string) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    return this.http.put<Patient[]>(
      environment.baseUrl + `api/Patients/UpdateAvatar/${id}`,
      JSON.stringify(avatarUri),
      { headers: headers }
    );
  }

  removePatientAvatar(patientId: number) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    return this.http
      .put<Patient[]>(environment.baseUrl + `api/Patients/RemoveAvatar/${patientId}`, {}, { headers: headers })
      .pipe(
        map((data) => {
          delete this.patientPanelPatient.avatar;
          return data;
        })
      );
  }

  togglePatientRushNote(patientId: number, toggle: boolean) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put<boolean>(environment.baseUrl + `api/Patients/TogglePatientRushNote/${patientId}`, toggle, {
      headers: headers,
    });
  }

  getPatientPanelPatientContainerFolderName(): string {
    if (!this.patientPanelPatient) {
      return '';
    }
    var patientId = this.patientPanelPatient.patientId;
    var containerFolderName = `${patientId}`;
    return containerFolderName;
  }

  getAvatarURI(avatar: string): string {
    if (!avatar) {
      avatar = '/assets/profile/noprofile.jpg';
    }

    const readOnlySAS = this.blobService.getReadOnlySAS();

    if (avatar.indexOf('?') < 0 && readOnlySAS) {
      avatar += readOnlySAS;
    }

    return avatar;
  }

  createPatientPortalAccount(patientId: number) {
    return this.http.post(environment.baseUrl + 'api/Patients/CreatePatientPortalAccount', patientId);
  }

  existsPatientPortalAccount(patientId: number): Observable<boolean> {
    return this.http.get<boolean>(`${environment.baseUrl}api/Patients/ExistsPatientPortalAccount/${patientId}`);
  }

  sendPortalPasswordReset(patientId: number) {
    return this.http.post(environment.baseUrl + 'api/Patients/SendPortalPasswordReset', patientId);
  }

  getCreatedData(patientId: number): Observable<PatientPortalCreatedData> {
    return this.http.get<PatientPortalCreatedData>(`${environment.baseUrl}api/Patients/GetCreatedData/${patientId}`);
  }

  sendDocumentsToComplete(patientId: number) {
    return this.http.post(environment.baseUrl + 'api/Patients/SendDocumentsToComplete', patientId);
  }
}
