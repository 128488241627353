<ngx-loading [show]="loading"></ngx-loading>
<div class="edit-container position-absolute d-flex flex-column z-index-3 white-background">
  <div class="re-links-container" kendoTooltip tooltipClass="em-tooltip" position="top">
    <div class="re-links">
      <button class="btn em-btn" (click)="cancelUpdate()">
        <i class="far fa-times" aria-hidden="true" title="Close Panel"></i>
      </button>
    </div>
  </div>
  <div class="row mx-0 teal-background">
    <div class="col px-2">
      <h6 class="white-font uppercase p-2 mb-0">{{ isNew ? 'Add' : 'Edit' }} Service</h6>
    </div>
  </div>
  <div class="flex-grow-1 minh-0">
    <!-- ng-scrollbar is causing the whole page to move up the height of the top banner. d-none until view is initialized to avoid this. -->
    <ng-scrollbar #scrollbar class="h-100" [ngClass]="{ 'd-none': !viewInitialized }">
      <form [formGroup]="form" autocomplete="off">
        <div class="row px-2 mx-0 pt-2">
          <div class="col pl-2 pr-3">
            <mat-form-field class="d-block">
              <input
                matInput
                placeholder="Service Name"
                class="capitalized"
                aria-label="Service Name"
                formControlName="serviceName"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row px-2 mx-0">
          <div class="col pl-2 pr-3">
            <mat-form-field class="d-block">
              <input
                matInput
                placeholder="Invoice Service Name"
                class="capitalized"
                aria-label="Invoice Service Name"
                formControlName="serviceAltName"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row mx-0 px-2">
          <div class="col px-2">
            <mat-form-field>
              <mat-select formControlName="serviceTemplateTypeId" placeholder="Type" aria-label="Type">
                <mat-option *ngFor="let serviceTemplateType of serviceTemplateTypes" [value]="serviceTemplateType.id">
                  {{ serviceTemplateType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col px-2">
            <mat-form-field>
              <mat-select
                formControlName="serviceDetailTemplateId"
                [compareWith]="compareObjects"
                placeholder="Template"
                aria-label="Template"
              >
                <mat-option *ngFor="let template of serviceDetailTemplates" [value]="template.id">
                  {{ template.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row mx-0 px-2">
          <div class="col px-2">
            <mat-form-field id="serviceColourPickerFormField">
              <mat-select
                formControlName="serviceIDColour"
                [compareWith]="compareItems"
                id="serviceColourInput"
                placeholder="Service Colour"
                aria-label="Service Colour"
                [ngStyle]="getSelectedOptionStyles()"
              >
                <mat-option
                  class="color-option"
                  *ngFor="let color of serviceColours | keyvalue; let i = index"
                  [value]="color.key"
                  [ngStyle]="getOptionStyles(color)"
                >
                  {{ color.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col px-2">
            <mat-form-field class="d-flex">
              <i
                class="icon-select {{ this.form.controls['serviceIcon'].value }}"
                [style.backgroundColor]="
                  this.form.controls['serviceIDColour'].value
                    ? this.form.controls['serviceIDColour'].value
                    : 'transparent'
                "
                [style.color]="this.form.controls['serviceIDColour'].value ? 'white' : 'Gray'"
              ></i>
              <mat-select
                id="serviceIconSelect"
                placeholder="Service Icon"
                formControlName="serviceIcon"
                name="serviceIcons"
              >
                <mat-option
                  id="serviceIconOption"
                  *ngFor="let icon of templateIconsDropDown"
                  [value]="icon.icon"
                  [style.text-align]="'center'"
                >
                  <span
                    id="serviceIconOptionSpan"
                    [style.backgroundColor]="
                      this.form.controls['serviceIDColour'].value
                        ? this.form.controls['serviceIDColour'].value
                        : 'transparent'
                    "
                    [class]="icon.icon"
                    [style.width.px]="35"
                    [style.padding.px]="5"
                    [style.border-radius.px]="2"
                    [style.font-size.px]="15"
                    [style.color]="this.form.controls['serviceIDColour'].value ? 'white' : 'Gray'"
                  >
                  </span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row mx-0 px-2">
          <div class="col px-2">
            <mat-form-field>
              <mat-select placeholder="Category" formControlName="serviceCategory" name="serviceCategories">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let category of categories" [value]="category.serviceCategoryId">
                  {{ category.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0 px-2">
          <div class="col px-2">
            <mat-form-field>
              <input
                type="number"
                autocomplete="off"
                min="0"
                matInput
                placeholder="Default Duration"
                aria-label="Default Duration"
                formControlName="defaultDuration"
              />
            </mat-form-field>
          </div>
          <div class="col px-2">
            <mat-form-field id="isTreatmentPlanMultiple">
              <mat-select
                formControlName="isTreatmentPlanMultiple"
                placeholder="Allow Multiples"
                name="isTreatmentPlanMultiple"
              >
                <mat-option [value]="false"> No </mat-option>
                <mat-option [value]="true"> Yes </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0 px-2">
          <div class="col px-2">
            <mat-form-field>
              <mat-select
                placeholder="Service Providers"
                multiple="true"
                formControlName="selectedServiceProviders"
                [compareWith]="compareServiceProviders"
              >
                <mat-option *ngFor="let serviceProvider of serviceProviders" [value]="serviceProvider">
                  {{ serviceProvider.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row mx-0">
          <div class="col px-2">
            <h6 class="uppercase p-2 mx-0 mb-0">RESOURCES</h6>
          </div>
        </div>
        <div class="row mx-0 px-2">
          <div class="col px-2">
            <mat-form-field>
              <mat-select
                placeholder="Equipment"
                multiple="true"
                formControlName="equipmentResourcesSelected"
                [compareWith]="onCompareResource"
              >
                <mat-option *ngFor="let resource of equipmentResources" [value]="resource">
                  {{ resource.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col px-2">
            <mat-form-field>
              <mat-select
                placeholder="Rooms"
                multiple="true"
                formControlName="roomResourcesSelected"
                [compareWith]="onCompareResource"
              >
                <mat-option *ngFor="let resource of roomResources" [value]="resource">
                  {{ resource.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col px-2">
            <h6 class="uppercase p-2 mx-0">Government Billing</h6>
          </div>
        </div>

        <div class="row mx-0 px-2">
          <div class="col px-2">
            <mat-form-field id="serviceGovtBillingFormField">
              <mat-select formControlName="isGovtBilling" placeholder="Government Billing" name="governmentBilling">
                <mat-option [value]="false"> No </mat-option>
                <mat-option [value]="true">Yes</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col px-2">
            <mat-form-field>
              <input
                class="pr-4"
                matInput
                placeholder="Diagnostic Code"
                aria-label="Diagnostic Code"
                formControlName="diagnosticCode"
                autocomplete="off"
              />
              <button class="btn search-btn p-0" (click)="openDiagnosticSearch()">
                <i class="fas fa-search"></i>
              </button>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="this.form.controls['isGovtBilling'].value && serviceTemplate" class="row mx-0 px-2">
          <div class="col px-2">
            <mat-form-field id="serviceGovtBillingFormField">
              <mat-select
                formControlName="billingGroupId"
                placeholder="Age-Specific Billing Codes"
                name="governmentBilling"
              >
                <mat-option [value]="null">None</mat-option>
                <mat-option
                  *ngFor="let billingCodeDescription of ageSpecificBillingCodes"
                  [value]="billingCodeDescription.billingGroupId"
                >
                  {{ billingCodeDescription.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="this.form.controls['billingGroupId'].value" class="col px-2 d-flex flex-column">
            <div>
              <i
                class="hover-icon fas fa-info-circle mr-1"
                kendoTooltip
                tooltipClass="em-tooltip"
                title="The correct billing code will be automatically selected based on the patient's age."
              ></i>
              <span><b>Includes: </b> </span>
            </div>
            {{ getAgeSpecificBillingCodes() }}
          </div>
        </div>

        <ng-container *ngIf="this.form.controls['isGovtBilling'].value; else nonGovernmentBilling">
          <div class="row mx-3 px-3 mb-3 py-1 grey-three-border">
            <ng-container formArrayName="billingCodes">
              <div class="d-flex w-100" *ngFor="let billingCodeGroup of billingCodes.controls; let i = index">
                <ng-container [formGroup]="$any(billingCodeGroup)">
                  <div class="col pl-0 pr-2">
                    <mat-form-field class="w-auto">
                      <input
                        class="pr-4"
                        matInput
                        formControlName="billingCode"
                        placeholder="Billing Code"
                        aria-label="Billing Code"
                        autocomplete="off"
                      />
                      <button class="btn search-btn p-0" (click)="openBillingSearch(i)">
                        <i class="fas fa-search"></i>
                      </button>
                    </mat-form-field>
                  </div>

                  <div class="col d-flex pl-2 pr-0">
                    <mat-form-field class="w-auto">
                      <input
                        matInput
                        formControlName="serviceUnits"
                        placeholder="Billed Service Units"
                        aria-label="Billed Service Units"
                        autocomplete="off"
                      />
                    </mat-form-field>
                    <button class="btn-clean pr-0" (click)="removeBillingCode(i)">
                      <i class="far fa-minus-circle"></i>
                    </button>
                    <button
                      *ngIf="i === billingCodes.controls.length - 1"
                      class="btn-clean pr-0"
                      (click)="addBillingCode()"
                    >
                      <i class="fas fa-plus-circle"></i>
                    </button>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <ng-template #nonGovernmentBilling>
          <div class="row mx-0">
            <div class="col px-2">
              <h6 class="uppercase p-2 mx-0">BILLING DETAILS</h6>
            </div>
          </div>
          <div class="row mx-0 px-2">
            <div class="col px-2">
              <mat-form-field>
                <input
                  type="number"
                  autocomplete="off"
                  min="0"
                  matInput
                  placeholder="Retail Price"
                  aria-label="Retail Price"
                  formControlName="retailPrice"
                />
              </mat-form-field>
            </div>
            <div class="col px-2">
              <mat-form-field>
                <mat-select
                  placeholder="Require Card On File"
                  name="requireCardOnFile"
                  formControlName="requireCardOnFile"
                >
                  <mat-option [value]="false">No</mat-option>
                  <mat-option [value]="true">Yes</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row mx-0 px-2">
            <div class="col px-2">
              <mat-form-field>
                <mat-select placeholder="Tax Indicator" name="taxIndicator" formControlName="taxIndicator">
                  <mat-option *ngFor="let item of taxIndicators" [value]="item.value">
                    {{ item.title }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col px-2">
              <mat-form-field>
                <mat-select
                  placeholder="Taxes"
                  name="taxes"
                  multiple
                  formControlName="selectedTaxes"
                  [compareWith]="compareClinicTaxObjects"
                >
                  <mat-option *ngFor="let clinicTax of taxes" [value]="clinicTax">
                    {{ clinicTax?.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </ng-template>

        <div class="row mx-0 pt-1">
          <div class="col px-2">
            <h6 class="p-2 mb-0">ePOINTS</h6>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col px-3">
            <span>$1 = </span>
            <mat-form-field>
              <input type="number" autocomplete="off" min="0" matInput formControlName="dollarsToLoyaltyPointRate" />
            </mat-form-field>
            <span class="ml-1">ePoints</span>
          </div>
        </div>

        <div class="row mx-0 mt-2 teal-background">
          <div class="col px-2">
            <h6 class="uppercase p-2 mb-0 white-font">Recommended Products</h6>
          </div>
        </div>

        <div class="row mt-3 mx-3 px-3 pt-4 pb-1 grey-three-border">
          <div class="d-flex w-100">
            <div class="col pl-0 pr-2">
              <mat-form-field>
                <input
                  id="recommendedProductsInput"
                  type="text"
                  matInput
                  placeholder="Search for a product"
                  [matAutocomplete]="recommendedProducts"
                  formControlName="selectedRecommendedProduct"
                />
              </mat-form-field>
              <mat-autocomplete #recommendedProducts="matAutocomplete" [displayWith]="recommendedProductDisplayFn">
                <mat-option *ngFor="let product of filteredRecommendedProducts | async" [value]="product">
                  {{ product.displayName }}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div class="col pl-2 pr-0">
              <button
                class="btn em-btn em-btn-green"
                [disabled]="form.controls['selectedRecommendedProduct'].value == null"
                (click)="addRecommendedProduct()"
              >
                Add Product
              </button>
            </div>
          </div>

          <div
            *ngFor="let recommendedProduct of selectedRecommendedProducts"
            class="d-flex align-items-center w-100 mb-2 mr-3"
          >
            {{ recommendedProduct.clinicProduct ? recommendedProduct.clinicProduct.displayName : '' }}
            {{ recommendedProduct.clinicProduct ? (recommendedProduct.clinicProduct.retailPrice | currency) : '' }}
            <div class="d-flex align-items-center teal-font ml-auto">
              <label class="d-block ml-3 mb-0 teal-font text-truncate">Add to Invoice</label>
              <label class="switch mt-2 ml-1">
                <input
                  type="checkbox"
                  [checked]="recommendedProduct.isAddedToInvoice"
                  (change)="updateAddToInvoice(recommendedProduct)"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <button class="btn btn p-0 ml-3">
              <i class="fa fa-times-circle" (click)="removeRecommendedProduct(recommendedProduct.clinicProduct)"> </i>
            </button>
          </div>
        </div>

        <div class="row mx-0 mt-3 teal-background">
          <div class="col">
            <h6 class="white-font mb-0 py-2">eFORMS</h6>
          </div>
        </div>

        <div class="row px-2 mx-0 my-3">
          <div class="col px-2">
            <kendo-grid
              [data]="formGridData"
              scrollable="none"
              (add)="addFormHandler()"
              (remove)="removeFormHandler($event)"
            >
              <ng-template kendoGridToolbarTemplate>
                <button class="btn em-btn em-btn-green" kendoGridAddCommand>Assign New</button>
              </ng-template>
              <kendo-grid-column field="clinicForm.name" title="Assigned Form"></kendo-grid-column>
              <kendo-grid-column title="Auto-Selected" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div style="text-align: center; width: 100%">
                    <label class="switch">
                      <input
                        type="checkbox"
                        name="isSelectedByDefault"
                        aria-label="Is selected by default"
                        id="isSelectedByDefault"
                        [checked]="dataItem.isSelectedByDefault"
                        (change)="updateIsSelectedByDefault(dataItem, $event)"
                      />
                      <span [ngStyle]="{ 'pointer-events': 'none' }" class="slider round"></span>
                    </label>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-command-column [width]="50">
                <ng-template kendoGridCellTemplate let-isNew="isNew">
                  <button class="btn kendo-btn" kendoGridRemoveCommand>
                    <i class="fa fa-times-circle"></i>
                  </button>
                </ng-template>
              </kendo-grid-command-column>
            </kendo-grid>
          </div>
        </div>
        <div class="row mx-0 mt-3 teal-background">
          <div class="col">
            <h6 class="white-font mb-0 py-2">eTREATMENT FORMS</h6>
          </div>
        </div>

        <div class="row px-2 mx-0 my-3">
          <div class="col px-2">
            <kendo-grid
              [data]="eTreatmentFormGridData"
              scrollable="none"
              (add)="addeTreatmentFormHandler()"
              (remove)="removeeTreatmentFormHandler($event)"
            >
              <ng-template kendoGridToolbarTemplate>
                <button class="btn em-btn em-btn-green" kendoGridAddCommand>Assign New</button>
              </ng-template>
              <kendo-grid-column field="eTreatmentForm.name" title="Assigned eTreatment Form">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                  <button
                    type="button"
                    class="btn-clean px-0 text-left underline"
                    (click)="openeTreatmentFormViewer(dataItem.eTreatmentForm)"
                  >
                    {{ dataItem.eTreatmentForm.name }}
                  </button>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="Auto-Selected" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div style="text-align: center; width: 100%">
                    <label class="switch">
                      <input
                        type="checkbox"
                        name="isSelectedByDefault"
                        aria-label="Is selected by default"
                        id="isSelectedByDefault"
                        [checked]="dataItem.isSelectedByDefault"
                        (change)="updateeTreatmentFormIsSelectedByDefault(dataItem, $event)"
                      />
                      <span [ngStyle]="{ 'pointer-events': 'none' }" class="slider round"></span>
                    </label>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-command-column [width]="50">
                <ng-template kendoGridCellTemplate let-isNew="isNew">
                  <button class="btn kendo-btn" kendoGridRemoveCommand>
                    <i class="fa fa-times-circle"></i>
                  </button>
                </ng-template>
              </kendo-grid-command-column>
            </kendo-grid>
          </div>
        </div>
        <div class="row pt-2 pb-3">
          <div class="col px-2 text-center">
            <button
              class="btn em-btn em-btn-green mr-2"
              [disabled]="!this.form.dirty && !this.form.touched"
              (click)="onFormSubmit()"
            >
              {{ isNew ? 'Add' : 'Update' }} Service
            </button>
            <button class="btn em-btn-green em-btn" (click)="cancelUpdate()">Cancel</button>
          </div>
        </div>
      </form>
    </ng-scrollbar>
  </div>
</div>
