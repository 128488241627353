import { TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { ColourVariables } from '@models/constants/colour-variables';
import { AppointmentTile } from '@models/scheduler/appointment-tile';
import { AppointmentType } from '@models/scheduler/appointment';
import { PaymentStatus } from '@models/scheduler/payment-status';
import { AppointmentService } from '@services/appointments.service';
import { EventsService, ScheduleMode, ScheduleView } from '@services/events.service';
import { VisitService } from '@services/visit.service';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.less'],
})
export class AppointmentComponent implements OnInit, OnDestroy {
  appointmentType = AppointmentType;
  scheduleMode = ScheduleMode;
  scheduleView = ScheduleView;
  appointmentTile: AppointmentTile;
  colourVariables = new ColourVariables();
  actionPanelOpened = false;
  isWeeklyMode = false;
  isOverlappingEvent = false;
  isIpad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;

  appointmentsPolicy = Policies.appointments;
  patientAccountPolicy = Policies.patientAccount;
  patientChartPolicy = Policies.patientChart;
  patientProfilePolicy = Policies.patientProfile;

  AppointmentPaymentStatus = PaymentStatus;

  get appointmentVisitStatus() {
    const visit = this.appointmentService.activeAppointmentVisits.get(this.appointmentTile.visitId);
    return this.visitService.getVisitStatus(visit);
  }

  constructor(
    private router: Router,
    private titleCasePipe: TitleCasePipe,
    public appointmentService: AppointmentService,
    private visitService: VisitService,
    public eventsService: EventsService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {}

  getAppointmentTitle() {
    let title = '';
    if (this.appointmentTile?.onlineBooked && this.appointmentTile?.isPlaceholder) {
      title = this.appointmentTile.title;
    } else if (!this.appointmentTile?.onlineBooked && this.appointmentTile?.isPlaceholder) {
      title = this.titleCasePipe.transform(this.appointmentTile.patientName) + ' - ' + this.appointmentTile.title;
    } else if (this.eventsService?.scheduleView !== 'StaffSchedules') {
      title = this.titleCasePipe.transform(this.appointmentTile.patientName);
    }
    return title;
  }

  isApptSelected(): boolean {
    return this.appointmentService.apptsSelected.has(this.appointmentTile.id);
  }

  isApptMarked(): boolean {
    return this.appointmentService.apptsMarkedForMove.has(this.appointmentTile.id);
  }

  isVisitPanelOpen(): boolean {
    return this.router.url.indexOf('action-panel:visit-details') !== -1 ? true : false;
  }
}
